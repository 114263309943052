import React from "react";
import { Container, Alert, Fade } from "shards-react";

const PageAlert = ({ alertProps }) => {
  return (
    <Container fluid className="px-0">
      <Alert
        theme={alertProps.alertData && alertProps.alertData.theme}
        className="mb-0"
        open={alertProps.isVissable}
        fade={true}
        dismissible={() => alertProps.setIsVissable(false)}
        transition={{
          ...Fade.defaultProps,
          timeout: 150,
          unmountOnExit: false,
        }}
      >
        {alertProps.alertData && alertProps.alertData.message}
      </Alert>
    </Container>
  );
};

export default PageAlert;

export function usePageAlert() {
  const [isVissable, setIsVissable] = React.useState(false);
  const [alertData, setAlertData] = React.useState();

  function pageAlert({ theme, message }) {
    setIsVissable(false);
    setAlertData({
      message: message,
      theme: theme,
    });
  }

  React.useEffect(() => {
    setAlertData();
  }, []);

  React.useEffect(() => {
    if (alertData) setIsVissable(true);
  }, [alertData]);

  React.useEffect(() => {
    if (isVissable) {
      let timer1 = setTimeout(() => setIsVissable(false), 5 * 1000);

      return () => {
        clearTimeout(timer1);
      };
    }
  }, [isVissable]);

  const alertPage = {
    success: function (message) {
      pageAlert({ theme: "success", message });
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    },
    error: function (message) {
      pageAlert({ theme: "danger", message });
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    },
  };

  return {
    pageAlert,
    alertPage,
    alertProps: { isVissable, setIsVissable, alertData },
  };
}
