import { authService } from "./auth.service";
import { createAuthCaller } from "./http.service";


export function createApiKey(body) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.post("/admin/apikey", body);
}

export function getApiKeys() {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.get("/admin/apikey");
}

export function getApiKey(id) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.get(`/admin/apikey/${id}`);
}

export function getApiKeyToken(id) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.get(`/admin/apikey/token/${id}`);
}

export function deleteApiKey(id) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.delete(`/admin/apikey/${id}`);
}

export function updateApiKey(id, body) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.patch(`/admin/apikey/${id}`, body);
}

export function getApiKeyOptions() {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.get(`/admin/apikey/options`);
}

const apiKeyService = {
    createApiKey,
    getApiKeys,
    getApiKey,
    deleteApiKey,
    updateApiKey,
    getApiKeyOptions
}

export default apiKeyService;