import React from "react";
import { Container, Row, CardHeader } from "shards-react";
import GlobalSearchBar from "../GlobalSearchBar";

const TableHeader = ({ search, setSearch }) => {
  return (
    <CardHeader className="text-center p-0">
      <Container fluid className="file-manager__filters border-bottom">
        <Row>
          <GlobalSearchBar search={search} setSearch={setSearch} />
        </Row>
      </Container>
      {/* <FileDropzone /> */}
    </CardHeader>
  );
};

export default TableHeader;
