import React, { useState, useEffect } from "react";
import { Row, Col } from "shards-react";
import PayoutsTable from "./PayoutsTable";
import { useQuery, useQueryClient } from "react-query";
import { setVendorQueryDefaults } from "../../../query/vendor.query";
import PayoutForm from "./PayoutForm";
import vendorService from "../../../services/vendor.service";
import PayoutItemList from "./PayoutItemList";

const Payouts = ({ bookingId, alertPage }) => {
  const queryClient = useQueryClient();
  setVendorQueryDefaults(queryClient);
  const [itemData, setItemData] = useState();
  const [mode, setMode] = useState("edit");
  const [data, setData] = useState();
  const [payout, setPayout] = useState();

  useEffect(() => {
    vendorService.getBookingVendors(bookingId).then((res) => {
      setData(res.data);
    });
  }, []);

  function handleViewItems(vendorData) {
    setItemData(vendorData);
    setMode("edit");
  }

  function handleSaveItems(vendorData) {
    setItemData(vendorData);
    setMode("new");
  }

  const { status, data: invoices } = useQuery([
    "invoices",
    { bookingId, vendor: itemData && itemData.vendor.id },
  ]);

  async function handleSubmit() {
    try {
      await vendorService.createPayout({
        vendor: itemData.vendor.id,
        bookingId,
        ...payout,
      });

      queryClient.invalidateQueries([
        "invoices",
        { bookingId, vendor: itemData.vendor.id },
      ]);
      setPayout();
    } catch (ex) {
      // Api response error
      if (ex.response) {
        return alertPage.error(ex.response.data.message);
      }
      // Critical error
      console.error(ex);
      return alertPage.error("Connection error - please try again later");
    }
  }

  return (
    <Row>
      <Col lg="7" className="mb-4">
        <PayoutsTable
          title={"Booking Vendors"}
          data={data || []}
          handleViewItems={handleViewItems}
          handleSaveItems={handleSaveItems}
        />
      </Col>
      {itemData && itemData.items.length > 0 && mode === "edit" && (
        <Col lg="4">
          <PayoutItemList
            title={itemData && itemData.vendor.name}
            vendorItems={(itemData && itemData.items) || []}
          />
        </Col>
      )}
      {mode === "new" && (
        <Col lg="5" className="mb-4">
          <PayoutForm
            invoices={invoices}
            title={itemData && itemData.vendor.name}
            vendor={itemData && itemData.vendor && itemData.vendor.id}
            bookingId={bookingId}
            handleSubmit={handleSubmit}
            payout={payout}
            setPayout={setPayout}
          />
        </Col>
      )}
    </Row>
  );
};

export default Payouts;
