import React from "react";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormGroup,
  FormSelect,
  FormFeedback,
} from "shards-react";

import NumberFormat from "react-number-format";

const ClientContactForm = ({ errors, phone, setPhone, cell, setCell }) => {
  return (
    <>
      <Row form>
        <Col md="6" className="form-group">
          <FormInput
            id="feFirstName"
            placeholder="First"
            name="firstName"
            invalid={errors.field === "firstName" ? true : false}
          />
          <FormFeedback>{errors && errors.message}</FormFeedback>
        </Col>
        <Col md="6">
          <FormInput
            id="feLastName"
            placeholder="Last"
            name="lastName"
            invalid={errors.field === "lastName" ? true : false}
          />
          <FormFeedback>{errors && errors.message}</FormFeedback>
        </Col>
      </Row>
      <Row form>
        <Col className="form-group">
          <FormInput
            id="feEmail"
            //type="email"
            placeholder="Email"
            name="email"
            invalid={errors.field === "email" ? true : false}
          />
          <FormFeedback>{errors && errors.message}</FormFeedback>
        </Col>
      </Row>
      <hr />
      <Row form>
        <Col md="6" className="form-group">
          <FormInput
            id="feFirstName2"
            placeholder="First"
            name="firstName2"
            invalid={errors.field === "firstName2" ? true : false}
          />
          <FormFeedback>{errors && errors.message}</FormFeedback>
        </Col>
        <Col md="6">
          <FormInput
            id="feLastName2"
            placeholder="Last"
            name="lastName2"
            invalid={errors.field === "lastName2" ? true : false}
          />
          <FormFeedback>{errors && errors.message}</FormFeedback>
        </Col>
      </Row>

      <Row form>
        <Col md="6" className="form-group">
          <FormGroup>
            <NumberFormat
              customInput={FormInput}
              format="(###) ###-####"
              placeholder="Phone"
              mask="_"
              value={phone}
              name="phone"
              isNumericString={true}
              type="tel"
              onValueChange={(values) => {
                setPhone(values.value);
              }}
              invalid={errors.field === "phone" ? true : false}
            />
            <FormFeedback>{errors && errors.message}</FormFeedback>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <NumberFormat
              customInput={FormInput}
              format="(###) ###-####"
              placeholder="Cell"
              mask="_"
              value={cell}
              name="cell"
              isNumericString={true}
              type="tel"
              onValueChange={(values) => {
                setCell(values.value);
              }}
              invalid={errors.field === "cell" ? true : false}
            />
            <FormFeedback>{errors && errors.message}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default ClientContactForm;
