import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
  ButtonGroup,
  Button,
} from "shards-react";
import moment from "moment";
import capitalize from "../../../utils/capitalize";

const PaymentTable = ({
  title,
  data,
  handleAddNew,
  handleDelete,
  handleUpdate,
}) => {
  return (
    <Card small className="payout-stats h-100">
      <CardHeader className="border-bottom">
        <Row className="d-flex">
          <Col className="fmr-auto p-2">
            <h6 className="m-0">{title}</h6>
          </Col>

          <Col className="p-2 text-right">
            <Button onClick={handleAddNew} theme="accent" size="sm">
              Add
            </Button>
          </Col>
        </Row>
      </CardHeader>

      <CardBody className="p-0">
        <Container fluid className="px-0">
          <table className="table mb-0">
            <thead className="py-2 bg-light text-semibold border-bottom">
              <tr>
                <th>Payment</th>
                <th className="text-center">Due</th>
                <th className="text-center">Status</th>
                <th className="text-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item, idx) => {
                  return (
                    <tr key={idx}>
                      <td className="lo-stats__order-details">
                        <span>{item.totalPrice}</span>
                        <span>${item.payment}</span>
                      </td>

                      <td className="lo-stats__items text-center">
                        {moment.utc(item.due).format("MM-DD")}
                      </td>

                      <td
                        className={
                          "lo-stats__total text-center text-" +
                          getBadgeType(item.status)
                        }
                      >
                        {capitalize(item.status)}
                      </td>

                      <td className="lo-stats__actions">
                        <ButtonGroup className="d-table ml-auto">
                          <Button
                            size="sm"
                            theme="white"
                            onClick={() => handleUpdate(item)}
                          >
                            Mark Paid
                          </Button>
                          <Button
                            size="sm"
                            theme="white"
                            onClick={() => handleDelete(item)}
                          >
                            Delete
                          </Button>
                        </ButtonGroup>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </Container>
      </CardBody>

      <CardFooter className="border-top">
        <Row>
          <Col>
            Final Payment: $
            {data && data.length > 0 && data[data.length - 1].payment}
          </Col>
          <Col className="text-center view-report">
            Due:{" "}
            {data &&
              data.length > 0 &&
              moment(data && data[data.length - 1].due).format("MM-DD-YYYY")}
          </Col>
        </Row>
      </CardFooter>
    </Card>
  );
};

function getBadgeType(itemStatus) {
  const statusMap = {
    paid: "success",
    Pending: "warning",
    Canceled: "danger",
  };

  return statusMap[itemStatus];
}

export default PaymentTable;
