import React, { useState } from "react";
import {
  Row,
  Col,
  FormInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ListGroup,
  ListGroupItem,
  FormSelect,
} from "shards-react";
import { BehaviorSubject, from } from "rxjs";
import {
  mergeMap,
  filter,
  debounceTime,
  distinctUntilChanged,
} from "rxjs/operators";
import { getVendorsByNameAutoComplete } from "../../services/vendor.service";

let searchSubject = new BehaviorSubject("");

let searchResultObservables = searchSubject.pipe(
  filter((val) => val.length > 0),
  debounceTime(750),
  distinctUntilChanged(),
  mergeMap((val) => from(getVendorsByNameAutoComplete(val)))
);

const useObservable = (observable, setter) => {
  React.useEffect(() => {
    let subscription = observable.subscribe((result) => {
      setter(result.data);
    });
    return () => subscription.unsubscribe();
  }, [observable, setter]);
};

const VendorAutoCompleteInput = ({ value, onChange, placeholder }) => {
  const [isEdit, setIsEdit] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [results, setResults] = React.useState([]);
  const [showOptions, setShowOptions] = React.useState(false);
  const [activeOption, setActiveOption] = React.useState(0);
  const inputSelectRef = React.useRef();
  useObservable(searchResultObservables, setResults);

  React.useEffect(() => {
    if (isEdit) {
      inputSelectRef.current.focus();
    }
  }, [isEdit]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setSearch(newValue);
    if (newValue) {
      setShowOptions(true);
      searchSubject.next(newValue ? newValue : " ");
    } else {
      setShowOptions(false);
    }
  };

  /* list item handlers */
  const handleOnClick = (e) => {
    const changeEvent = {
      target: {
        value: {
          id: e.currentTarget.id,
          name: e.currentTarget.dataset.name,
        },
      },
    };
    onChange(changeEvent);
    setSearch("");
    setShowOptions(false);
    setIsEdit(false);
  };

  function handleOnKeyDown(e) {
    if (e.keyCode === 13) {
      const changeEvent = {
        target: {
          value: {
            id: results[activeOption].id,
            name: results[activeOption].name,
          },
        },
      };
      setActiveOption(0);
      onChange(changeEvent);
      setSearch("");
      setShowOptions(false);
      setIsEdit(false);
    } else if (e.keyCode === 38) {
      if (activeOption === 0) {
        return;
      }
      setActiveOption((activeOption) => activeOption - 1);
    } else if (e.keyCode === 40) {
      if (activeOption === results.length - 1) {
        return;
      }
      setActiveOption((activeOption) => activeOption + 1);
    } else if (e.keyCode === 9) {
      onChange({
        target: {
          value: "",
        },
      });
      setSearch("");
      setShowOptions(false);
      setIsEdit(false);
    }
  }

  return (
    <div className="">
      <label htmlFor="feAutoComIn">Vendor</label>
      <InputGroup seamless>
        <InputGroupAddon type="prepend">
          <InputGroupText>
            <i className="material-icons">travel_explore</i>
          </InputGroupText>
        </InputGroupAddon>
        {!isEdit && (
          <FormInput
            placeholder={placeholder}
            onChange={onChange}
            value={value && value.name}
            data-id={value && value.id}
            data-name={value && value.name}
            onClick={() => setIsEdit(true)}
          />
        )}
        {isEdit && (
          <FormInput
            innerRef={inputSelectRef}
            onChange={handleChange}
            value={search}
            placeholder={placeholder}
            onKeyDown={handleOnKeyDown}
          />
        )}
      </InputGroup>
      <ListGroup small>
        {showOptions &&
          [...results].map((vendor, index) => (
            <ListGroupItem
              id={vendor.id}
              data-vendor={vendor.name}
              data-name={vendor.name}
              data-id={vendor.id}
              key={vendor.id}
              onClick={handleOnClick}
              active={index === activeOption}
            >{`${vendor.name}`}</ListGroupItem>
          ))}
      </ListGroup>
    </div>
  );
};

export default VendorAutoCompleteInput;
