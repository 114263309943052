export default function getSidebarNavItems(role) {
  const page = {

    clients: {
      title: "Clients",
      to: "/clients",
      htmlBefore: '<i class="material-icons">people_alt</i>',
      htmlAfter: "",
    },

    bookings: {
      title: "Bookings",
      to: "/bookings",
      htmlBefore: '<i class="material-icons">perm_contact_calendar</i>',
      htmlAfter: "",
    },

    vendors: {
      title: "Vendors",
      to: "/vendors",
      htmlBefore: '<i class="material-icons">storefront</i>',
      htmlAfter: "",
    },

    agents: {
      title: "Agents",
      to: "/agents",
      htmlBefore: '<i class="material-icons">support_agent</i>',
      htmlAfter: "",
    },

    payouts: {
      title: "Vendor Payouts",
      to: "/payouts",
      htmlBefore: '<i class="material-icons">receipt</i>',
      htmlAfter: "",
    },

    reminders: {
      title: "Reminders",
      to: "/reminders",
      htmlBefore: '<i class="material-icons">circle_notifications</i>',
      htmlAfter: "",
    },

    admin: {
      title: "Admin",
      to: "/admin",
      htmlBefore: '<i class="material-icons">&#xEF3D;</i>',
      htmlAfter: "",
    },

    users: {
      title: "Users",
      to: "/admin/users",
      htmlBefore: '<i class="material-icons">people_alt</i>',
      htmlAfter: "",
    },

    api: {
      title: "API Keys",
      to: "/admin/api",
      htmlBefore: '<i class="material-icons">vpn_key</i>',
      htmlAfter: "",
    },

    uploads: {
      title: "File Manager",
      to: "/admin/uploads",
      htmlBefore: '<i class="material-icons">&#xE2C7;</i>',
      htmlAfter: "",
    },
    profile: {
      title: "Profile",
      to: "/user/profile",
      htmlBefore: '<i class="material-icons">&#xF02E;</i>',
      htmlAfter: ""
    },
    userUploads: {
      title: "User Uploads",
      to: "/user/uploads",
      htmlBefore: '<i class="material-icons">&#xE2C7;</i>',
      htmlAfter: ""
    }
  }



  switch (role) {
    case "admin":
      return [
        {
          title: "Agency",
          items: [
            { ...page.reminders },
            { ...page.clients },
            { ...page.bookings },
            { ...page.vendors },
            { ...page.payouts }
          ],
        },
        {
          title: "Admin",
          items: [
            { ...page.admin },
            { ...page.agents },
            { ...page.uploads },
            { ...page.api }
          ],
        },
        {
          title: "User",
          items: [
            { ...page.profile },
            // { ...page.userUploads },
          ],
        },
      ];
    case "user":
      return [
        {
          title: "Agency",
          items: [
            { ...page.clients },
            { ...page.bookings },
          ],
        },
        {
          title: "User",
          items: [
            { ...page.profile },
            { ...page.userUploads },
          ],
        },
      ];
    default:
      return []
  }

}


