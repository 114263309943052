import React, { useState, useEffect } from "react";
import Uploader from "./uploader/Uploader";
import Directories from "./directories/Directories";
import EditFileModal from "./common/EditFileModal/EditFileModal";
import { useMutation, useQueryClient } from "react-query";

const FileManagerCards = ({
  userId,
  uploadFiles,
  getFile,
  getFiles,
  moveFile,
  previewFile,
  folders,
  alertPage,
  setDeleteHandler,
}) => {
  const queryClient = useQueryClient();
  const [selectedDirectory, setSelectedDirectory] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [search, setSearch] = useState();

  // Clear selected file on directory change
  useEffect(() => {
    setSelectedFile("");
    setSearch("");
  }, [selectedDirectory]);

  // Modal
  const [isOpen, setIsOpen] = useState(false);
  const [updated, setUpdated] = useState();

  useEffect(() => {
    if (updated) setIsOpen(true);
  }, [updated]);

  useEffect(() => {
    if (!isOpen) setUpdated();
  }, [isOpen]);

  // Mutation
  const fileMutation = useMutation("updateUploadsArray");
  const deleteFileMutation = useMutation("deleteUploadsArray");

  const deleteSelectedFile = async () => {
    const { data: item } = await getFile(selectedFile);
    setDeleteHandler({
      deleteFn: () => {
        deleteFileMutation.mutate(
          {
            id: selectedFile,
            key: ["uploadCards", { userId, dir: selectedDirectory, search }],
          },
          {
            onError: () => {
              alertPage.error(`Unable to delete file: ${item.originalname}`);
            },
            onSuccess: () => {
              queryClient.refetchQueries();
              // queryClient.invalidateQueries(["files", userId]);
              alertPage.success(`File: ${item.originalname} DELETED`);
              setSelectedFile("");
            },
          }
        );
      },
      item: `File: ${item.originalname}`,
    });
  };

  const editFile = async () => {
    const { data } = await getFile(selectedFile);
    setUpdated(data);
  };

  return (
    <div className="file-manager file-manager-cards">
      {/* Uploader */}
      <Uploader
        userId={userId}
        selectedDirectory={selectedDirectory}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        uploadFiles={uploadFiles}
        deleteSelectedFile={deleteSelectedFile}
        editFile={editFile}
        search={search}
        setSearch={setSearch}
      />
      {/* Directories */}
      <Directories
        userId={userId}
        getFiles={getFiles}
        moveFile={moveFile}
        previewFile={previewFile}
        folders={folders}
        selectedDirectory={selectedDirectory}
        setSelectedDirectory={setSelectedDirectory}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        search={search}
      />
      <EditFileModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        updated={updated}
        setUpdated={setUpdated}
        handleSubmit={async () => {
          const { folder, originalname } = updated;
          fileMutation.mutate(
            {
              userId,
              uploadId: updated.id,
              params: { folder, originalname },
              key: ["uploadCards", { userId, dir: selectedDirectory, search }],
            },
            {
              onSuccess: () => {
                queryClient.invalidateQueries("files");
                alertPage.success(`File: ${originalname} UPDATED`);
                setSelectedFile("");
                setIsOpen(false);
              },
              onError: () => {
                alertPage.error(`Unable to update file: ${originalname}`);
              },
            }
          );
        }}
        error={{}}
        folders={folders}
      />
    </div>
  );
};

export default FileManagerCards;
