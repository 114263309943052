import { authService } from "./auth.service";
import { createAuthCaller } from "./http.service";
import queryString from "query-string";


export function getInvoicePayouts(params) {
  const accessToken = authService.userValue.token;
  const http = createAuthCaller(accessToken);
  const query = queryString.stringify(params);
  return http.get(`/payouts/invoice?${query}`)
}

export function getPayouts(params) {
  const accessToken = authService.userValue.token;
  const http = createAuthCaller(accessToken);
  const query = queryString.stringify(params);
  return http.get(`/payouts?${query}`)
}
export function deletePayout(id) {
  const accessToken = authService.userValue.token;
  const http = createAuthCaller(accessToken);
  return http.delete(`/payouts/${id}`);
}
export function updatePayout(id, payout) {
  const accessToken = authService.userValue.token;
  const http = createAuthCaller(accessToken);
  return http.patch(`/payouts/${id}`, payout);
}


const payoutService = {
  getInvoicePayouts,
  getPayouts,
  updatePayout,
  deletePayout
}

export default payoutService;