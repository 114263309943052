import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Button,
  Form,
  FormInput,
  FormGroup,
  FormCheckbox,
  CardHeader,
  FormSelect,
  ListGroup,
  ListGroupItem,
} from "shards-react";
import moment from "moment";
import bookingService from "../../services/booking.service";
import { useQueryClient } from "react-query";

const BookingForm = ({ data }) => {
  const queryClient = useQueryClient();
  const [booking, setBooking] = useState(data);
  const [isNew, setIsNew] = useState(false);
  const [agents, setAgents] = useState([]);

  useEffect(() => {
    bookingService.getOptions({ option: "agents" }).then((res) => {
      setAgents(res.data);
    });
  }, []);

  async function handleSubmit() {
    const { bookingDate, travelDate, name, insurance, _agent, agentId } =
      booking;

    try {
      await bookingService.updateBooking(data.id, {
        bookingDate,
        travelDate,
        name,
        insurance,
        _agent,
        agentId,
      });
      queryClient.invalidateQueries(["booking", data.id]);
      setIsNew(false);
    } catch (ex) {
      console.log(ex);
    }
  }

  return (
    <Card className="p-0 mb-4 h-100">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Booking Info</h6>
        <div className="block-handle" />
      </CardHeader>
      <CardBody className="p-0">
        <ListGroup flush>
          <ListGroupItem className="p-3">
            <Row>
              <Col>
                <Form>
                  <Row form>
                    <Col md="6" className="form-group">
                      <label htmlFor="feBookingDate">Booking Date</label>
                      <FormInput
                        id="feBookingDate"
                        type="date"
                        placeholder="Booking"
                        value={moment
                          .utc(booking.bookingDate)
                          .format("YYYY-MM-DD")}
                        onChange={(e) => {
                          setIsNew(true);
                          setBooking({
                            ...booking,
                            bookingDate: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col md="6">
                      <label htmlFor="feTravelDate">Travel Date</label>
                      <FormInput
                        id="feTravelDate"
                        type="date"
                        placeholder="Travel"
                        value={moment
                          .utc(booking.travelDate)
                          .format("YYYY-MM-DD")}
                        onChange={(e) => {
                          setIsNew(true);
                          setBooking({
                            ...booking,
                            travelDate: e.target.value,
                          });
                        }}
                      />
                    </Col>
                  </Row>

                  <Row form>
                    <Col md="6" className="form-group">
                      <label htmlFor="feBookingName">Name</label>
                      <FormInput
                        id="feBookingName"
                        placeholder="Booking Name"
                        value={booking.name}
                        onChange={(e) => {
                          setIsNew(true);
                          setBooking({
                            ...booking,
                            name: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col md="4" className="form-group">
                      <label htmlFor="feInsurance">Insurance Waiver</label>
                      <FormCheckbox
                        id="feInsurance"
                        toggle
                        small
                        checked={booking.insurance === "ACC"}
                        onChange={() => {
                          setIsNew(true);
                          setBooking({
                            ...booking,
                            insurance:
                              booking.insurance === "ACC" ? "DECL" : "ACC",
                          });
                        }}
                      >
                        Accept
                      </FormCheckbox>
                    </Col>
                  </Row>
                  <FormGroup>
                    <label htmlFor="feAgent">Agent</label>
                    <FormSelect
                      id="feAgent"
                      value={booking._agent}
                      onChange={(e) => {
                        setIsNew(true);
                        setBooking({
                          ...booking,
                          _agent: e.target.value,
                          agentId: e.target.value,
                        });
                      }}
                    >
                      {agents.map((agent) => {
                        return (
                          <option key={agent.id} value={agent.id}>
                            {`${agent.lastName}, ${agent.firstName}`}
                          </option>
                        );
                      })}
                    </FormSelect>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </CardBody>
      <CardFooter className="border-top">
        <Row className="mb-2">
          {isNew && (
            <Button theme="accent" type="submit" onClick={() => handleSubmit()}>
              Save Changes
            </Button>
          )}
        </Row>
      </CardFooter>
    </Card>
  );
};

export default BookingForm;
