import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "shards-react";
import { useSearchBar } from "../custom/SiteSearchBar/SiteSearchBar";
import PageHeader, { useDateChangeHandler } from "../common/PageHeader";

// Table
import UserColumns from "../components/UserManager/UserColumns";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { setUserQueryDefaults } from "../query/user.query";

import DataTableCard from "../common/DataTableCard/DataTableCard";
import PageAlert, { usePageAlert } from "../common/Alert/PageAlert";
import AreYouSure, { useAreYouSure } from "../common/AreYouSure/AreYouSure";
import NewAgentForm from "../components/Agents/NewAgentForm";
import agentValidation from "../validation/agent.validation";
import userService from "../services/user.service";

const Agents = () => {
  /* Query Defaults */
  const queryClient = useQueryClient();
  setUserQueryDefaults(queryClient);
  /* Table State */
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState("createdAt:desc");
  const { search, setSearch } = useSearchBar();
  const [start, handleStartChange, end, handleEndChange] =
    useDateChangeHandler();

  const { alertPage, alertProps } = usePageAlert();
  const { areYouSureProps, setDeleteHandler } = useAreYouSure();
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState();
  const [formError, setFormError] = useState();
  const [isEdit, setIsEdit] = useState(false);

  /* Query */
  const { status, data } = useQuery([
    "users",
    { page, limit, sortBy, search, start, end },
  ]);

  function handleEdit(e) {
    setIsEdit(true);
    const { id, firstName, lastName, email, phone, role } = e;
    setFormData({ id, firstName, lastName, email, phone, role });
  }

  useEffect(() => {
    if (formData) setIsOpen(true);
  }, [formData]);
  useEffect(() => {
    if (!isOpen) {
      setFormData();
      setIsEdit(false);
    }
  }, [isOpen]);

  const deleteMutation = useMutation("deleteUser");
  function handleDelete(e) {
    setDeleteHandler({
      deleteFn: async () => {
        deleteMutation.mutate({
          id: e.id,
          key: ["users", { page, limit, sortBy, search, start, end }],
        });
      },
      item: `${e.email}`,
    });
  }

  async function handleUpdate(value) {
    const id = value.id;
    delete value.id;
    await userService.updateUser(id, value);
  }

  /* Submit Function */
  async function handleSubmit() {
    try {
      const value = await agentValidation.newAgent.validateAsync(formData);

      if (value.hasOwnProperty("id")) {
        /* Edit */
        await handleUpdate(value);
      } else {
        /* New */
        await userService.createUser(value);
      }
      queryClient.invalidateQueries([
        "users",
        { page, limit, sortBy, search, start, end },
      ]);
      setIsOpen();
    } catch (ex) {
      // Validation error
      if (ex.isJoi) {
        return setFormError(ex.details[0]);
      }
      // Api response error
      if (ex.response) {
        return alertPage.error(ex.response.data.message);
      }
      // Critical error
      console.error(ex);
      return alertPage.error("Connection error - please try again later");
    }
  }

  return (
    <>
      <PageAlert alertProps={alertProps} />
      <Container fluid className="main-content-container px-4 mb-4">
        <PageHeader
          title="Agents"
          subtitle="Admin"
          dateStart={start}
          dateEnd={end}
          handleStartChange={handleStartChange}
          handleEndChange={handleEndChange}
        />
        <Row>
          <Col md="8">
            <DataTableCard
              columns={UserColumns(handleEdit, handleDelete)}
              search={search}
              data={data}
              status={status}
              search={search}
              setSearch={setSearch}
              status={status}
              limit={limit}
              setLimit={setLimit}
              setPage={setPage}
              setSortBy={setSortBy}
              setPreFetchId={() => {}}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              handleSubmit={handleSubmit}
            >
              <NewAgentForm
                formData={formData}
                setFormData={setFormData}
                formError={formError}
                setFormError={setFormError}
                isEdit={isEdit}
              />
            </DataTableCard>
          </Col>
          <Col md="5"></Col>
        </Row>
      </Container>
      <AreYouSure areYouSureProps={areYouSureProps} />
    </>
  );
};

export default Agents;
