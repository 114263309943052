import { authService } from "./auth.service";
import { createAuthCaller } from "./http.service";


export function getAirportsAutoComplete(searchString) {
  const accessToken = authService.userValue.token;
  const http = createAuthCaller(accessToken);
  return http.get(`/airports?search=${searchString}`)
}

const airportService = {
  getAirportsAutoComplete
}

export default airportService;