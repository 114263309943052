import React from "react";

/**
 *
 * @param {function} setFormError
 * @returns {function} setError
 *
 * example use
 * const [setNewError] = useFormError(setFormError);
 */
export function useFormError(setFormError) {
  const [error, setError] = React.useState();

  React.useEffect(() => {
    if (error) {
      // SERVER ERROR
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //console.log("data", error.response.data);
        console.log("status", error.response.status);

        if (error.response.status >= 400 && error.response.status < 500) {
          //console.log("Expected Error");
          const { code, message } = error.response.data;
          //
          //console.log(code, message);
          if (code === 400) {
            //console.log("400");
            const fieldPattern = /".*?"/i;
            const field = fieldPattern.exec(message);
            if (field) {
              setFormError({
                // Maybe add lowercase first letter
                field: field[0].replace(/"/g, ""),
                message,
              });
            }
          }
          //
        } else {
          console.log("Unexpected Error");
          // ADMIN LOG error.response.data
        }
        // NO RESPONSE
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log("Unexpected Request Error");
        // ADMIN LOG error.request
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Unexpected Setup Error");
        // ADMIN LOG error.message
      }
    }
  }, [error]);

  //console.log("config", error.config);

  return setError;
}
