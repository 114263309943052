import React, { useState, useEffect } from "react";
import {
  FormInput,
  Modal,
  ModalBody,
  FormGroup,
  Form,
  ButtonGroup,
  Button,
  ModalFooter,
  FormFeedback,
} from "shards-react";
import { useQueryClient } from "react-query";
import moment from "moment";
import bookingValidation from "./validation/booking.validation";
import BookingItemCard from "./common/BookingItemCard";
import bookingService from "../../services/booking.service";

const TravelersCard = ({ bookingId, travelers, handleDelete }) => {
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState();
  const [formError, setFormError] = useState();

  useEffect(() => {
    if (selected) {
      setFormError();
      setIsOpen(true);
    }
  }, [selected]);

  useEffect(() => {
    if (!isOpen) {
      setSelected();
    }
  }, [isOpen]);

  async function handleSubmit() {
    try {
      const value = await bookingValidation.traveler.validateAsync(selected);

      delete value._id;

      if (selected._id) {
        console.log("EDIT", selected);
        await bookingService.updateBookingItem(
          bookingId,
          "travelers",
          selected._id,
          value
        );
      } else {
        console.log("NEW", value);
        await bookingService.createBookingItem(bookingId, "travelers", value);
      }
      queryClient.invalidateQueries(["booking", bookingId]);
      setIsOpen(false);
    } catch (ex) {
      if (ex.isJoi) {
        setFormError({
          path: ex.details[0].path[0],
          message: ex.details[0].message,
        });
      } else {
        console.log(ex);
      }
    }
  }

  return (
    <>
      <BookingItemCard
        label="Travelers"
        data={travelers}
        columns={columns}
        setSelected={setSelected}
        handleDelete={handleDelete}
        type="travelers"
      />
      <Modal open={isOpen} toggle={() => setIsOpen(!isOpen)}>
        <ModalBody>
          <Form onSubmit={(e) => e.preventDefault()}>
            <FormGroup>
              <FormInput
                name="first"
                placeholder="First"
                value={selected && selected.first}
                onChange={(e) => {
                  setSelected((updated) => {
                    return {
                      ...updated,
                      first: e.target.value,
                    };
                  });
                }}
                invalid={formError && formError.path === "first"}
              />
              <FormFeedback>{formError && formError.message}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <FormInput
                name="last"
                placeholder="Last"
                value={selected && selected.last}
                onChange={(e) => {
                  setSelected((updated) => {
                    return {
                      ...updated,
                      last: e.target.value,
                    };
                  });
                }}
                invalid={formError && formError.path === "last"}
              />
              <FormFeedback>{formError && formError.message}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <FormInput
                name="dob"
                placeholder="DOB"
                type="date"
                value={selected && moment(selected.dob).format("YYYY-MM-DD")}
                onChange={(e) => {
                  setSelected((updated) => {
                    return {
                      ...updated,
                      dob: e.target.value,
                    };
                  });
                }}
                invalid={formError && formError.path === "dob"}
              />
              <FormFeedback>{formError && formError.message}</FormFeedback>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button className="btn-accent" onClick={() => handleSubmit()}>
            Save
          </Button>
          <Button className="btn-accent" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const columns = [
  {
    Header: "id",
    accessor: "_id",
    show: false,
  },
  {
    Header: "Last",
    accessor: "last",
  },
  {
    Header: "First",
    accessor: "first",
  },
  {
    Header: "DOB",
    accessor: "dob",
    Cell: (row) => {
      return <span>{moment.utc(row.original.dob).format("MM-DD-YY")}</span>;
    },
  },
  {
    accessor: "actions",
    maxWidth: 130,
    minWidth: 40,
    sortable: false,
    Cell: (row) => (
      <ButtonGroup size="sm" className="d-table mx-auto my-auto">
        <Button
          theme="white"
          onClick={() => {
            console.log("del", row);
          }}
        >
          <i className="material-icons">&#xE872;</i>
        </Button>
      </ButtonGroup>
    ),
  },
];

export default TravelersCard;
