import React, { useState } from "react";
import moment from "moment";
import { Container, Row, Col, Card, CardBody } from "shards-react";
import PageAlert, { usePageAlert } from "../common/Alert/PageAlert";
import AreYouSure, { useAreYouSure } from "../common/AreYouSure/AreYouSure";
import { useQueryClient } from "react-query";
import { setAdminManagerQueryDefaults } from "../query/adminManager.query";
import { authService } from "../services/auth.service";
// Page componnenets
import PageHeader, { useDateChangeHandler } from "../common/PageHeader";
import UserDataTable from "../components/AdminManager/UserDataTable";
import ClientDataTable from "../components/AdminManager/ClientDataTable";
import TableHeader from "../components/AdminManager/TableHeader";
// Hooks
import { useSearchBar } from "../custom/SiteSearchBar/SiteSearchBar";

const tableOptions = ["Users", "Clients"];

const AdminManager = () => {
  const queryClient = useQueryClient();
  setAdminManagerQueryDefaults(queryClient);
  const { alertPage, alertProps } = usePageAlert();
  const { areYouSureProps, setDeleteHandler } = useAreYouSure();

  const { id: userId } = authService.userValue;
  // Table
  const [activeTable, setActiveTable] = useState("Clients");

  // State
  const [limit, setLimit] = useState(5);
  const { search, setSearch } = useSearchBar("");
  const [start, handleStartChange, end, handleEndChange] =
    useDateChangeHandler();

  return (
    <>
      <PageAlert alertProps={alertProps} />
      <Container fluid className="main-content-container px-4">
        <PageHeader
          title="Admin"
          subtitle="Dashboard"
          dateStart={start}
          dateEnd={end}
          handleStartChange={handleStartChange}
          handleEndChange={handleEndChange}
        />
        <Row>
          <Col md="12">
            <Card className="p-0 mb-4">
              <TableHeader
                table={activeTable}
                setTable={setActiveTable}
                tableOptions={tableOptions}
                search={search}
                setSearch={setSearch}
              />
              <CardBody className="p-0">
                <ClientDataTable
                  table={activeTable}
                  limit={limit}
                  search={search}
                  start={moment(start).format("YYYY-MM-DD")}
                  end={moment(end).format("YYYY-MM-DD")}
                  setLimit={setLimit}
                  pageSizeOptions={[5, 10, 25, 50, 75, 100]}
                  alertPage={alertPage}
                  setDeleteHandler={setDeleteHandler}
                />
                <UserDataTable
                  userId={userId}
                  table={activeTable}
                  limit={limit}
                  search={search}
                  start={moment(start).format("YYYY-MM-DD")}
                  end={moment(end).format("YYYY-MM-DD")}
                  setLimit={setLimit}
                  pageSizeOptions={[5, 10, 25, 50, 75, 100]}
                  alertPage={alertPage}
                  setDeleteHandler={setDeleteHandler}
                ></UserDataTable>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <AreYouSure areYouSureProps={areYouSureProps} />
    </>
  );
};

export default AdminManager;
