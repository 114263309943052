import React from "react";
import { Container, Row, Card, CardHeader, CardBody } from "shards-react";
import GlobalSearchBar from "./GlobalSearchBar";
import PageSizeSelect from "./PageSizeSelect";

const TableHeader = ({
  limit,
  setLimit,
  pageSizeOptions,
  search,
  setSearch,
}) => {
  return (
    <CardHeader className="text-center p-0">
      <Container fluid className="file-manager__filters border-bottom">
        <Row>
          <PageSizeSelect
            limit={limit}
            setLimit={setLimit}
            pageSizeOptions={pageSizeOptions}
          />
          <GlobalSearchBar search={search} setSearch={setSearch} />
        </Row>
      </Container>
      {/* <FileDropzone /> */}
    </CardHeader>
  );
};

export default TableHeader;
