import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Col } from "shards-react";
import SidebarMainNavbar from "./SidebarMainNavbar";
import SidebarSearch from "./SidebarSearch";
import SidebarNavItems from "./SidebarNavItems";
import { MenuContext } from "../../../context/MenuContext";

const MainSidebar = ({ hideLogoText }) => {
  const { sidebarNavItems, menuVisible } = useContext(MenuContext);

  return (
    <Col
      tag="aside"
      className={`main-sidebar px-0 col-12 ${menuVisible ? "open" : ""}`}
      lg={{ size: 2 }}
      md={{ size: 3 }}
    >
      <SidebarMainNavbar hideLogoText={hideLogoText} />
      <SidebarSearch />
      {sidebarNavItems && <SidebarNavItems sidebarNavItems={sidebarNavItems} />}
    </Col>
  );
};

MainSidebar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool,
};

MainSidebar.defaultProps = {
  hideLogoText: false,
};

export default MainSidebar;
