import React from "react";
import { useQueryClient } from "react-query";

export function useClientPreFetch() {
  const [selectId, setSelectId] = React.useState();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    if (selectId) {
      // return () => {
      queryClient.prefetchQuery(["client", selectId]);
      // };
    }
  }, [selectId]);

  return setSelectId;
}
