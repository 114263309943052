import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
  Button,
} from "shards-react";

import ReactTable from "react-table-v6";

const BookingItemCard = ({
  label,
  data,
  columns,
  setSelected,
  handleDelete,
  type,
  imgIcon,
}) => {
  return (
    <Card className="p-0 mb-4 h-100">
      <CardHeader className="p-0">
        <Container fluid className="file-manager__filters border-bottom">
          <Row>
            <Col className="text-left pl-4">
              {imgIcon && (
                <img
                  className="country-flag mr-1"
                  src={imgIcon}
                  alt={type}
                  style={{ height: "40px" }}
                />
              )}
              <h6 className="m-0">{label}</h6>
            </Col>
          </Row>
        </Container>
      </CardHeader>
      <CardBody className="p-0">
        <Container fluid className="p-0 border-bottom">
          <ReactTable
            data={data || []}
            columns={columns}
            pageSize={(data && data.length) || 0}
            showPagination={false}
            getTdProps={(state, rowInfo, column, instance) => {
              return {
                onClick: () => {
                  if (column.id === "actions") {
                    handleDelete(rowInfo.original, type);
                  } else {
                    setSelected(rowInfo.original);
                  }
                },
              };
            }}
          />
        </Container>
      </CardBody>
      <CardFooter className="pt-0">
        <Button className="btn-accent" onClick={() => setSelected({})}>
          Add
        </Button>
      </CardFooter>
    </Card>
  );
};

export default BookingItemCard;
