import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { NavItem, Collapse, DropdownItem } from "shards-react";
import NotificationItem from "./NotificationItem";
import { MenuContext } from "../../context/MenuContext";
import NotificationIcon from "./NotificationIcon";

const Notifications = React.memo(() => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const { newNot, notList } = useContext(MenuContext);

  return (
    <NavItem
      className="border-right dropdown notifications"
      onMouseLeave={() => setIsOpen(false)}
    >
      <NotificationIcon isOpen={isOpen} setIsOpen={setIsOpen} newNot={newNot} />
      <Collapse open={isOpen} className="dropdown-menu dropdown-menu-small">
        {notList.map((item) => {
          return (
            <NotificationItem
              message={item.message}
              type={item.type}
              status={item.status}
              key={item.id}
              id={item.id}
            />
          );
        })}
        <DropdownItem
          onClick={() => history.push("/user/profile")}
          className="notification__all text-center"
        >
          View all Notifications
        </DropdownItem>
      </Collapse>
    </NavItem>
  );
});

export default Notifications;
