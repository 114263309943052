import React from "react";
import PropTypes from "prop-types";
import capitalize from "../../utils/capitalize";
import {
  Card,
  CardHeader,
  Button,
  ListGroup,
  ListGroupItem,
  Progress,
} from "shards-react";

const UserDetails = ({ userDetails }) => (
  <Card small className="mb-4 pt-3 h-100">
    <CardHeader className="border-bottom text-center">
      <div className="mb-3 mx-auto">
        <img
          className="rounded-circle"
          src={userDetails.avatar}
          alt={userDetails.email}
          width="110"
        />
      </div>
      <h4 className="mb-0">{`${capitalize(userDetails.firstName)} ${capitalize(
        userDetails.lastName
      )}`}</h4>
      <span className="text-muted d-block mb-2">{userDetails.role}</span>
      {/*
        <Button pill outline size="sm" className="mb-2" theme="accent">
        <i className="material-icons mr-1">account_box</i> Finish Profile Setup
      </Button>
      */}
    </CardHeader>
    <ListGroup flush>
      {/*
       <ListGroupItem className="px-4">
        <div className="progress-wrapper">
          <strong className="text-muted d-block mb-2">{"Profile Setup"}</strong>
          <Progress className="progress-sm" value={74} theme="info">
            <span className="progress-value">{74}%</span>
          </Progress>
        </div>
      </ListGroupItem>
      */}

      <ListGroupItem className="p-4">
        <strong className="text-muted d-block mb-2">
          {userDetails.metaTitle}
        </strong>
        <span>{userDetails.metaValue}</span>
      </ListGroupItem>
    </ListGroup>
  </Card>
);

UserDetails.propTypes = {
  /**
   * The user details object.
   */
  userDetails: PropTypes.object,
};

UserDetails.defaultProps = {
  userDetails: {
    firstName: "User",
    lastName: "Account",
    avatar: require("./../../images/avatars/0.jpg"),
    jobTitle: "Client",
    performanceReportTitle: "Travel",
    performanceReportValue: 74,
    metaTitle: "Description",
    metaValue: "Private Client",
  },
};

export default UserDetails;
