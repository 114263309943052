import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormFeedback,
  Button,
} from "shards-react";
import { useFormData } from "../../common/FormCard/useFormData";
import { useSingleUpdate } from "../../query.hooks/users/users";
import { useEffect } from "react";

const UserAccountDetails = ({ id, user, status, pageAlert }) => {
  const { formRef, getFormValues, fillFormValues } = useFormData();
  const [unsaved, setUnsaved] = React.useState(false);
  const [errors, setErrors] = React.useState({});

  function setFormData(data) {
    if (!data) {
      setUnsaved(false);
      [...formRef.current.elements].map((element) => {
        if (element.name === "password") {
          element.value = "";
        }
        return element;
      });
    } else setUnsaved(true);
  }

  const mutationUpdate = useSingleUpdate(setFormData, ["users", id], setErrors);

  React.useEffect(() => {
    if (user) {
      fillFormValues(user);
    }
  }, [user]);

  async function handleSubmit() {
    const formValues = getFormValues();
    const formData = {};
    for (const key in formValues) {
      if (Object.hasOwnProperty.call(formValues, key)) {
        if (formValues[key]) {
          formData[key] = formValues[key];
        }
      }
    }

    mutationUpdate.mutate({ id: user.id, body: formData });

    pageAlert({
      theme: "success",
      message: "User profile updated",
    });
  }

  useEffect(() => {
    if (errors.message) {
      pageAlert({
        theme: "danger",
        message: errors.message,
      });
    }
  }, [errors]);

  return (
    <Card small className="mb-4 h-100">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Account Details</h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Form innerRef={formRef} onSubmit={(e) => e.preventDefault()}>
                <Row form>
                  {/* First Name */}
                  <Col md="6" className="form-group">
                    <label htmlFor="feFirstName">First Name</label>
                    <FormInput
                      id="feFirstName"
                      placeholder="First Name"
                      name="firstName"
                      onFocus={() => setUnsaved(true)}
                      invalid={errors.field === "firstName" ? true : false}
                    />
                    <FormFeedback>{errors && errors.message}</FormFeedback>
                  </Col>
                  {/* Last Name */}
                  <Col md="6" className="form-group">
                    <label htmlFor="feLastName">Last Name</label>
                    <FormInput
                      id="feLastName"
                      placeholder="Last Name"
                      name="lastName"
                      onFocus={() => setUnsaved(true)}
                      invalid={errors.field === "lastName" ? true : false}
                    />
                    <FormFeedback>{errors && errors.message}</FormFeedback>
                  </Col>
                </Row>
                <Row form>
                  {/* Email */}
                  <Col md="6" className="form-group">
                    <label htmlFor="feEmail">Email</label>
                    <FormInput
                      type="email"
                      id="feEmail"
                      placeholder="Email Address"
                      name="email"
                      autoComplete="new-password"
                      onFocus={() => setUnsaved(true)}
                      invalid={errors.field === "email" ? true : false}
                    />
                    <FormFeedback>{errors && errors.message}</FormFeedback>
                  </Col>
                  {/* Password */}
                  <Col md="6" className="form-group">
                    <label htmlFor="fePassword">Password</label>
                    <FormInput
                      type="password"
                      id="fePassword"
                      placeholder="Password"
                      name="password"
                      autoComplete="new-password"
                      onFocus={() => setUnsaved(true)}
                      invalid={errors.field === "password" ? true : false}
                    />
                    <FormFeedback>{errors && errors.message}</FormFeedback>
                  </Col>
                </Row>

                {unsaved && (
                  <Button
                    onClick={() => {
                      handleSubmit();
                    }}
                    theme="accent"
                  >
                    Update Account
                  </Button>
                )}
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};

UserAccountDetails.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
};

UserAccountDetails.defaultProps = {
  title: "Account Details",
};

export default UserAccountDetails;
