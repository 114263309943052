import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink,
} from "shards-react";

import { logout, authService } from "../../../../services/auth.service";

const UserActions = () => {
  const [isVisable, setIsVisable] = useState(false);
  const user = authService.userValue;

  return (
    <Dropdown
      open={isVisable}
      toggle={() => setIsVisable((isVisable) => !isVisable)}
    >
      <DropdownToggle
        caret
        tag={NavLink}
        className="text-nowrap px-3"
        //onClick={() => setIsVisable((isVisable) => !isVisable)}
      >
        <span className="d-none d-md-inline-block">
          {user ? user.email : "User"}
        </span>
      </DropdownToggle>
      <Collapse tag={DropdownMenu} right small open={isVisable}>
        <DropdownItem tag={Link} to="/user/profile">
          <i className="material-icons">&#xE8B8;</i> Edit Profile
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={() => logout()} className="text-danger">
          <i className="material-icons text-danger">&#xE879;</i> Logout
        </DropdownItem>
      </Collapse>
    </Dropdown>
  );
};

export default UserActions;
