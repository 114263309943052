import React from "react";
import ReactTable from "react-table-v6";

const FileManagerTable = ({
  columns,
  data,
  pageSize,
  pages,
  loading,
  setSortBy,
  prefetchItem,
  setLimit,
  setPage,
}) => {
  return (
    <ReactTable
      columns={columns}
      data={data}
      defaultPageSize={pageSize}
      pages={pages}
      showPaginationTop={true}
      showPageSizeOptions={false}
      showPageJump={true}
      pageSize={pageSize}
      manual
      loading={loading}
      onPageChange={(pageIndex) => {
        setPage(pageIndex + 1);
      }} // Called when the page index is changed by the user
      onPageSizeChange={(pageSize, pageIndex) => {
        setLimit(pageSize);
        setPage(pageIndex + 1);
      }} // Called when the pageSize is changed by the user. The resolve page is also sent to maintain approximate position in the data
      onSortedChange={(newSorted) => {
        const direction = newSorted[0].desc ? "desc" : "asc";
        setSortBy(newSorted[0].id + ":" + direction);
        setPage(1);
      }}
      getTrProps={(state, rowInfo, column, instance) => {
        return {
          onMouseEnter: () => {
            prefetchItem(rowInfo.original.id);
          },
        };
      }}
      loadingText={"Loading..."}
      noDataText={"No records found"}
      pageText={"Page"}
    />
  );
};

export default FileManagerTable;
