import React from "react";
import { Route, Redirect } from "react-router-dom";
import { authService } from "../services/auth.service";

const ProtectedRoute = ({ route, index }) => {
  //const authCookie = useAuthCookie();

  const { roles } = route;
  return (
    <Route
      key={index}
      path={route.path}
      exact={route.exact}
      render={(props) => {
        // Authenticate
        const user = authService.userValue;
        if (!user) return backToLogin(props);
        // Role permission
        if (roles && roles.indexOf(user.role) === -1) {
          return <Redirect to={{ pathname: "/clients" }} />;
        }
        return (
          <route.layout user={user} {...props}>
            <route.component {...props} />
          </route.layout>
        );
      }}
    />
  );
};

function useUserCookie() {
  //const [cookies, setCookie] = useCookies(["user"]);
  //return cookies.user;
}
function useAuthCookie() {
  //const [cookies, setCookie] = useCookies(["auth"]);
  //return cookies.auth;
}

function backToLogin(props) {
  return (
    <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
  );
}

export default ProtectedRoute;
