import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  FormInput,
  Modal,
  ModalBody,
  FormGroup,
  Form,
  Button,
  ModalFooter,
  FormFeedback,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormCheckbox,
} from "shards-react";
import moment from "moment";
import AutoCompleteInput from "../../../custom/AutoCompleteInput/AutoCompleteInput";
import vendorService from "../../../services/vendor.service";
import VendorAutoCompleteInput from "../../../custom/VendorAutoCompleteInput/VendorAutoCompleteInput";

const BookingItemModal = ({
  children,
  isOpen,
  setIsOpen,
  selected,
  setSelected,
  isPackage,
  setIsPackage,
  formError,
  handleSubmit,
}) => {
  return (
    <Modal open={isOpen} toggle={() => setIsOpen(!isOpen)}>
      <ModalBody>
        <Form onSubmit={(e) => e.preventDefault()}>
          {children}

          <label htmlFor="feFromTo">From - To</label>
          <Row id="feFromTo" form>
            <Col className="form-group">
              <FormInput
                name="fromDate"
                placeholder="From"
                type="date"
                value={
                  selected && moment(selected.fromDate).format("YYYY-MM-DD")
                }
                onChange={(e) => {
                  setSelected((updated) => {
                    return {
                      ...updated,
                      fromDate: e.target.value,
                    };
                  });
                }}
                invalid={formError && formError.path === "fromDate"}
              />
              <FormFeedback>{formError && formError.message}</FormFeedback>
            </Col>
            <Col className="form-group">
              <FormInput
                name="toDate"
                placeholder="To"
                type="date"
                value={selected && moment(selected.toDate).format("YYYY-MM-DD")}
                onChange={(e) => {
                  setSelected((updated) => {
                    return {
                      ...updated,
                      toDate: e.target.value,
                    };
                  });
                }}
                invalid={formError && formError.path === "toDate"}
              />
              <FormFeedback>{formError && formError.message}</FormFeedback>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormCheckbox
                name="package"
                checked={isPackage}
                onChange={() => setIsPackage(!isPackage)}
              >
                Package
              </FormCheckbox>
            </Col>
            <Col>
              {!isPackage && (
                <InputGroup className="mb-3">
                  <InputGroupAddon type="prepend">
                    <InputGroupText>$</InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    name="price"
                    value={selected && selected.price}
                    onChange={(e) => {
                      setSelected((updated) => {
                        return {
                          ...updated,
                          price: e.target.value,
                        };
                      });
                    }}
                    invalid={formError && formError.path === "price"}
                  />
                </InputGroup>
              )}
            </Col>
          </Row>
          <Row from>
            <Col className="form-group">
              <VendorAutoCompleteInput
                value={selected && selected.vendor}
                placeholder="Vendor"
                onChange={(e) => {
                  setSelected((updated) => {
                    return {
                      ...updated,
                      vendor: e.target.value,
                    };
                  });
                }}
              />
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button className="btn-accent" onClick={() => handleSubmit()}>
          Save
        </Button>
        <Button className="btn-accent" onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default BookingItemModal;
