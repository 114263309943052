import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  FormSelect,
  FormInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "shards-react";

const TableFilters = ({ pageSize, setPageSize, search, setSearch }) => {
  const [pageSizeOptions] = useState([5, 10, 15, 20, 25, 30]);
  return (
    <Container fluid className="file-manager__filters border-bottom">
      <Row>
        {/* Filters :: Page Size */}
        <Col className="file-manager__filters__rows d-flex">
          <span>Show</span>
          <FormSelect
            size="sm"
            onChange={(e) => setPageSize(e.target.value)}
            value={pageSize}
          >
            {pageSizeOptions.map((size, idx) => (
              <option key={idx} value={size}>
                {size} rows
              </option>
            ))}
          </FormSelect>
        </Col>

        {/* Filters :: Search */}
        <Col className="file-manager__filters__search d-flex">
          <InputGroup seamless size="sm" className="ml-auto">
            <InputGroupAddon type="prepend">
              <InputGroupText>
                <i className="material-icons">search</i>
              </InputGroupText>
            </InputGroupAddon>
            <FormInput
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </InputGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default TableFilters;
