import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Button,
  Form,
  FormInput,
  CardHeader,
  ListGroup,
  ListGroupItem,
} from "shards-react";
import capitalizeArray from "../../../utils/capitalizeArray";

const PayoutForm = ({ invoices, title, handleSubmit, payout, setPayout }) => {
  return (
    <Card className="p-0 mb-4 h-100">
      <CardHeader className="border-bottom">
        <h6 className="m-0">{`Create Payout Invoice - ${title}`}</h6>
        <div className="block-handle" />
      </CardHeader>
      <CardBody className="p-0">
        <ListGroup flush>
          <ListGroupItem className="p-3">
            <Row>
              <Col>
                <Form>
                  <Row form>
                    <Col md="6" className="form-group">
                      <label htmlFor="feRefNum">Ref Number</label>
                      <FormInput
                        id="feRefNum"
                        placeholder="Reference Number"
                        value={(payout && payout.referenceNumber) || ""}
                        onChange={(e) => {
                          setPayout({
                            ...payout,
                            referenceNumber: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col md="6" className="form-group">
                      <label htmlFor="feCommission">Commission</label>
                      <FormInput
                        id="feCommission"
                        placeholder="Commission"
                        value={(payout && payout.commission) || ""}
                        onChange={(e) => {
                          setPayout({
                            ...payout,
                            commission: e.target.value,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
          <ListGroupItem className="p-3">
            {/* Vendor Item Table List */}
            <table className="table mb-0">
              <thead className="py-2 bg-light text-semibold border-bottom">
                <tr>
                  <th>Ref</th>
                  <th className="text-right">Price</th>
                  <th className="text-right">Comm</th>
                  <th className="text-right">Status</th>
                </tr>
              </thead>
              <tbody>
                {invoices &&
                  invoices.results.map((item, idx) => (
                    <tr key={idx}>
                      <td className="text-left">{item.referenceNumber}</td>
                      <td className="text-right">${item.price}</td>
                      <td className="text-right">${item.commission}</td>
                      <td className={"text-right"}>
                        {capitalizeArray(item.status)}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </ListGroupItem>
        </ListGroup>
      </CardBody>
      <CardFooter className="border-top">
        <Row className="mb-2">
          <Button theme="accent" type="submit" onClick={() => handleSubmit()}>
            Save Changes
          </Button>
        </Row>
      </CardFooter>
    </Card>
  );
};

function getStatusColor(status) {
  const statusColor = {
    Pending: "secondary",
    pending: "secondary",
    Paid: "success",
    paid: "success",
    Error: "danger",
    error: "danger",
    Cxl: "danger",
    cxl: "danger",
  };
  return statusColor[statusColor];
}

export default PayoutForm;
