import { http } from "./http.service";
import { BehaviorSubject } from 'rxjs';

const userSubject = new BehaviorSubject(null);


export const authService = {
    login,
    logout,
    refreshToken,
    register,
    forgotPassword,
    resetPassword,
    user: userSubject.asObservable(),
    get userValue() { return userSubject.value }
};

export function register(params) {
    return http.post("/auth/register", params);
}

export function login({ email, password }) {
    return http.post("/auth/login", { email, password }).then((response) => {
        const { user, token } = response.data;
        userSubject.next({ ...user, token: token.token });
        startRefreshTokenTimer(token.expires);
        return user;
    });
}

export function logout() {
    http.post(`/auth/logout`, {}).then(() => {
        stopRefreshTokenTimer();
        userSubject.next(null);
        location.replace("/");
    });
}

export function refreshToken() {
    stopRefreshTokenTimer();
    return http.post(`/auth/refresh-tokens`, {})
        .then(response => {
            const { user, token } = response.data;
            userSubject.next({ ...user, token: token.token });
            startRefreshTokenTimer(token.expires);
            return user;
        }).catch((ex) => {
            console.log("You must login to access");
        })
}

export function forgotPassword(email) {
    return http.post("/auth/forgot-password", { email });
}

export function resetPassword({ token, password }) {
    return http.post(`/auth/reset-password?token=${token}`, { password });
}

export default authService;

let refreshTokenTimeout;

function startRefreshTokenTimer(exp) {
    const expires = new Date(exp);
    const timeout = ((expires.getTime() - Date.now() - (60 * 1000)));
    refreshTokenTimeout = setTimeout(refreshToken, timeout);
}

function stopRefreshTokenTimer() {
    clearTimeout(refreshTokenTimeout);
}