import React, { useContext } from "react";
import PropTypes from "prop-types";
import { NavLink as RouteNavLink } from "react-router-dom";
import {
  NavItem,
  NavLink,
  DropdownMenu,
  DropdownItem,
  Collapse,
} from "shards-react";

import { MenuContext } from "../../../context/MenuContext";

const SidebarNavItem = ({ item }) => {
  const { toggleSidebarDropdown } = useContext(MenuContext);
  const hasSubItems = item.items && item.items.length;

  return (
    <NavItem style={{ position: "relative" }}>
      <NavLink
        className={hasSubItems && "dropdown-toggle"}
        tag={hasSubItems ? "a" : RouteNavLink}
        to={hasSubItems ? "#" : item.to}
        onClick={() => toggleSidebarDropdown(item)}
        exact
      >
        {item.htmlBefore && (
          <div
            className="d-inline-block item-icon-wrapper"
            dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
          />
        )}
        {item.title && <span>{item.title}</span>}
        {item.htmlAfter && (
          <div
            className="d-inline-block item-icon-wrapper"
            dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
          />
        )}
      </NavLink>
      {hasSubItems && (
        <Collapse tag={DropdownMenu} small open={item.open} style={{ top: 0 }}>
          {item.items.map((subItem, idx) => (
            <DropdownItem key={idx} tag={RouteNavLink} to={subItem.to}>
              {subItem.title}
            </DropdownItem>
          ))}
        </Collapse>
      )}
    </NavItem>
  );
};

SidebarNavItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object,
};
export default SidebarNavItem;
