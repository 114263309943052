import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  FormGroup,
  FormInput,
  FormSelect,
  Form,
  FormFeedback,
  ModalFooter,
  ModalHeader,
} from "shards-react";
import PageHeader, { useDateChangeHandler } from "../common/PageHeader";
import moment from "moment";
import { useMutation, useQuery, useQueryClient } from "react-query";
import PageAlert from "../common/Alert/PageAlert";
import { useAlert } from "../common/Alert/useAlert";
import AreYouSure, { useAreYouSure } from "../common/AreYouSure/AreYouSure";
import { setVendorQueryDefaults } from "../query/vendor.query";
import { useSearchBar } from "../custom/SiteSearchBar/SiteSearchBar";
import TableHeader from "../components/UserManager/TableHeader";
import DataTable from "../components/payouts/DataTable";
import capitalizeArray from "../utils/capitalizeArray";
import payoutService from "../services/payout.service";

const Payouts = () => {
  const queryClient = useQueryClient();
  setVendorQueryDefaults(queryClient);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState();
  const [formError, setFormError] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [sortBy, setSortBy] = useState("primary.lastName:asc");
  const { search, setSearch } = useSearchBar("");
  const [start, handleStartChange, end, handleEndChange] =
    useDateChangeHandler();
  const [status, setStatus] = useState("All");

  /**
   * Bookings Query
   */
  const { status: queryStatus, data } = useQuery([
    "payouts",
    {
      page,
      limit,
      sortBy,
      search,
      status,
      start: moment(start).format("YYYY-MM-DD"),
      end: moment(end).format("YYYY-MM-DD"),
    },
  ]);

  const { alertPage, alertProps } = useAlert();
  const { areYouSureProps, setDeleteHandler } = useAreYouSure();

  const payoutUpdater = useMutation("updatePayout");
  function handleUpdate(item, update) {
    payoutUpdater.mutate(
      {
        id: item.id,
        body: update,
        key: [
          "payouts",
          {
            page,
            limit,
            sortBy,
            search,
            status,
            start: moment(start).format("YYYY-MM-DD"),
            end: moment(end).format("YYYY-MM-DD"),
          },
        ],
      },
      {
        onError: alertPage.error(`Unable to update payout`),
        onSuccess: alertPage.success(
          `Payout from: ${item.vendor.name} UPDATED`
        ),
      }
    );
  }

  const payoutDeleter = useMutation("deletePayout");
  function handleDelete(item) {
    setDeleteHandler({
      deleteFn: async () => {
        payoutDeleter.mutate(
          {
            id: item.id,
            key: [
              "payouts",
              {
                page,
                limit,
                sortBy,
                search,
                status,
                start: moment(start).format("YYYY-MM-DD"),
                end: moment(end).format("YYYY-MM-DD"),
              },
            ],
          },
          {
            onError: alertPage.error(
              `Unable to delete payout from: ${item.vendor.name}`
            ),
            onSuccess: alertPage.success(
              `Payout from: ${item.vendor.name} DELETED`
            ),
          }
        );
      },
      item: `${item.vendor.name} payout`,
    });
  }

  function handleSelect(item) {
    setSelected({
      ...item,
      title: `${item.vendor.name} - ${item.referenceNumber}`,
    });
  }

  useEffect(() => {
    if (selected) {
      setIsOpen(true);
    }
  }, [selected]);

  async function handleSubmit() {
    try {
      await payoutService.updatePayout(selected.id, {
        status: selected.status,
        paidOn: selected.paidOn,
      });
      queryClient.invalidateQueries([
        "payouts",
        {
          page,
          limit,
          sortBy,
          search,
          status,
          start: moment(start).format("YYYY-MM-DD"),
          end: moment(end).format("YYYY-MM-DD"),
        },
      ]);
      setIsOpen(false);
    } catch (ex) {
      alertPage.error(`Unable to update payout from: ${selected.title}`);
    }
  }

  return (
    <>
      <PageAlert alertProps={alertProps} />
      <Container fluid className="main-content-container px-4">
        {status === "success" && (
          <PageHeader
            title="Payout List"
            subtitle="Agent"
            dateStart={start}
            dateEnd={end}
            handleStartChange={handleStartChange}
            handleEndChange={handleEndChange}
          />
        )}
        <Row className="mb-4">
          <Col md="12">
            <Card className="p-0 mb-4 h-100">
              <TableHeader
                table={status}
                setTable={setStatus}
                tableOptions={["All", "Pending", "Paid", "Cxl", "Error"]}
                search={search}
                setSearch={setSearch}
              />
              <CardBody className="p-0">
                <div className="">
                  <DataTable
                    columns={columns(handleDelete, handleSelect)}
                    data={data && data.results}
                    pageSize={limit}
                    pages={data && data.totalPages}
                    loading={queryStatus === "loading"}
                    setPage={setPage}
                    setLimit={setLimit}
                    setSortBy={setSortBy}
                    prefetchItem={() => {}}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="5"></Col>
        </Row>
      </Container>
      <Modal open={isOpen} toggle={() => setIsOpen(!isOpen)}>
        <ModalHeader>{`${selected && selected.title}`}</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <FormSelect
                name="Status"
                placeholder="Status"
                value={selected && selected.status}
                onChange={(e) => {
                  setSelected((updated) => {
                    return {
                      ...updated,
                      status: e.target.value,
                    };
                  });
                }}
                invalid={formError && formError.path === "to"}
              >
                <option>Pending</option>
                <option>Paid</option>
                <option>Cxl</option>
                <option>Error</option>
              </FormSelect>
              <FormFeedback>{formError && formError.message}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <FormInput
                name="paidOn"
                placeholder="Paid On"
                value={selected && selected.paidOn}
                onChange={(e) => {
                  setSelected((updated) => {
                    return {
                      ...updated,
                      paidOn: e.target.value,
                    };
                  });
                }}
                invalid={formError && formError.path === "to"}
              />
              <FormFeedback>{formError && formError.message}</FormFeedback>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button className="btn-accent" onClick={() => handleSubmit()}>
            Save
          </Button>
          <Button className="btn-accent" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <AreYouSure areYouSureProps={areYouSureProps} />
    </>
  );
};

const columns = (delFn, editFn) => [
  {
    Header: "id",
    accessor: "_id",
    show: false,
  },
  {
    Header: "Booked",
    accessor: "bookingDate",
    minWidth: 40,
    Cell: (row) => {
      return <span>{moment(row.original.bookingDate).format("MM-DD")}</span>;
    },
  },
  {
    Header: "Vendor",
    accessor: "vendor.name",
  },
  {
    Header: "Ref",
    accessor: "referenceNumber",
  },
  {
    Header: "Status",
    accessor: "status",
    minWidth: 50,
    Cell: (row) => {
      return (
        <span className={`text-${statusClass[row.original.status]}`}>
          {capitalizeArray(row.original.status)}
        </span>
      );
    },
  },
  {
    Header: "Price",
    accessor: "price",
    minWidth: 50,
    Cell: (row) => <span>${`${row.original.price}`}</span>,
  },

  {
    Header: "Comm",
    accessor: "commission",
    minWidth: 50,
    Cell: (row) => <span>${`${row.original.commission}`}</span>,
  },
  {
    Header: "Paid",
    accessor: "paidOn",
    minWidth: 50,
    Cell: (row) => <span>${`${row.original.paidOn}`}</span>,
  },
  {
    Header: "Agent",
    accessor: "agent.firstName",
    minWidth: 60,
    Cell: (row) => (
      <span>{`${row.original.agent.lastName}, ${row.original.agent.firstName}`}</span>
    ),
  },
  {
    accessor: "actions",
    maxWidth: 130,
    minWidth: 50,
    sortable: false,
    Cell: (row) => (
      <ButtonGroup size="sm" className="d-table my-auto p-2">
        <Button
          theme="white"
          onClick={() => {
            delFn(row.original);
          }}
        >
          <i className="material-icons">&#xE872;</i>
        </Button>
        <Button
          theme="white"
          onClick={() => {
            editFn(row.original);
          }}
        >
          <i className="material-icons">&#xE254;</i>
        </Button>
      </ButtonGroup>
    ),
  },
];

const statusClass = {
  paid: "success",
  Paid: "success",
  pending: "warning",
  Pending: "warning",
  error: "danger",
  Error: "danger",
};
export default Payouts;
