import {
  getAllUsers,
  getUser,
  updateUser,
  deleteUser,
} from "../../services/user.service";
import {
  useQuery,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
  useMutation,
} from "react-query";
import {
  useMutationUpdate,
  useMutationToggleUpdate,
  useMutationDelete,
  useMutationSingleUpdate,
} from "../mutation/useCreateMutations";
import {
  usePrefetchPages,
  usePrefetchPageSize,
  usePrefetchId,
} from "../prefetch/usePreFetchers";

/**
 * - useUsers
 *
 * @param {object} param0
 * @returns React Query - Many Records
 */
export function useUsers({ page, limit, sortBy, search, start, end }) {
  return useQuery(
    ["users", { page, limit, sortBy, search, start, end }],
    async () => {
      const { data } = await getAllUsers({
        page,
        limit,
        sortBy,
        search,
        start,
        end,
      });
      return data;
    },
    { staleTime: 10 * 1000 }
  );
}
/**
 * - useUser
 *
 * @param {string} id
 * @returns React Query - Single Record
 */
export function useUser(id) {
  return useQuery(
    ["users", id],
    async () => {
      if (id) {
        const { data } = await getUser(id);
        return data;
      }
    },
    { staleTime: 10 * 1000 }
  );
}

/**
 * - useUpdate
 *
 * @param {function} setFormData set form data state
 * @param {array} queryKey query key for page data
 * @param {function} setFormError set form error state
 * @returns {object} useMutation object
 */
export function useUpdate(setFormData, queryKey, setFormError) {
  return useMutationUpdate(
    (update) => updateUser(update.id, update.body),
    setFormData,
    queryKey,
    setFormError
  );
}

/**
 * - useSingleUpdate
 *
 * @param {function} setFormData set form data state
 * @param {array} queryKey query key for page data
 * @param {function} setFormError set form error state
 * @returns {object} useMutation object
 */
export function useSingleUpdate(setFormData, queryKey, setFormError) {
  return useMutationSingleUpdate(
    (update) => updateUser(update.id, update.body),
    setFormData,
    queryKey,
    setFormError
  );
}

/**
 * - useToggleUpdate
 *
 * @param {array} queryKey query key for page data
 * @returns {object} useMutation object
 */
export function useToggleUpdate(queryKey) {
  return useMutationToggleUpdate(
    (update) => updateUser(update.id, update.body),
    queryKey
  );
}

/**
 * - useDelete
 *
 * @param {array} queryKey query key for page data
 * @returns {object} useMutation object
 */
export function useDelete(queryKey) {
  return useMutationDelete(deleteUser, queryKey);
}

/**
 * - usePrefetchUsers
 *
 * @param {object} param0
 * @param {object} data
 * @param {array} pageSizeOptions
 * @returns {function} setPreFetchId
 */
export function usePrefetchUsers(
  { page, limit, sortBy, search, start, end },
  data,
  pageSizeOptions
) {
  usePrefetchPages(
    "users",
    { page, limit, sortBy, search, start, end },
    data,
    getAllUsers
  );
  usePrefetchPageSize(
    "users",
    pageSizeOptions,
    { page, limit, sortBy, search, start, end },
    data,
    getAllUsers
  );
  const setPreFetchId = usePrefetchId("users", getUser);
  return setPreFetchId;
}

export function usePrefetchUser() {
  const setPreFetchId = usePrefetchId("users", getUser);
  return setPreFetchId;
}

const exports = {
  useUsers,
  useUser,
  useUpdate,
  useSingleUpdate,
  usePrefetchUsers,
  usePrefetchUser,
};

export default exports;
