import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
  ButtonGroup,
  Button,
} from "shards-react";

const PaymentInfo = ({ title, data }) => {
  return (
    <Card small className="payout-stats">
      <CardHeader className="border-bottom">
        <Row className="d-flex">
          <Col className="fmr-auto p-2">
            <h6 className="m-0"></h6>
          </Col>
          <Col className="p-2 text-right"></Col>
        </Row>
      </CardHeader>
      <CardBody className="p-0">
        <Container fluid className="px-0">
          <table className="table mb-0">
            <thead className="py-2 bg-light text-semibold border-bottom">
              <tr>
                <th>Total Price</th>
                <th className="text-right"></th>
                <th className="text-right">${data && data.totalPrice}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td className="lo-stats__order-details">Cost Per Person</td>
                <td className="lo-stats__order-details text-right">
                  ${data && data.costPerPerson}
                </td>
              </tr>
              <tr>
                <td className="lo-stats__order-details"></td>
                <td className="lo-stats__order-details text-right"></td>
                <td></td>
              </tr>
              <tr>
                <td className="lo-stats__order-details">Amount Paid</td>
                <td className="lo-stats__order-details text-right">
                  ${data && data.amountPaid}
                </td>
                <td></td>
              </tr>
              <tr>
                <td className="lo-stats__order-details">Amount Due</td>
                <td className="lo-stats__order-details text-right">
                  ${data && data.totalPrice - data.amountPaid}
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </Container>
      </CardBody>

      {/*
 <CardFooter className="border-top">
        <Row>
          <Col>
            Final Payment: $
            {data && data.length > 0 && data[data.length - 1].payment}
          </Col>
          <Col className="text-center view-report">
            Due:{" "}
            {data &&
              data.length > 0 &&
              moment(data && data[data.length - 1].due).format("MM-DD-YYYY")}
          </Col>
        </Row>
      </CardFooter>
*/}
    </Card>
  );
};

export default PaymentInfo;
