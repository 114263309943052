import { useState, useCallback, memo } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Row } from "shards-react";

// Core
import { Dustbin } from "./Dustbin";
import { Box } from "./Box";
import SectionTitle from "../common/SectionTitle";

export const Container = memo(function Container({
  userId,
  folders,
  previewFile,
  selectedDirectory,
  setSelectedDirectory,
  selectedFile,
  setSelectedFile,
  search,
}) {
  const queryClient = useQueryClient();

  // Query - get Files
  const { data: files } = useQuery([
    "uploadCards",
    { userId, dir: selectedDirectory, search },
  ]);

  // Mutation - change file
  const changeFolderMutation = useMutation("moveUploadsArray");

  // Dustbins - Directories
  const [dustbins] = useState([
    ...folders.map((folder) => ({ accepts: [folder, "Document"] })),
  ]);

  // Handle file drop
  const handleDrop = useCallback(
    (item) => {
      changeFolderMutation.mutate({
        userId,
        uploadId: item.fileId,
        params: { folder: item.dir },
        key: ["uploadCards", { userId, dir: selectedDirectory, search }],
      });
    },
    [dustbins, selectedDirectory]
  );

  // PreFetch
  const preFetchData = (dir) => {
    queryClient.prefetchQuery(["uploadCards", { userId, dir: dir, search }], {
      staleTime: 10 * 1000,
    });
  };

  return (
    <div>
      {dustbins && dustbins.length > 1 && <SectionTitle title="Directories" />}
      <Row>
        {dustbins &&
          dustbins.map(({ accepts }, index) => (
            <Dustbin
              accept={accepts}
              onDrop={(item) => handleDrop({ ...item, dir: accepts[0] })}
              key={index}
              setSelected={setSelectedDirectory}
              selected={selectedDirectory}
              preFetch={() => preFetchData(accepts[0])}
            />
          ))}
      </Row>
      {files && files.length > 1 && <SectionTitle title="Documents" />}
      <Row>
        {files &&
          files.map(
            ({ fileName, id, fileType, fileSize, originalname }, index) => (
              <Box
                userId={userId}
                name={fileName}
                fileName={originalname}
                type={"Document"}
                fileId={id}
                key={id}
                fileType={fileType}
                fileSize={fileSize}
                previewFile={previewFile}
                selected={selectedFile}
                setSelected={setSelectedFile}
              />
            )
          )}
      </Row>
    </div>
  );
});
