import React, { useState } from "react";
import { Container } from "./Container";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const Directories = ({
  getFiles,
  moveFile,
  userId,
  folders,
  previewFile,
  selectedDirectory,
  setSelectedDirectory,
  selectedFile,
  setSelectedFile,
  search,
}) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <Container
        getFiles={getFiles}
        moveFile={moveFile}
        userId={userId}
        folders={folders}
        previewFile={previewFile}
        selectedDirectory={selectedDirectory}
        setSelectedDirectory={setSelectedDirectory}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        search={search}
      />
    </DndProvider>
  );
};

export default Directories;
