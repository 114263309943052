import { authService } from "./auth.service";
import { createAuthCaller } from "./http.service";
import queryString from "query-string";



export function createVendor(params) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.post("/vendors", params);
}

export function getAllVendors(params) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    const query = queryString.stringify(params);
    return http.get(`/vendors?${query}`)
}

export function getVendor(id) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.get(`/vendors/${id}`);
}

export function updateVendor(id, vendor) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.patch(`/vendors/${id}`, vendor);
}

export function deleteVendor(id) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.delete(`/vendors/${id}`);
}

export function getVendorsByNameAutoComplete(name) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.get(`/vendors/options?name=${name}`)
}

export function getBookingVendors(booking) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.get(`/vendors/bookings/${booking}`)
}

export function getBookingVendor(booking, vendor) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.get(`/vendors/bookings/${booking}?vendorId=${vendor}`)
}

export function createPayout(params) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.post("/vendors/payouts", params);
}



const vendorService = {
    deleteVendor,
    updateVendor,
    getVendor,
    getAllVendors,
    createVendor,
    getVendorsByNameAutoComplete,
    getBookingVendors,
    getBookingVendor,
    createPayout,

}

export default vendorService;