import { memo } from "react";
import { useDrop } from "react-dnd";
import classNames from "classnames";
import { Col, Card, CardFooter } from "shards-react";

export const Dustbin = memo(function Dustbin({
  accept,
  onDrop,
  selected,
  setSelected,
  preFetch,
}) {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = isOver && canDrop;

  let isSelected;

  if (selected === accept[0]) isSelected = true;

  const classes = classNames(
    (isSelected || isActive) && "file-manager__item--selected",
    "file-manager__item",
    "file-manager__item--directory",
    "mb-3"
  );
  return (
    <Col lg="3">
      <div ref={drop} role="Dustbin">
        <Card
          small
          className={classes}
          onClick={() => {
            if (selected === accept[0]) setSelected("");
            else setSelected(accept[0]);
          }}
          onMouseEnter={preFetch}
        >
          <CardFooter>
            <span className="file-manager__item-icon">
              <i className="material-icons">&#xE2C7;</i>
            </span>
            <h6 className="file-manager__item-title">{accept[0]}</h6>
          </CardFooter>
        </Card>
      </div>
    </Col>
  );
});
