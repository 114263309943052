import React from "react";
import { FormInput, FormGroup, FormFeedback } from "shards-react";
import moment from "moment";
import bookingValidation from "./validation/booking.validation";
import BookingItemCard from "./common/BookingItemCard";
import BookingItemModal from "./common/BookingItemModal";
import useBooking from "./common/bookingHook";
import bookingColumns from "./columns/booking.columns";
import hotelsImgIcon from "./Payouts/hotels.png";

const HotelsCard = ({ bookingId, label, data, handleDelete }) => {
  const {
    handleSubmit,
    isOpen,
    setIsOpen,
    selected,
    setSelected,
    formError,
    isPackage,
    setIsPackage,
  } = useBooking(bookingId, "hotels", bookingValidation.hotel);

  return (
    <>
      <BookingItemCard
        imgIcon={hotelsImgIcon}
        label={label}
        data={data}
        columns={bookingColumns.hotels}
        setSelected={setSelected}
        handleDelete={handleDelete}
        type="hotels"
      />
      <BookingItemModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        selected={selected}
        setSelected={setSelected}
        isPackage={isPackage}
        setIsPackage={setIsPackage}
        formError={formError}
        handleSubmit={handleSubmit}
      >
        <FormGroup>
          <FormInput
            name="hotel"
            placeholder="Hotel"
            value={selected && selected.hotel}
            onChange={(e) => {
              setSelected((updated) => {
                return {
                  ...updated,
                  hotel: e.target.value,
                };
              });
            }}
            invalid={formError && formError.path === "hotel"}
          />
          <FormFeedback>{formError && formError.message}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <FormInput
            name="room"
            placeholder="Room"
            value={selected && selected.room}
            onChange={(e) => {
              setSelected((updated) => {
                return {
                  ...updated,
                  room: e.target.value,
                };
              });
            }}
            invalid={formError && formError.path === "room"}
          />
          <FormFeedback>{formError && formError.message}</FormFeedback>
        </FormGroup>
      </BookingItemModal>
    </>
  );
};

export default HotelsCard;
