import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Form } from "shards-react";
import capitalizeArray from "../utils/capitalizeArray";
import PageAlert, { usePageAlert } from "../common/Alert/PageAlert";
import AreYouSure, { useAreYouSure } from "../common/AreYouSure/AreYouSure";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { setAdminManagerQueryDefaults } from "../query/adminManager.query";
import DataTableCard from "../common/DataTableCard/DataTableCard";
import ClientColumns from "../components/ClientList/ClientColumns";
import PageHeader, { useDateChangeHandler } from "../common/PageHeader";
import { useSearchBar } from "../custom/SiteSearchBar/SiteSearchBar";
import { createClient } from "../services/client.service";
import clientValidation from "../validation/client.validation";
import NewClientForm from "../components/ClientList/NewClientForm";
import moment from "moment";

/* Client Table Actions */
function handleViewClient() {
  let history = useHistory();
  return function handleClick(id, page) {
    if (!page) history.push("/clients/" + id);
    if (page === "edit") history.push("/clients/" + id + "/edit");
    if (page === "users") history.push("/clients/" + id + "/users");
  };
}

const ClientList = () => {
  /* Query Defaults */
  const queryClient = useQueryClient();
  setAdminManagerQueryDefaults(queryClient);

  // - Move this to context -
  const { alertPage, alertProps } = usePageAlert();
  const { areYouSureProps, setDeleteHandler } = useAreYouSure();
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState();

  /* Table State */
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState("primary.lastName:asc");
  const { search, setSearch } = useSearchBar("");
  const [start, handleStartChange, end, handleEndChange] =
    useDateChangeHandler();

  /* Query */
  const { status, data } = useQuery([
    "clients",
    {
      page,
      limit,
      sortBy,
      search,
      start: moment(start).format("YYYY-MM-DD"),
      end: moment(end).format("YYYY-MM-DD"),
    },
  ]);

  /* Prefetch */
  useEffect(() => {
    if (data) {
      queryClient.prefetchQuery([
        "clients",
        {
          page: page + 1,
          limit,
          sortBy,
          search,
          start: moment(start).format("YYYY-MM-DD"),
          end: moment(end).format("YYYY-MM-DD"),
        },
      ]);
    }
  }, [data]);

  /* Move back a page when no results */
  // - This
  useEffect(() => {
    if (data) {
      if (!data.results[0] && page > 1) {
        setPage((page) => page - 1);
      }
    }
  }, [data]);

  /* Page limited to total pages */
  // - Or This
  useEffect(() => {
    if (data) {
      if (page > data.totalPages) {
        setPage(data.totalPages);
      }
    }
  }, [data]);

  /* Clear Form on Modal close */
  useEffect(() => {
    if (!isOpen) {
      setFormData({});
      setFormError();
    }
  }, [isOpen]);

  /* Action Functions */
  const goToViewClient = handleViewClient();

  /* Delete Function */
  // - Move this to action
  const clientDeleter = useMutation("deleteClient");
  function handleDelete(item) {
    setDeleteHandler({
      deleteFn: () => {
        /* Client Delete */
        clientDeleter.mutate(
          {
            id: item.id,
            key: ["clients", { page, limit, sortBy, search, start, end }],
          },
          {
            onError: alertPage.error(
              `Unable to delete client: ${capitalizeArray(
                item.primary.lastName
              )}, ${capitalizeArray(item.primary.firstName)}`
            ),
            onSuccess: alertPage.success(
              `Client: ${capitalizeArray(
                item.primary.lastName
              )}, ${capitalizeArray(item.primary.firstName)} DELETED`
            ),
          }
        );
      },
      item: `Client ${capitalizeArray(
        item.primary.lastName
      )}, ${capitalizeArray(item.primary.firstName)}`,
    });
  }

  /* Submit Function */
  async function handleSubmit() {
    try {
      const value = await clientValidation.newClient.validateAsync(formData);
      await createClient(value);
      queryClient.invalidateQueries([
        "clients",
        { page, limit, sortBy, search, start, end },
      ]);
      setIsOpen(false);
    } catch (ex) {
      // Validation error
      if (ex.isJoi) {
        return setFormError(ex.details[0]);
      }
      // Api response error
      if (ex.response) {
        return alertPage.error(ex.response.data.message);
      }
      // Critical error
      return alertPage.error("Connection error - please try again later");
    }
  }

  return (
    <>
      <PageAlert alertProps={alertProps} />
      <Container fluid className="main-content-container px-4">
        <PageHeader
          title="Client List"
          subtitle="Agent"
          dateStart={start}
          dateEnd={end}
          handleStartChange={handleStartChange}
          handleEndChange={handleEndChange}
        />
        <Row>
          <Col md="12">
            <DataTableCard
              columns={ClientColumns(
                (item) => {
                  /* Client View */
                  goToViewClient(item.id);
                },
                (item) => {
                  /* Client Edit */
                  goToViewClient(item.id, "edit");
                },
                (item) => {
                  /* Client Delete */
                  handleDelete(item);
                }
              )}
              data={data}
              status={status}
              search={search}
              setSearch={setSearch}
              status={status}
              limit={limit}
              setLimit={setLimit}
              setPage={setPage}
              setSortBy={setSortBy}
              setPreFetchId={() => {}}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              handleSubmit={handleSubmit}
            >
              <NewClientForm
                formData={formData}
                setFormData={setFormData}
                formError={formError}
                setFormError={setFormError}
              />
            </DataTableCard>
          </Col>
        </Row>
      </Container>
      <AreYouSure areYouSureProps={areYouSureProps} />
    </>
  );
};

export default ClientList;
