import React from "react";
import { FormSelect } from "shards-react";
import { clientStatusList } from "../../../data/clientStatus";

const StatusSelect = () => {
  return (
    <React.Fragment>
      <label htmlFor="feStatus">Status</label>
      <FormSelect
        id="feStatus"
        name="status"
        disabled={
          false
          //getUserRole() === "Admin" || getUserRole() === "BO" ? false : true
        }
        //onChange={(e) => onSalesChange(e, "status")}
        //value={statusValue}
      >
        {clientStatusList().map((status) => {
          return (
            <option key={status} value={status}>
              {status}
            </option>
          );
        })}
      </FormSelect>
    </React.Fragment>
  );
};

export default StatusSelect;
