import React from "react";
import { FormInput, FormGroup, FormFeedback, Row, Col } from "shards-react";
import bookingValidation from "./validation/booking.validation";
import BookingItemCard from "./common/BookingItemCard";
import BookingItemModal from "./common/BookingItemModal";
import useBooking from "./common/bookingHook";
import bookingColumns from "./columns/booking.columns";
import rentalsImgIcon from "./Payouts/rentals.png";

const RentalCarCard = ({ bookingId, label, data, handleDelete }) => {
  const {
    handleSubmit,
    isOpen,
    setIsOpen,
    selected,
    setSelected,
    formError,
    isPackage,
    setIsPackage,
  } = useBooking(bookingId, "rentals", bookingValidation.rental);

  return (
    <>
      <BookingItemCard
        imgIcon={rentalsImgIcon}
        label={label}
        data={data}
        columns={bookingColumns.rental}
        setSelected={setSelected}
        handleDelete={handleDelete}
        type="rentals"
      />
      <BookingItemModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        selected={selected}
        setSelected={setSelected}
        isPackage={isPackage}
        setIsPackage={setIsPackage}
        formError={formError}
        handleSubmit={handleSubmit}
      >
        <FormGroup>
          <FormInput
            name="company"
            placeholder="Company"
            value={selected && selected.company}
            onChange={(e) => {
              setSelected((updated) => {
                return {
                  ...updated,
                  company: e.target.value,
                };
              });
            }}
            invalid={formError && formError.path === "company"}
          />
          <FormFeedback>{formError && formError.message}</FormFeedback>
        </FormGroup>
        <label htmlFor="feAutoComIn">Pick up - Drop off</label>
        <Row id="feAutoComIn" form>
          <Col className="form-group">
            <FormInput
              name="pickUp"
              placeholder="Pick Up"
              value={selected && selected.pickUp}
              onChange={(e) => {
                setSelected((updated) => {
                  return {
                    ...updated,
                    pickUp: e.target.value,
                  };
                });
              }}
              invalid={formError && formError.path === "pickUp"}
            />
            <FormFeedback>{formError && formError.message}</FormFeedback>
          </Col>
          <Col className="form-group">
            <FormInput
              name="dropOff"
              placeholder="Drop Off"
              value={selected && selected.dropOff}
              onChange={(e) => {
                setSelected((updated) => {
                  return {
                    ...updated,
                    dropOff: e.target.value,
                  };
                });
              }}
              invalid={formError && formError.path === "dropOff"}
            />
            <FormFeedback>{formError && formError.message}</FormFeedback>
          </Col>
        </Row>
      </BookingItemModal>
    </>
  );
};

export default RentalCarCard;
