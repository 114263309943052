import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Badge,
  Button,
  ButtonGroup,
  FormInput,
  Row,
  Col,
  FormSelect,
  Fade,
  Container,
} from "shards-react";
import moment from "moment";
import capitalizeArray from "../../utils/capitalizeArray";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { setNotificationQueryDefaults } from "../../query/notification.query";
import { useSearchBar } from "../../custom/SiteSearchBar/SiteSearchBar";
import { Link } from "react-router-dom";
import SlideItem from "./SlideItem";

const statusIcon = {
  system: "notifications",
  mail: "email",
  account: "person",
  security: "verified_user",
  analytics: "show_chart",
  upload: "file_upload",
};

const NotificationSlide = ({ title }) => {
  const queryClient = useQueryClient();
  setNotificationQueryDefaults(queryClient);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(3);
  const [sortBy, setSortBy] = useState("createdAt:desc");
  const [status, setStatus] = useState("new");
  const [type, setType] = useState("");
  const { search } = useSearchBar();
  const [results, setResults] = useState([]);

  const { data } = useQuery([
    "notifications",
    { page, limit, sortBy, status, type, search },
  ]);

  const markSeen = useMutation("markSeen");

  function markPageSeen(dataArray) {
    for (const item of dataArray) {
      if (item.status === "new") {
        markSeen.mutate({
          id: item.id,
          key: ["notifications", { page, limit, sortBy, status, type, search }],
        });
      }
    }
  }

  useEffect(() => {
    if (data) {
      setResults(data.results);
    }
  }, [data]);
  //useEffect(() => {
  //  if (data) {
  //    setResults(data.results);
  //    if (status !== "new") {
  //      const timer = setTimeout(() => markPageSeen(data.results), 5 * 1000);
  //
  //      return () => {
  //        clearTimeout(timer);
  //      };
  //    }
  //  }
  //}, [data]);

  useEffect(() => {
    setPage(1);
  }, [type, status]);

  const onItemClick = (index) => {
    if (status === "new") {
      setResults(
        [...results].map((item, i) =>
          i === index ? { ...item, collapse: true } : { ...item }
        )
      );

      setTimeout(() => {
        setResults(
          [...results]
            .map((item, i) => (i === index ? null : { ...item }))
            .filter((i) => i !== null)
        );

        markSeen.mutate({
          id: results[index].id,
          key: ["notifications", { page, limit, sortBy, status, type, search }],
        });
      }, 350);
    } else {
      markSeen.mutate({
        id: results[index].id,
        key: ["notifications", { page, limit, sortBy, status, type, search }],
      });
    }
  };

  return (
    <Card small className="user-activity mb-4">
      <CardHeader className="border-bottom">
        <Col></Col>
        <h6 className="m-0">{title}</h6>
        <div className="block-handle" />
      </CardHeader>
      <CardBody className="pt-0">
        <Row className="border-bottom py-2 bg-light">
          {/* Time Interval */}
          <Col sm="6" className="col d-flex mb-2 mb-sm-0">
            <FormSelect
              size="sm"
              className="w-50"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="">Type</option>
              <option value="mail">Mail</option>
              <option value="account">Account</option>
              <option value="security">Security</option>
              <option value="analytics">Analytics</option>
              <option value="upload">Upload</option>
              <option value="system">System</option>
            </FormSelect>
          </Col>

          {/* DatePicker */}
          <Col sm="6" className="col text-right">
            <FormSelect
              className="w-50 justify-content-right"
              size="sm"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="">All</option>
              <option value="seen">Seen</option>
              <option value="new">New</option>
            </FormSelect>
          </Col>
        </Row>

        {/* User Activity Icon */}
        {results.map((result, i) => (
          <SlideItem
            key={result.id}
            result={result}
            icon={statusIcon[result.type]}
            onClick={() => onItemClick(i)}
            collapse={result.collapse}
          />
        ))}
      </CardBody>
      <CardFooter className="border-top">
        <Row>
          <Col className="d-flex justify-content-center">
            <span style={{ fontSize: "13px" }} className=" text-center">
              Page:{" "}
              <div style={{ display: "inline-block", maxWidth: "33%" }}>
                <FormInput
                  theme="white"
                  min={1}
                  max={data && data.totalPages}
                  size="sm"
                  type="number"
                  value={page}
                  onChange={(e) => {
                    setPage(e.target.value);
                  }}
                ></FormInput>
              </div>{" "}
              of {data && data.totalPages}
            </span>
          </Col>
        </Row>
      </CardFooter>
    </Card>
  );
};

export default NotificationSlide;
