import React from "react";
import { FormInput, FormGroup, FormFeedback } from "shards-react";
import bookingValidation from "./validation/booking.validation";
import BookingItemCard from "./common/BookingItemCard";
import BookingItemModal from "./common/BookingItemModal";
import useBooking from "./common/bookingHook";
import bookingColumns from "./columns/booking.columns";
import cruiseImgIcon from "./Payouts/cruise.png";

const CruiseCard = ({ bookingId, label, data, handleDelete }) => {
  const {
    handleSubmit,
    isOpen,
    setIsOpen,
    selected,
    setSelected,
    formError,
    isPackage,
    setIsPackage,
  } = useBooking(bookingId, "cruise", bookingValidation.cruise);
  return (
    <>
      <BookingItemCard
        imgIcon={cruiseImgIcon}
        label={label}
        data={data}
        columns={bookingColumns.cruise}
        setSelected={setSelected}
        handleDelete={handleDelete}
        type="cruise"
      />
      <BookingItemModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        selected={selected}
        setSelected={setSelected}
        isPackage={isPackage}
        setIsPackage={setIsPackage}
        formError={formError}
        handleSubmit={handleSubmit}
      >
        <FormGroup>
          <FormInput
            name="cruiseLine"
            placeholder="Line"
            value={selected && selected.cruiseLine}
            onChange={(e) => {
              setSelected((updated) => {
                return {
                  ...updated,
                  cruiseLine: e.target.value,
                };
              });
            }}
            invalid={formError && formError.path === "cruiseLine"}
          />
          <FormFeedback>{formError && formError.message}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <FormInput
            name="confirmation"
            placeholder="Confirmation"
            value={selected && selected.confirmation}
            onChange={(e) => {
              setSelected((updated) => {
                return {
                  ...updated,
                  confirmation: e.target.value,
                };
              });
            }}
            invalid={formError && formError.path === "confirmation"}
          />
          <FormFeedback>{formError && formError.message}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <FormInput
            name="deck"
            placeholder="Deck"
            value={selected && selected.deck}
            onChange={(e) => {
              setSelected((updated) => {
                return {
                  ...updated,
                  deck: e.target.value,
                };
              });
            }}
            invalid={formError && formError.path === "deck"}
          />
          <FormFeedback>{formError && formError.message}</FormFeedback>
        </FormGroup>
      </BookingItemModal>
    </>
  );
};

export default CruiseCard;
