import React, { useRef, useEffect } from "react";
import moment from "moment";

const FreeTextDisplay = ({ freeText }) => {
  const bottomElementRef = useRef();

  useEffect(() => {
    if (bottomElementRef.current) {
      bottomElementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [freeText]);

  return (
    <div style={{ overflowY: "scroll", height: 200, padding: 0 }}>
      {freeText &&
        freeText.map((txt) => {
          return (
            <div key={txt._id} className="user-activity__item pr-3 py-3">
              <div className="user-activity__item__icon">
                <i className="material-icons">
                  {txt.user === "Systems Log" ? "settings_suggest" : "chat"}
                </i>
              </div>
              <div className="user-activity__item__content">
                <span className="text-light">
                  {moment(txt.createdAt).format("MM-DD-YYYY - h:mm a -")}
                </span>
                <span>
                  <b> {txt.user}</b>
                </span>
                <p>{txt.freeText}</p>
              </div>
            </div>
          );
        })}

      <div
        style={{ float: "left", clear: "both" }}
        ref={bottomElementRef}
      ></div>
    </div>
  );
};

export default FreeTextDisplay;
