import React from "react";
import filesize from "filesize";

const DocumentFooter = ({ fileName, fileSize }) => {
  return (
    <React.Fragment>
      <span className="file-manager__item-icon">
        <i className="material-icons">&#xE24D;</i>
      </span>
      <h6 className="file-manager__item-title">{fileName}</h6>
      <span className="file-manager__item-size ml-auto">
        {filesize(fileSize)}
      </span>
    </React.Fragment>
  );
};

export default DocumentFooter;
