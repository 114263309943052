import React, { useState } from "react";
import { Row, Col } from "shards-react";
import RangeDatePicker from "../components/sales/RangeDatePicker";
import PageTitle from "../components/common/PageTitle";

const PageHeader = ({
  title,
  subtitle,
  dateStart,
  dateEnd,
  handleStartChange,
  handleEndChange,
}) => {
  return (
    <Row noGutters className="page-header py-4">
      {/* Page Header :: Title */}
      <PageTitle
        title={title}
        subtitle={subtitle}
        className="text-sm-left mb-3"
      />
      {/* Page Header :: Actions */}
      <Col xs="12" sm="4" className="col d-flex align-items-center" />

      {/* Page Header :: Datepicker */}
      <Col sm="4" className="d-flex">
        <RangeDatePicker
          className="justify-content-end"
          start={dateStart}
          end={dateEnd}
          handleStartDateChange={handleStartChange}
          handleEndDateChange={handleEndChange}
        />
      </Col>
      <Col className="text-right">
        <span className="stats-small__label ">
          Click on the dates above to select a date range
        </span>
      </Col>
    </Row>
  );
};
export default PageHeader;

const useDateChangeHandler = () => {
  const d = new Date();
  const ed = new Date();
  const [start, setStart] = useState(new Date(d.setDate(d.getDate() - 365)));
  const [end, setEnd] = useState(new Date(ed.setDate(ed.getDate() + 1)));

  function handleStartChange(value) {
    //setIsLoading(true);
    setStart(new Date(value));
  }

  function handleEndChange(value) {
    //setIsLoading(true);
    setEnd(new Date(value));
  }

  return [start, handleStartChange, end, handleEndChange];
};

export { useDateChangeHandler };
