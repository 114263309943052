import { authService } from "./auth.service";
import { createAuthCaller } from "./http.service";
import queryString from "query-string";
import removeMissing from "../utils/removeMissing";


export function getDirectoryUploads(userId, params) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    const query = queryString.stringify(params);
    return http.get(`/uploads/users/${userId}?${query}`)
}
export function getAllDirectoryUploads(userId, params) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    const query = queryString.stringify(params);
    return http.get(`/uploads/users/${userId}?dir=all&${query}`)
}
export function updateDirectoryUpload(userId, uploadId, params) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.patch(`/uploads/users/${userId}?uploadId=${uploadId}`, params);
}

export function uploadFiles(id, formData, folder) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.post(`/uploads/users/${id}?folder=${folder ? folder : "My Documents"}`, formData);
}

export function createUpload(params) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.post("/uploads", removeMissing(params));
}

export function getAllUploads(params) {
    for (const param in params) {
        if (Object.hasOwnProperty.call(params, param)) {
            //const element = params[param];
            if (!params[param]) delete params[param]
        }
    }
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    const query = queryString.stringify(params);
    return http.get(`/uploads?${query}`)
}

export function getUpload(id) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.get(`/uploads/${id}`);
}

export function updateUpload(id, body) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.put(`/uploads/${id}`, body);
}

export function deleteUpload(id) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.delete(`/uploads/${id}`);
}

const uploadService = {
    getDirectoryUploads,
    getAllDirectoryUploads,
    updateDirectoryUpload,
    uploadFiles,
    createUpload,
    getAllUploads,
    getUpload,
    updateUpload,
    deleteUpload
}
export default uploadService