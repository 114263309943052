import Joi from "joi";
import { phone } from "./custom.validation";

const newClient = Joi.object().keys({
  primary: Joi.object()
    .keys({
      firstName: Joi.string().required(),
      lastName: Joi.string().required(),
    })
    .required(),
  phone: Joi.string().custom(phone).required(),
  email: Joi.string().required(),
});

const exports = {
  newClient,
};

export default exports;
