import Joi from "joi";
import { phone, objectId } from "./custom.validation";

const newAgent = Joi.object().keys({
  id: Joi.string().custom(objectId),
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  email: Joi.string().required(),
  phone: Joi.string().custom(phone),
  password: Joi.string(),
  role: Joi.string().allow("user", "admin"),
});

const exports = {
  newAgent,
};

export default exports;
