import React from "react";
import { Container, Row, Col, CardHeader, Button } from "shards-react";
import GlobalSearchBar from "../../CommonPageComponents/GlobalSearchBar";

const TableHeader = ({ search, setSearch, setIsOpen }) => {
  return (
    <CardHeader className="text-center p-0">
      <Container fluid className="file-manager__filters border-bottom">
        <Row>
          <Col className="d-flex justify-content-start">
            <Button onClick={() => setIsOpen(true)} theme="accent">
              New
            </Button>
          </Col>

          <GlobalSearchBar search={search} setSearch={setSearch} />
        </Row>
      </Container>
    </CardHeader>
  );
};

export default TableHeader;
