import Joi from "joi";

const booking = Joi.object().keys({
    client: Joi.string(),
    agent: Joi.string(),
    name: Joi.string().required(),
    travelDate: Joi.date().required(),
})

const exports = {
    booking
}

export default exports;