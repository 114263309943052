import React from "react";

import { Col, FormSelect } from "shards-react";

const TableDataSelect = ({ table, setTable, tableOptions }) => {
  return (
    <Col className="file-manager__filters__rows d-flex">
      <span>Show</span>
      <FormSelect
        size="sm"
        onChange={(e) => setTable(e.target.value)}
        value={table}
      >
        {tableOptions.map((item, idx) => (
          <option key={idx} value={item}>
            {item} table
          </option>
        ))}
      </FormSelect>
    </Col>
  );
};

export default TableDataSelect;
