import React from "react";
import { thumbnailPlugin } from "@react-pdf-viewer/thumbnail";
import { Viewer } from "@react-pdf-viewer/core";

const ThumbNailViewer = ({ fileType, url, fileName }) => {
  const thumbnailPluginInstance = thumbnailPlugin();

  return (
    <>
      {fileType !== "application/pdf" && <img src={url} alt={fileName} />}

      {fileType === "application/pdf" && (
        <Viewer fileUrl={url} plugins={[thumbnailPluginInstance]} />
      )}
    </>
  );
};

export default ThumbNailViewer;
