import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Badge,
  Button,
  ButtonGroup,
  FormInput,
  Row,
  Col,
  FormSelect,
  Fade,
} from "shards-react";
import moment from "moment";
import capitalizeArray from "../../utils/capitalizeArray";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { setNotificationQueryDefaults } from "../../query/notification.query";
import { useSearchBar } from "../../custom/SiteSearchBar/SiteSearchBar";
import { Link } from "react-router-dom";

const statusIcon = {
  system: "notifications",
  mail: "email",
  account: "person",
  security: "verified_user",
  analytics: "show_chart",
  upload: "file_upload",
};

//className="user-activity__item pr-3 py-3"

const SlideItem = ({ collapse, onClick, icon, result }) => {
  return (
    <div
      className={[
        "user-notifications__item",
        collapse ? "done" : "",
        "pr-3 py-3",
      ].join(" ")}
      onClick={onClick}
    >
      <div
        className={[
          `user-notifications__item__icon`,
          result.status === "new" ? "notification-new" : "notification-seen",
        ].join(" ")}
      >
        <i
          className={[
            `material-icons`,
            result.status === "new" ? "notification-new" : "notification-seen",
          ].join(" ")}
        >
          {icon}
        </i>
      </div>
      <div className="user-notifications__item__content">
        <span className="text-light">{moment(result.createdAt).fromNow()}</span>
        <p>
          <Link to={result.path}>{capitalizeArray(result.type)}</Link> -{" "}
          {result.message}.{" "}
        </p>
      </div>
    </div>
  );
};

export default SlideItem;
