import React from "react";
import { Row, Col, Form, FormInput } from "shards-react";
import ModalFormInput from "../../common/DataTableCard/components/ModalFormInput";

const NewVendorForm = ({ formData, setFormData, formError, setFormError }) => {
  return (
    <Form>
      <Row form>
        <Col md="12" className="form-group">
          <label htmlFor="feVendorName">Name</label>
          <ModalFormInput
            name="name"
            placeholder="Vendor Name"
            value={formData && formData.name}
            onChange={(e) => {
              setFormError();
              setFormData((formData) => {
                return {
                  ...formData,
                  name: e.target.value,
                };
              });
            }}
            formError={formError}
          />
        </Col>
        <Col md="12">
          <label htmlFor="feVendorPhone">Phone</label>
          <ModalFormInput
            name="phone"
            placeholder="Phone Number"
            value={formData && formData.phone}
            onChange={(e) => {
              setFormError();
              setFormData((formData) => {
                return {
                  ...formData,
                  phone: e.target.value,
                };
              });
            }}
            formError={formError}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default NewVendorForm;
