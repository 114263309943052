import React from "react";
import { Row, Col } from "shards-react";
import ClientInfo from "./Profile/ClientInfo";
import FreeText from "./Profile/FreeText";
import { useLocation } from "react-router-dom";

function useLocationState() {
  const { state } = useLocation();
  const [newAlert, setNewAlert] = React.useState();

  React.useEffect(() => {
    if (state && state.pageAlert) {
      setNewAlert(state.pageAlert);
    }
  }, [state]);

  return newAlert;
}

const ProfileClient = ({ client, pageAlert }) => {
  const isAlert = useLocationState(pageAlert);

  React.useEffect(() => {
    if (isAlert) pageAlert(isAlert);
  }, [isAlert]);

  return (
    <>
      <Row className="mb-4">
        <Col md="5">
          <ClientInfo client={client} />
        </Col>
        <Col md="6">
          <FreeText
            id={client && client.id}
            freeText={client && client.freeText}
          />
        </Col>
      </Row>
    </>
  );
};

export default ProfileClient;
