import React, { useState, useEffect } from "react";
import { Row, Col } from "shards-react";
import UserMenu from "./Users/UserMenu";
import { useActivity } from "../../query.hooks/activity/activity";
import {
  useAddClientUser,
  useRemoveClientUser,
} from "../../query.hooks/clients/clients";
import { useFormError } from "../../query.hooks/formError/useFormError";
import { useLocation, useHistory } from "react-router-dom";
import UserActivity from "./Users/UserActivity";
const queryString = require("query-string");
import FormCard from "../../common/FormCard/FormCard";
import { useFormData } from "../../common/FormCard/useFormData";
import AddUserForm from "./Users/AddUserForm";
import authService from "../../services/auth.service";
import { getUser, updateUser } from "../../services/user.service";
import { useQueryClient } from "react-query";

function useLocationState() {
  const { state } = useLocation();
  const [newAlert, setNewAlert] = React.useState();

  React.useEffect(() => {
    if (state && state.pageAlert) {
      setNewAlert(state.pageAlert);
    }
  }, [state]);

  return newAlert;
}

const UsersClient = ({ client, alertPage }) => {
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const [page, setPage] = useState(1);
  const { data } = useActivity(parsed.user, { page });
  const [errors, setErrors] = useState({});
  const setFormErrors = useFormError(setErrors);
  const queryClient = useQueryClient();
  const [phoneValue, setPhoneValue] = useState();
  const { formRef, getFormValues, clearFormValues, fillFormValues } =
    useFormData();

  const newAlert = useLocationState();

  useEffect(() => {
    if (newAlert) {
      alertPage.success(newAlert);
    }
  }, [newAlert]);

  const addMutation = useAddClientUser(clearFormValues, ["clients", client.id]);

  async function registerUserData(user) {
    try {
      const { data } = await authService.register(user);
      return data.user;
    } catch (ex) {
      setFormErrors(ex);
    }
  }

  async function handleAddSubmit() {
    const formValues = getFormValues();
    formValues.phone = phoneValue;
    const user = await registerUserData(formValues);

    if (user) {
      setErrors({});
      addMutation.mutate({ clientId: client.id, user });
      setPhoneValue("");
      alertPage.success(`New User ${user.email} Added`);
    }
  }

  const { user } = parsed;
  useEffect(() => {
    if (user) {
      getUser(user).then((res) => {
        fillFormValues(res.data);
        setPhoneValue(res.data.phone);
      });
    } else {
      setPhoneValue("");
    }
  }, [user]);

  async function updateUserData(user) {
    try {
      const { data } = await updateUser(parsed.user, user);
      return data;
    } catch (ex) {
      setFormErrors(ex);
    }
  }

  async function handleEditSubmit() {
    const formValues = getFormValues();

    formValues.phone = phoneValue;

    const formData = {};

    for (const key in formValues) {
      if (Object.hasOwnProperty.call(formValues, key)) {
        const element = formValues[key];

        if (element) {
          formData[key] = formValues[key];
        }
      }
    }

    const user = await updateUserData(formData);

    if (user) {
      setErrors({});
      clearFormValues();
      setPhoneValue("");
      queryClient.invalidateQueries(["activity", parsed.user]);
      queryClient.invalidateQueries(["clients", client.id]);
      alertPage.success("User Edit Saved");
    }
  }

  const removeMutation = useRemoveClientUser(["clients", client.id]);

  async function handleDeleteUser(user) {
    console.log("del", user);
    removeMutation.mutate({ clientId: client.id, userId: user });
    history.push(`/clients/${client.id}/users`, { pageAlert: "User Deleted" });
  }

  useEffect(() => {
    if (errors.message) alertPage.error(errors.message);
  }, [errors]);

  return (
    <>
      <Row className="mb-4">
        <Col md="3">
          <UserMenu
            id={client.id}
            users={client.users}
            handleDeleteUser={handleDeleteUser}
          />
        </Col>
        <Col md="5">
          {parsed && !parsed.user && (
            <FormCard title={"Add User"} handleSubmit={handleAddSubmit}>
              <AddUserForm
                formRef={formRef}
                errors={errors}
                phoneValue={phoneValue}
                setPhoneValue={setPhoneValue}
              />
            </FormCard>
          )}
          {parsed && parsed.user && (
            <FormCard title={"Edit User"} handleSubmit={handleEditSubmit}>
              <AddUserForm
                formRef={formRef}
                errors={errors}
                phoneValue={phoneValue}
                setPhoneValue={setPhoneValue}
              />
            </FormCard>
          )}
        </Col>
        <Col md="4">
          {parsed && parsed.user && (
            <UserActivity data={data} page={page} setPage={setPage} />
          )}
        </Col>
      </Row>
    </>
  );
};

export default UsersClient;
