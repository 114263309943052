import React from "react";
import classNames from "classnames";
import {
  InputGroup,
  DatePicker,
  InputGroupAddon,
  InputGroupText
} from "shards-react";

const RangeDatePicker = props => {
  const { className } = props;
  const classes = classNames(className, "d-flex", "my-auto", "date-range");

  return (
    <InputGroup className={classes}>
      <DatePicker
        size="sm"
        selected={props.start}
        onChange={props.handleStartDateChange}
        placeholderText="Starting Date"
        dropdownMode="select"
        className="text-center start-date"
        maxDate={props.end}
        name="start"
      />
      <DatePicker
        size="sm"
        selected={props.end}
        onChange={props.handleEndDateChange}
        placeholderText="Ending Date"
        dropdownMode="select"
        className="text-center end-date"
        minDate={props.start}
        name="end"
      />
      <InputGroupAddon type="append">
        <InputGroupText>
          <i className="material-icons">&#xE916;</i>
        </InputGroupText>
      </InputGroupAddon>
    </InputGroup>
  );
};

export default RangeDatePicker;
