import { authService } from "./auth.service";
import { createAuthCaller } from "./http.service";
import queryString from "query-string";


export function createBooking(params) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.post("/bookings", params);
}

export function getAllBookings(params) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    const query = queryString.stringify(params);
    return http.get(`/bookings?${query}`)
}

export function getBooking(id) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.get(`/bookings/${id}`);
}

export function updateBooking(id, booking) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.patch(`/bookings/${id}`, booking);
}

export function deleteBooking(id) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.delete(`/bookings/${id}`);
}

export function createBookingItem(id, bookingType, params) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.post(`/bookings/${id}/${bookingType}`, params)
}

export function updateBookingItem(id, bookingType, bookingItemId, params) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.patch(`/bookings/${id}/${bookingType}/${bookingItemId}`, params)
}

export function deleteBookingItem(id, bookingType, bookingItemId) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.delete(`/bookings/${id}/${bookingType}/${bookingItemId}`)
}

export function addFreeText(id, params) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.post(`/bookings/${id}`, params);
}

export function getOptions(params) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    const query = queryString.stringify(params);
    return http.get(`/bookings/options?${query}`);
}

export function getAllPayments(bookingId) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.get(`/payments/${bookingId}`)
}
export function createPayment(bookingId, params) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.post(`/payments/${bookingId}`, params)
}
export function updatePayment(bookingId, paymentId) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.patch(`/payments/${bookingId}?paymentId=${paymentId}`)
}
export function deletePayment(bookingId, paymentId) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.delete(`/payments/${bookingId}?paymentId=${paymentId}`)
}

export function getChecklist(bookingId) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.get(`/checklist/${bookingId}`)
}
export function toggleDoc(bookingId, doc) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.patch(`/checklist/${bookingId}?doc=${doc}`)
}

export function getBookingReminders(bookingId) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.get(`/reminders/${bookingId}`)
}

export function toggleReminder(bookingId, reminder) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.patch(`/reminders/${bookingId}?reminder=${reminder}`)
}

export function getAllReminders() {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.get(`/reminders`)
}




const bookingService = {
    createBooking,
    getAllBookings,
    getBooking,
    updateBooking,
    deleteBooking,
    createBookingItem,
    updateBookingItem,
    deleteBookingItem,
    addFreeText,
    getOptions,
    getAllPayments,
    createPayment,
    deletePayment,
    updatePayment,
    getChecklist,
    toggleDoc,
    getBookingReminders,
    toggleReminder,
    getAllReminders
}

export default bookingService;