import Joi from "joi";
import { phone, objectId } from "./custom.validation";

const newVendor = Joi.object().keys({
  id: Joi.string().custom(objectId),
  name: Joi.string().required(),
  phone: Joi.string().custom(phone),
});

const exports = {
  newVendor,
};

export default exports;
