/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Badge,
  Button,
  Row,
  Col,
} from "shards-react";
import UserActivityItem from "./UserActivity/UserActivityItem";

const UserActivity = ({ data, page, setPage }) => {
  return (
    <Card small className="user-activity mb-4">
      <CardHeader className="border-bottom">
        <h6 className="m-0">
          User Activity{" "}
          <span>
            ({data && (data.page - 1) * 5 + 1} -{" "}
            {data && (data.page - 1) * 5 + data.results.length}) of{" "}
            {data && data.totalResults}
          </span>
        </h6>
        <div className="block-handle" />
      </CardHeader>
      <CardBody className="p-0">
        {/* User Activity Icon */}
        {data &&
          data.results.map((result) => {
            return <UserActivityItem activity={result} />;
          })}
      </CardBody>
      <CardFooter className="border-top">
        <Row>
          <Col>
            <Button
              size="sm"
              theme="white"
              className="d-table mx-auto"
              disabled={data && page < 2}
              onClick={() => {
                setPage((page) => page - 1);
              }}
            >
              Prev
            </Button>
          </Col>
          <Col>
            <Button
              size="sm"
              theme="white"
              className="d-table mx-auto"
              disabled={data && page > data.totalPages - 1}
              onClick={() => {
                setPage((page) => page + 1);
              }}
            >
              Next
            </Button>
          </Col>
        </Row>
      </CardFooter>
    </Card>
  );
};

UserActivity.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
};

UserActivity.defaultProps = {
  title: "User Activity",
};

export default UserActivity;
