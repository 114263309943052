import React from "react";
import { useDropzone } from "react-dropzone";
import { Row } from "shards-react";

const DropzonePreview = ({ setFiles }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*, application/pdf",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file, index) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            selected: false,
            id: index,
          })
        )
      );
    },
  });

  return (
    <Row noGutters className="border-bottom">
      <div className="file-manager-cards__dropzone w-100 p-2">
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <p>
            {isDragActive
              ? "Drop files"
              : "Drag files here to upload, or click to select files"}
          </p>

          <em>(Only image and PDF files will be accepted)</em>
        </div>
      </div>
    </Row>
  );
};

export default DropzonePreview;
