import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DataTable from "./DataTable";
import ClientColumns from "./ClientColumns";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useClientPreFetch } from "../../component_modules/Client/preFetch";
import capitalizeArray from "../../utils/capitalizeArray";

function handleViewClient() {
  let history = useHistory();

  let setPreFetchId = useClientPreFetch();
  function handleClick(id, page) {
    setPreFetchId(id);

    if (!page) history.push("/clients/" + id);
    if (page === "edit") history.push("/clients/" + id + "/edit");
    if (page === "users") history.push("/clients/" + id + "/users");
  }

  return handleClick;
}

const ClientDataTable = ({
  table,
  limit,
  search,
  start,
  end,
  setLimit,
  pageSizeOptions,
  alertPage,
  setDeleteHandler,
}) => {
  const queryClient = useQueryClient();
  const goToViewClient = handleViewClient();
  // State
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState("primary.lastName:asc");

  /**
   * Clients Query
   */
  const { status, data } = useQuery([
    "clients",
    { page, limit, sortBy, search, start, end },
  ]);

  useEffect(() => {
    if (data) {
      queryClient.prefetchQuery([
        "clients",
        { page: page + 1, limit, sortBy, search, start, end },
      ]);
    }
  }, [data]);

  /**
   * Prefetch by ID
   */
  const setPreFetchId = useClientPreFetch();

  /**
   * Delete Functions
   */
  const clientDeleter = useMutation("deleteClient");

  function handleDelete(item) {
    setDeleteHandler({
      deleteFn: () => {
        /* Client Delete */
        clientDeleter.mutate(
          {
            id: item.id,
            key: ["clients", { page, limit, sortBy, search, start, end }],
          },
          {
            onError: alertPage.error(
              `Unable to delete client: ${capitalizeArray(
                item.primary.lastName
              )}, ${capitalizeArray(item.primary.firstName)}`
            ),
            onSuccess: alertPage.success(
              `Client: ${capitalizeArray(
                item.primary.lastName
              )}, ${capitalizeArray(item.primary.firstName)} DELETED`
            ),
          }
        );
      },
      item: `Client ${capitalizeArray(
        item.primary.lastName
      )}, ${capitalizeArray(item.primary.firstName)}`,
    });
  }

  /* Move back a page when no results in page data */
  useEffect(() => {
    if (data) {
      if (!data.results[0] && page > data.totalPages && page !== 1) {
        setPage((page) => page - 1);
      }
    }
  }, [data]);

  return (
    <div className="">
      {table === "Clients" && (
        <DataTable
          columns={ClientColumns(
            (id) => {
              /* Client View */
              goToViewClient(id);
            },
            (id) => {
              /* Client Edit */
              goToViewClient(id, "edit");
            },
            (item) => {
              /* Client Delete */
              handleDelete(item);
            }
          )}
          data={(data && data.results) || []}
          pages={data && data.totalPages}
          loading={status === "loading"}
          pageSize={limit}
          setPage={setPage}
          setLimit={setLimit}
          setSortBy={setSortBy}
          prefetchItem={setPreFetchId}
        />
      )}
    </div>
  );
};

export default ClientDataTable;
