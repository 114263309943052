import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Container, Row, Nav, NavItem, NavLink } from "shards-react";
import { Link } from "react-router-dom";
import moment from "moment";
import logo from "../../static/scs.png";


const MainFooter = ({ contained, menuItems, copyright }) => {

  return (
    <footer className="main-footer d-flex p-2 px-3 bg-white border-top">
      <Container fluid={contained}>
        <Row>
          <Nav>
            {menuItems.map((item, idx) => (
              <NavItem key={idx}>
                <NavLink tag={Link} to={item.to}>
                  {item.title}
                </NavLink>
              </NavItem>
            ))}

          </Nav>
          <span className="copyright ml-auto my-auto mr-1">{copyright} </span><span className="copyright my-auto mr-2"><img id="main-logo"
            className="auth-form__logo d-inline-block align-center mb-1"
            style={{ maxWidth: "15px" }}
            src={logo}></img>Signature Consulting</span>
        </Row>
      </Container>
    </footer>
  );
}
MainFooter.propTypes = {
  /**
   * Whether the content is contained, or not.
   */
  contained: PropTypes.bool,
  /**
   * The menu items array.
   */
  menuItems: PropTypes.array,
  /**
   * The copyright info.
   */
  copyright: PropTypes.string
};

MainFooter.defaultProps = {
  contained: false,
  copyright: `Copyright © ${moment().year()}`,
  menuItems: [
    {
      title: "User",
      to: "/user/profile"
    },
  ]
};

export default MainFooter;
