import React, { useEffect, useState } from "react";
import {
  QueryClient,
  QueryClientProvider,
} from "react-query";
import { BrowserRouter as Router, Route } from "react-router-dom";
import useSidebarItems from "./common/sidebarMenu/useSidebarItems";
import routes from "./routes";
import Login from "./views/Login";
import ProtectedRoute from "./custom/ProtectedRoute";
import { authService } from "./services/auth.service";
import { ReactQueryDevtools } from 'react-query/devtools'
import { Worker } from "@react-pdf-viewer/core";
import { MenuContext } from "./context/MenuContext";
import { SearchBarContext } from "./custom/SiteSearchBar/SearchBarContext";
import { createSocket, SocketContext } from "./component_modules/react-user-socket/react-user-socket";

const queryClient = new QueryClient();

function useUserAuth() {
  const [user, setUser] = useState();

  useEffect(() => {
    const subscription = authService.user.subscribe(x => setUser(x));
    return subscription.unsubscribe;
  }, []);
  return user
}

function App({ props }) {
  const user = useUserAuth();


  // Menus
  const [menuVisible, setMenuVisible] = useState(false);
  const [search, setSearch] = useState();
  const [sidebarNavItems, toggleSidebarDropdown] = useSidebarItems("admin");


  // Create Socket
  const [authSocket, setAuthSocket] = useState();
  useEffect(() => {
    if (user) {
      const { token } = user;
      const socket = createSocket(authSocket, token);
      setAuthSocket(socket);
    }
  }, [user])

  // Notification listner
  const [newNot, setNewNot] = useState(0);
  const [notList, setNotList] = useState([]);
  const listener = (...args) => {
    setNewNot(args[0]);
    setNotList(args[1]);
  };

  useEffect(() => {
    if (authSocket) {
      authSocket.on("NEW_NOTIFICATION", listener);
      return () => {
        authSocket.off("NEW_NOTIFICATION", listener);
      };
    }
  }, [authSocket])



  return (
    <div className={"app-m" + (user && "bfd")}>
      <MenuContext.Provider value={{ sidebarNavItems, toggleSidebarDropdown, menuVisible, setMenuVisible, newNot, notList }}>
        <Route
          path="/login"
          component={(props) => {
            return <Login {...props} />;
          }}
          exact={true}
        />
        <QueryClientProvider client={queryClient}>
          <SocketContext.Provider value={authSocket}>
            <SearchBarContext.Provider value={{ search, setSearch }}>
              {routes.map((route, index) => {
                return <ProtectedRoute key={index} route={route} index={index} {...props} />;
              })}
              <ReactQueryDevtools initialIsOpen={false} />
            </SearchBarContext.Provider>
          </SocketContext.Provider>
        </QueryClientProvider>
      </MenuContext.Provider>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js"></Worker>
    </div>
  );
}

export default App;
