import React from "react";
import { Container, Row, Col } from "shards-react";
import PageHeader, { useDateChangeHandler } from "../common/PageHeader";
import ProfileHeader from "../common/ProfileHeader";
import { useLocation } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { useClient } from "../query.hooks/clients/clients";
import EditClient from "../components/Clients/EditClient";
import ProfileClient from "../components/Clients/ProfileClient";
import UsersClient from "../components/Clients/UsersClient";
import PageAlert from "../common/Alert/PageAlert";
import { useAlert } from "../common/Alert/useAlert";
import { setClientQueryDefaults } from "../query/client.query";
import BookingsClient from "../components/Clients/BookingsClient";

const Client = ({ match }) => {
  const queryClient = useQueryClient();
  setClientQueryDefaults(queryClient);

  const { data, status } = useClient(match.params.client);
  const { pageAlert, alertPage, alertProps } = useAlert();

  return (
    <>
      <PageAlert alertProps={alertProps} />
      <Container fluid className="main-content-container px-4">
        {status === "success" && (
          <ProfileHeader
            title={data && data.primary.lastName}
            subtitle={"Private Client"}
            id={data && data.id}
            page={match.params.page}
          />
        )}

        <div>
          {match.params.page === "edit" && (
            <EditClient
              client={data}
              pageAlert={pageAlert}
              alertPage={alertPage}
            />
          )}
        </div>
        <div>
          {match.params.page === "bookings" && (
            <BookingsClient
              client={data}
              pageAlert={pageAlert}
              alertPage={alertPage}
            />
          )}
        </div>
        <div>
          {!match.params.page && (
            <ProfileClient
              client={data}
              pageAlert={pageAlert}
              alertPage={alertPage}
            />
          )}
        </div>
      </Container>
    </>
  );
};

export default Client;
