import React from "react";
import { Nav } from "shards-react";
import UserActions from "./UserActions";
//import Notifications from "../../../../component_modules/Notifications/Notifications";
import NotificationMenu from "../../../../component_modules/Notifications/NotificationMenu";


export default () => {
  return (
    <Nav navbar className="border-left flex-row">

      <NotificationMenu />
      <UserActions />
    </Nav>
  )
}