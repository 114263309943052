import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormSelect,
} from "shards-react";
import toursImg from "./tours.png";
import hotelsImg from "./hotels.png";
import rentalsImg from "./rentals.png";
import cruiseImg from "./cruise.png";
import flightImg from "./flights.png";
import { capitalize } from "lodash";

const typeImg = {
  flight: flightImg,
  cruise: cruiseImg,
  hotel: hotelsImg,
  rental: rentalsImg,
  tour: toursImg,
};

const PayoutItemList = ({ title, vendorItems }) => {
  const totalPrice = vendorItems.reduce((acc, item) => {
    return acc + item.price;
  }, 0);

  return (
    <Card small className="payout-stats country-stats">
      <CardHeader className="border-bottom">
        <h6 className="m-0">{title} - Items</h6>
        <div className="block-handle" />
      </CardHeader>
      <CardBody className="p-0">
        {/* Vendor Item Table List */}
        <table className="table mb-0">
          <thead className="py-2 bg-light text-semibold border-bottom">
            <tr>
              <th>Type</th>
              <th className="text-center">Company</th>
              <th className="text-right">Price</th>
            </tr>
          </thead>
          <tbody>
            {vendorItems.map((item, idx) => (
              <tr key={idx}>
                <td>
                  <img
                    className="country-flag mr-1"
                    src={typeImg[item.type]}
                    alt={item.type}
                  />
                  {capitalize(item.type)}
                </td>
                <td className="text-center">{item.company}</td>

                <td className="text-right">${item.price}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </CardBody>

      <CardFooter className="border-top">
        <Row>
          <Col>
            <span></span>
          </Col>

          {/* View Full Report */}
          <Col className="text-right view-report">
            {/* eslint-disable-next-line */}
            <span>${totalPrice || 0}</span>
          </Col>
        </Row>
      </CardFooter>
    </Card>
  );
};

export default PayoutItemList;
