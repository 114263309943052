import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import {
  Row,
  Col,
  FormInput,
  Form,
  Modal,
  ModalBody,
  Button,
} from "shards-react";
import PaymentTable from "./PaymentTable";
import PaymentInfo from "./PaymentInfo";
import { setBookingsQueryDefaults } from "../../../query/booking.query";
import bookingService from "../../../services/booking.service";

const Payment = ({ bookingId }) => {
  const queryClient = useQueryClient();
  setBookingsQueryDefaults(queryClient);
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState();
  const { data } = useQuery(["payments", bookingId]);

  async function handleSubmit() {
    try {
      await bookingService.createPayment(bookingId, formData);
      setIsOpen(false);
      queryClient.invalidateQueries(["payments", bookingId]);
    } catch (ex) {
      console.log(ex);
    }
  }

  async function handleDelete(item) {
    try {
      await bookingService.deletePayment(bookingId, item._id);
      setIsOpen(false);
      queryClient.invalidateQueries(["payments", bookingId]);
    } catch (ex) {
      console.log(ex);
    }
  }

  async function handleUpdate(item) {
    try {
      await bookingService.updatePayment(bookingId, item._id);
      setIsOpen(false);
      queryClient.invalidateQueries(["payments", bookingId]);
    } catch (ex) {
      console.log(ex);
    }
  }

  return (
    <>
      <Row>
        <Col lg="6" className="mb-4">
          <PaymentTable
            title={`Total Payments: $${data && data.totalPayments}`}
            data={data && data.results}
            handleAddNew={() => {
              setIsOpen(true);
            }}
            handleDelete={handleDelete}
            handleUpdate={handleUpdate}
          />
        </Col>
        <Col lg="4" className="mb-4">
          <PaymentInfo title="title" data={data} />
        </Col>
      </Row>
      <Modal open={isOpen} toggle={() => setIsOpen((isOpen) => !isOpen)}>
        <ModalBody>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Row form>
              <Col className="form-group">
                <FormInput
                  type="number"
                  value={formData && formData.update}
                  onChange={(e) => {
                    setFormData((update) => {
                      return {
                        ...update,
                        payment: e.target.value,
                      };
                    });
                  }}
                  placeholder="Payment"
                ></FormInput>
              </Col>

              <Col className="form-group">
                <FormInput
                  type="date"
                  value={formData && formData.update}
                  onChange={(e) => {
                    setFormData((update) => {
                      return {
                        ...update,
                        due: e.target.value,
                      };
                    });
                  }}
                ></FormInput>
              </Col>
              <Col className="form-group text-right">
                <Button theme="accent" small onClick={handleSubmit}>
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Payment;
