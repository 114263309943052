import React from "react";
import { NavLink, Badge } from "shards-react";

const NotificationIcon = React.memo(({ setIsOpen, newNot }) => {
  return (
    <NavLink
      className="nav-link-icon text-center"
      onClick={() => setIsOpen((isOpen) => !isOpen)}
    >
      <div className="nav-link-icon__wrapper">
        <i className="material-icons">&#xE7F4;</i>
        <Badge pill theme="danger">
          {newNot ? newNot : ""}
        </Badge>
      </div>
    </NavLink>
  );
});

export default NotificationIcon;
