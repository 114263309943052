import React, { useState } from "react";
import { Row, Col, ButtonGroup, Button } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { NavLink } from "react-router-dom";
import capitalize from "../utils/capitalize";

const pageDescription = (page) => {
  if (page === "edit") return "Edit Booking Profile";
  if (page === "reminders") return "Booking Reminders";
  if (page === "checklist") return "Booking Check List";
  if (page === "payment") return "Booking Payments";
  if (page === "payouts") return "Vendor Payout Tracking";

  return "Booking Profile";
};

const BookingHeader = ({ title, subtitle, id, clientId, page }) => {
  return (
    <Row noGutters className="page-header py-4 rowFlex">
      {/* Page Header :: Title */}
      <PageTitle
        title={title && capitalize(title)}
        subtitle={subtitle}
        className="text-sm-left mb-3"
      />
      {/* Page Header :: Actions */}
      <Col xs="12" sm="4" className="col d-flex align-items-center"></Col>

      {/* Page Header :: Datepicker */}
      <Col sm="4" className="d-flex align-items-center">
        <ButtonGroup size="sm" className="d-inline-flex mb-3 mb-sm-0 mx-auto">
          <Button
            theme="white"
            tag={NavLink}
            exact
            to={"/clients/" + clientId + "/bookings/" + id}
          >
            View
          </Button>

          <Button
            theme="white"
            tag={NavLink}
            to={"/clients/" + clientId + "/bookings/" + id + "/reminders"}
          >
            Reminders
          </Button>
          <Button
            theme="white"
            tag={NavLink}
            to={"/clients/" + clientId + "/bookings/" + id + "/checklist"}
          >
            Check
          </Button>
          <Button
            theme="white"
            tag={NavLink}
            to={"/clients/" + clientId + "/bookings/" + id + "/payment"}
          >
            Payment
          </Button>
          <Button
            theme="white"
            tag={NavLink}
            to={"/clients/" + clientId + "/bookings/" + id + "/payouts"}
          >
            Payouts
          </Button>
        </ButtonGroup>
      </Col>
      <Col className="text-center">
        <span className="stats-small__label ">{pageDescription(page)}</span>
      </Col>
    </Row>
  );
};

export default BookingHeader;
