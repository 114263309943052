import { authService } from "./auth.service";
import { createAuthCaller } from "./http.service";
import queryString from "query-string";


export function createClient(params) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.post("/clients", params);
}

export function getAllClients(params) {
    //console.log(params)
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    const query = queryString.stringify(params);
    return http.get(`/clients?${query}`)
}

export function getClient(id) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.get(`/clients/${id}`);
}

export function updateClient(id, client) {
    console.log("sev", id, client)
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.patch(`/clients/${id}`, client);
}

export function deleteClient(id) {
    console.log("ser", id);
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.delete(`/clients/${id}`);
}

export function addFreeText(id, params) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.post(`/clients/${id}`, params);
}

export function addClientUser(clientId, userId) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.put(`/clients/${clientId}?option=add&userId=${userId}`);
}

export function removeClientUser(clientId, userId) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.put(`/clients/${clientId}?option=remove&userId=${userId}`);
}

const clientService = {
    createClient,
    getAllClients,
    getClient,
    updateClient,
    deleteClient,
    addFreeText,
    addClientUser,
    removeClientUser
}

export default clientService;