import moment from "moment";
import React from "react";
import { useQuery, useQueryClient } from "react-query";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Container,
  FormCheckbox,
} from "shards-react";
import bookingService from "../../../services/booking.service";

const CheckList = ({ bookingId }) => {
  const queryClient = useQueryClient();
  const { data } = useQuery(["checklist", bookingId]);

  async function toggleDoc(doc) {
    console.log(doc);
    try {
      await bookingService.toggleDoc(bookingId, doc);
      queryClient.invalidateQueries(["checklist", bookingId]);
    } catch (ex) {
      console.log(ex);
    }
  }
  return (
    <Row className="mb-4">
      <Col md="5">
        <Card small className="payout-stats">
          <CardHeader className="border-bottom">
            <Row className="d-flex">
              <Col className="fmr-auto p-2">
                <h6 className="m-0">Pre Docs</h6>
              </Col>
              <Col className="p-2 text-right"></Col>
            </Row>
          </CardHeader>
          <CardBody className="p-0">
            <Container fluid className="px-0">
              <table className="table mb-0">
                <thead className="py-2 bg-light text-semibold border-bottom">
                  <tr>
                    <th>Complete</th>
                    <th className="text-center">Doc</th>
                    <th className="text-right"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <FormCheckbox
                        onChange={() => toggleDoc("registration")}
                        checked={
                          data && data.docs && data.docs.registration.checked
                        }
                      />
                    </td>
                    <td className="text-center">Registration</td>
                    <td className="text-right">
                      {data && data.docs && data.docs.registration.checked
                        ? moment
                            .utc(data.docs.registration.checkedDate)
                            .format("MM-DD-YYYY")
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FormCheckbox
                        onChange={() => toggleDoc("auth_ucc")}
                        checked={
                          data && data.docs && data.docs.auth_ucc.checked
                        }
                      />
                    </td>
                    <td className="text-center">Auth / Ucc</td>
                    <td className="text-right">
                      {data && data.docs && data.docs.auth_ucc.checked
                        ? moment
                            .utc(data.docs.auth_ucc.checkedDate)
                            .format("MM-DD-YYYY")
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FormCheckbox
                        onChange={() => toggleDoc("proofOfCitz_infoCkInitial")}
                        checked={
                          data &&
                          data.docs &&
                          data.docs.proofOfCitz_infoCkInitial.checked
                        }
                      />
                    </td>
                    <td className="text-center">
                      Proof Of Citz / Info Ck Initial
                    </td>
                    <td className="text-right">
                      {data &&
                      data.docs &&
                      data.docs.proofOfCitz_infoCkInitial.checked
                        ? moment
                            .utc(
                              data.docs.proofOfCitz_infoCkInitial.checkedDate
                            )
                            .format("MM-DD-YYYY")
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FormCheckbox
                        onChange={() => toggleDoc("brochure_ins")}
                        checked={
                          data && data.docs && data.docs.brochure_ins.checked
                        }
                      />
                    </td>
                    <td className="text-center">Brochure / Ins</td>
                    <td className="text-right">
                      {data && data.docs && data.docs.brochure_ins.checked
                        ? moment
                            .utc(data.docs.brochure_ins.checkedDate)
                            .format("MM-DD-YYYY")
                        : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Container>
          </CardBody>
        </Card>
      </Col>
      <Col md="5">
        <Card small className="payout-stats">
          <CardHeader className="border-bottom">
            <Row className="d-flex">
              <Col className="fmr-auto p-2">
                <h6 className="m-0">Docs</h6>
              </Col>
              <Col className="p-2 text-right"></Col>
            </Row>
          </CardHeader>
          <CardBody className="p-0">
            <Container fluid className="px-0">
              <table className="table mb-0">
                <thead className="py-2 bg-light text-semibold border-bottom">
                  <tr>
                    <th>Complete</th>
                    <th className="text-center">Doc</th>
                    <th className="text-right"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <FormCheckbox
                        onChange={() => toggleDoc("emailed")}
                        checked={data && data.docs && data.docs.emailed.checked}
                      />
                    </td>
                    <td className="text-center">Emailed</td>
                    <td className="text-right">
                      {data && data.docs && data.docs.emailed.checked
                        ? moment
                            .utc(data.docs.emailed.checkedDate)
                            .format("MM-DD-YYYY")
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FormCheckbox
                        onChange={() => toggleDoc("pickedUp")}
                        checked={
                          data && data.docs && data.docs.pickedUp.checked
                        }
                      />
                    </td>
                    <td className="text-center">Picked Up</td>
                    <td className="text-right">
                      {data && data.docs && data.docs.pickedUp.checked
                        ? moment
                            .utc(data.docs.pickedUp.checkedDate)
                            .format("MM-DD-YYYY")
                        : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Container>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default CheckList;
