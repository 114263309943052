import React from "react";
import {
  Row,
  Col,
  Form,
  FormInput,
  FormGroup,
  FormSelect,
  FormFeedback,
} from "shards-react";
import StateList from "../../../custom/StateList";

const AddressForm = ({ formRef, errors }) => {
  return (
    <>
      <Row form>
        <Col>
          <FormGroup>
            <FormInput
              id="feAddress"
              placeholder="1234 Main St"
              name="address"
              invalid={errors.field === "address" ? true : false}
            />
            <FormFeedback>{errors && errors.message}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <FormInput
              id="feAddress2"
              placeholder="Apartment, Studio or Floor"
              name="address2"
              invalid={errors.field === "address2" ? true : false}
            />
            <FormFeedback>{errors && errors.message}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>

      <Row form>
        <Col md="4" className="form-group">
          <FormInput
            id="feCity"
            name="city"
            placeholder="City"
            invalid={errors.field === "city" ? true : false}
          />
          <FormFeedback>{errors && errors.message}</FormFeedback>
        </Col>
        <Col md="4" className="form-group">
          <FormSelect
            id="feState"
            name="state"
            invalid={errors.field === "state" ? true : false}
          >
            <StateList />
          </FormSelect>
          <FormFeedback>{errors && errors.message}</FormFeedback>
        </Col>
        <Col md="4" className="form-group">
          <FormInput
            id="feZip"
            name="zip"
            placeholder="Zip"
            invalid={errors.field === "zip" ? true : false}
          />
          <FormFeedback>{errors && errors.message}</FormFeedback>
        </Col>
      </Row>
    </>
  );
};

export default AddressForm;
