/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
//import { useCookies } from "react-cookie";
//import { login } from "../services/authService";
import { login } from "../services/auth.service";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  FormInput,
  Button,
  FormFeedback,
} from "shards-react";

import logo from "../static/scs.png";

const Login = (props) => {
  let history = useHistory();
  const emailRef = useRef();
  const passwordRef = useRef();
  const [isValid, setIsValid] = useState(true);

  function handleSubmit(e) {
    e.preventDefault();
    login({
      email: emailRef.current.value,
      password: passwordRef.current.value,
    })
      .then((user) => {
        history.push("/clients");
      })
      .catch((ex) => {
        emailRef.current.value = "";
        passwordRef.current.value = "";
        setIsValid(false);
        console.log(ex);
      });
  }

  return (
    <Container fluid className="main-content-container h-100 px-4">
      <Row noGutters className="h-100">
        <Col lg="3" md="5" className="auth-form mx-auto my-auto">
          <Card className="mt-5">
            <CardBody>
              <img
                className="auth-form__logo d-table mx-auto mb-3"
                src={logo}
              ></img>
              {/* Title */}
              <h5 className="auth-form__title text-center mb-4">
                Access Your Account
              </h5>

              {/* Form Fields */}
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <label htmlFor="exampleInputEmail1">Email address</label>
                  <FormInput
                    type="email"
                    id="exampleInputEmail1"
                    placeholder="Enter email"
                    autoComplete="email"
                    innerRef={emailRef}
                    invalid={!isValid}
                  />
                  <FormFeedback>Incorrect Username/Password</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <label htmlFor="exampleInputPassword1">Password</label>
                  <FormInput
                    type="password"
                    id="exampleInputPassword1"
                    placeholder="Password"
                    autoComplete="current-password"
                    innerRef={passwordRef}
                  />
                </FormGroup>

                <Button
                  pill
                  theme="accent"
                  className="d-table mx-auto"
                  type="submit"
                >
                  Access Account
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
