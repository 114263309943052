import bookingService from "../services/booking.service";
import { MutationOption, QueryFunction } from "../component_modules/QuerySetup/QuerySetup";


/** Creat uploads defaults
 * 
 * 
 * @param {object} queryClient 
 * @returns queryClient
 */
export const setBookingsQueryDefaults = (queryClient) => {
    /**
     * Create Optimistic Update default Mutation loader
     * 
     * @param queryClient
     * @param param0 - Options & defaults
     * @param param0.DEBUG 
     * 
     * @returns {MutationOption} 
     */
    const optimistic = new MutationOption(queryClient, { DEBUG: true });

    optimistic.mutationFn = (variables) => bookingService.updateBooking(variables.id, variables.body);
    queryClient.setMutationDefaults("updateBooking", optimistic.objWithArrayProp("results").update);


    optimistic.mutationFn = (variables) => bookingService.deleteBooking(variables.id);
    queryClient.setMutationDefaults("deleteBooking", optimistic.objWithArrayProp("results").delete);



    /** QUERY FUNCTIONS */
    const queryFunctions = {
        "bookings": async (queryKey) => {
            const [_key, { page, limit, sortBy, search, start, end }] = queryKey;
            const { data } = await bookingService.getAllBookings({ page, limit, sortBy, search, start, end })
            return data;
        },
        "booking": async (queryKey) => {
            const [_key, _id] = queryKey;
            if (_id) {
                const { data } = await bookingService.getBooking(_id)
                return data;
            }
        },
        "payments": async (queryKey) => {
            const [_key, _id] = queryKey;
            if (_id) {
                const { data } = await bookingService.getAllPayments(_id)
                return data;
            }
        },
        "checklist": async (queryKey) => {
            const [_key, _id] = queryKey;
            if (_id) {
                const { data } = await bookingService.getChecklist(_id)
                return data;
            }
        },
        "reminders": async (queryKey) => {
            const [_key, _id] = queryKey;
            if (_id) {
                const { data } = await bookingService.getBookingReminders(_id)
                return data;
            }
        },
        "allReminders": async () => {

            const { data } = await bookingService.getAllReminders()
            return data;
        },


    }


    /** Create Default Function */
    const queryDefault = new QueryFunction(queryFunctions).defaultQueryFn;


    queryClient.setDefaultOptions({
        queries: {
            queryFn: queryDefault,
            staleTime: 10 * 1000,
        },
    })


    return queryClient;
}