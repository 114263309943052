import React from "react";
import { Container, Row, Col } from "shards-react";
import UserDetails from "../components/UserProfile/UserDetails";
import UserAccountDetails from "../components/UserProfile/UserAccountDetails";
import { authService } from "../services/auth.service";
import PageAlert from "../common/Alert/PageAlert";
import { useAlert } from "../common/Alert/useAlert";
import { useQuery, useQueryClient } from "react-query";
import { setUserQueryDefaults } from "../query/user.query";
import NotificationSlide from "../component_modules/UserProfile/NotificationsSlide";

const UserProfile = () => {
  const queryClient = useQueryClient();
  setUserQueryDefaults(queryClient);

  const { id } = authService.userValue;
  const { data, status } = useQuery(["user", id]);
  const { pageAlert, alertProps } = useAlert();

  return (
    <>
      <PageAlert alertProps={alertProps} />
      <Container fluid className="main-content-container px-4">
        <Row className="mt-4 d-flex rowFlex">
          <Col md="4">
            <UserDetails userDetails={data} status={status} />
          </Col>
          <Col md="8">
            <UserAccountDetails
              id={id}
              user={data}
              status={status}
              pageAlert={pageAlert}
            />
          </Col>
        </Row>
        <Row className="mt-4 d-flex rowFlex">
          <Col md="9" lg="6">
            <NotificationSlide title={"Notifications"} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserProfile;
