import apiKeyService from "../services/apiKey.service";
import { MutationOption, QueryFunction } from "../component_modules/QuerySetup/QuerySetup";

/** Create AdminManager defaults
 * 
 * 
 * @param {object} queryClient 
 * @returns queryClient
 */
export const setApiKeyManagerQueryDefaults = (queryClient) => {
    /**
     * Create Optimistic Update default Mutation loader
     * 
     * @param queryClient
     * @param param0 - Options & defaults
     * @param param0.DEBUG 
     * 
     * @returns {MutationOption} 
     */
    const optimistic = new MutationOption(queryClient, { DEBUG: false });

    optimistic.mutationFn = (variables) => apiKeyService.updateApiKey(variables.id, variables.body);
    queryClient.setMutationDefaults("updateApiKey", optimistic.array().update);

    optimistic.mutationFn = (variables) => apiKeyService.deleteApiKey(variables.id);
    queryClient.setMutationDefaults("deleteApiKey", optimistic.array().delete);

    optimistic.mutationFn = (variables) => apiKeyService.createApiKey(variables.body);
    queryClient.setMutationDefaults("createApiKey", optimistic.array().add);



    /** QUERY FUNCTIONS */
    const queryFunctions = {
        "apiKeys": async (queryKey) => {
            const [_key] = queryKey;
            const { data } = await apiKeyService.getApiKeys();
            return data;
        },
        "apiKey": async (queryKey) => {
            const [_key, _id] = queryKey;
            if (_id) {
                const { data } = await apiKeyService.getApiKey(_id);
                return data;
            }
        },
        "apiKeyToken": async (queryKey) => {
            const [_key, _id] = queryKey;
            if (_id) {
                const { data } = await apiKeyService.getApiKeyToken(_id);
                return data;
            }
        },
        "accessOptions": async (queryKey) => {
            const [_key] = queryKey;
            const { data } = await apiKeyService.getApiKeyOptions();
            return data;
        },

    }


    /** Create Default Function */
    const queryDefault = new QueryFunction(queryFunctions).defaultQueryFn;


    queryClient.setDefaultOptions({
        queries: {
            queryFn: queryDefault,
            staleTime: 10 * 1000,
        },
    })


    return queryClient;
}