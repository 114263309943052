import React, { useContext } from "react";
import { MenuContext } from "../../../context/MenuContext";

const NavbarToggle = () => {
  const { menuVisible, setMenuVisible } = useContext(MenuContext);

  return (
    <nav className="nav">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        href="#"
        //onClick={this.handleClick}
        onClick={() => setMenuVisible(!menuVisible)}
        className="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-inline d-lg-none text-center"
      >
        <i className="material-icons">&#xE5D2;</i>
      </a>
    </nav>
  );
};

export default NavbarToggle;
