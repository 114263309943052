import { ButtonGroup, Button } from "shards-react";
import capitalize from "../../utils/capitalize";
import moment from "moment";

export default function BookingListColumns(viewRow, editRow, deleteRow) {
  return [
    {
      Header: "id",
      accessor: "id",
      show: false,
    },
    {
      Header: "Booking Date",
      accessor: "bookingDate",
      minWidth: 35,
      Cell: (row) => (
        <span>{moment(row.original.bookingDate).format("MM-DD-YYYY")}</span>
      ),
    },
    {
      Header: "Travel Date",
      accessor: "travelDate",
      minWidth: 35,
      Cell: (row) => <span>{moment(row.original.travelDate).fromNow()}</span>,
    },
    {
      Header: "Updated",
      accessor: "updatedAt",
      minWidth: 35,
      Cell: (row) => <span>{moment(row.original.updatedAt).fromNow()}</span>,
    },
    {
      Header: "Client",
      accessor: "client",
      minWidth: 55,
      Cell: (row) => {
        if (!row.original.client) return "No Client";
        return (
          <span>
            {`${capitalize(row.original.client.primary.lastName)}, ${capitalize(
              row.original.client.primary.firstName
            )}`}
          </span>
        );
      },
    },
    {
      Header: "Agent",
      accessor: "lastName",
      minWidth: 55,
      Cell: (row) => (
        <span>
          {`${capitalize(row.original.agent.lastName)}, ${capitalize(
            row.original.agent.firstName
          )}`}
        </span>
      ),
    },
    {
      Header: "Trip",
      accessor: "name",
      minWidth: 75,
      className: "text-center",
      Cell: (row) => <span>{row.original.name}</span>,
    },
    {
      Header: "Price",
      accessor: "totalPrice",
      minWidth: 15,
      className: "text-center",
      Cell: (row) => <span>${row.original.totalPrice}</span>,
    },
    {
      Header: "Actions",
      accessor: "actions",
      maxWidth: 130,
      minWidth: 180,
      sortable: false,
      Cell: (row) => (
        <ButtonGroup size="sm" className="d-table mx-auto">
          <Button
            theme="white"
            onClick={() => {
              viewRow(row.original);
            }}
          >
            <i className="material-icons">&#xE416;</i>
          </Button>
          <Button
            theme="white"
            onClick={() => {
              editRow(row.original.id);
            }}
          >
            <i className="material-icons">&#xE254;</i>
          </Button>
          <Button
            theme="white"
            onClick={() => {
              deleteRow(row.original);
            }}
          >
            <i className="material-icons">&#xE872;</i>
          </Button>
        </ButtonGroup>
      ),
    },
  ];
}
