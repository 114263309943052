import React from "react";
import { Row, Col, Form, FormSelect } from "shards-react";
import ModalFormInput from "../../common/DataTableCard/components/ModalFormInput";

const NewAgentForm = ({
  formData,
  setFormData,
  formError,
  setFormError,
  isEdit,
}) => {
  return (
    <Form>
      <Row form>
        <Col md="6" className="form-group">
          <ModalFormInput
            name="firstName"
            placeholder="First Name"
            value={formData && formData.firstName}
            onChange={(e) => {
              setFormError();
              setFormData((formData) => {
                return {
                  ...formData,
                  firstName: e.target.value,
                };
              });
            }}
            formError={formError}
          />
        </Col>
        <Col md="6" className="form-group">
          <ModalFormInput
            name="lastName"
            placeholder="Last Name"
            value={formData && formData.lastName}
            onChange={(e) => {
              setFormError();
              setFormData((formData) => {
                return {
                  ...formData,
                  lastName: e.target.value,
                };
              });
            }}
            formError={formError}
          />
        </Col>
      </Row>
      <Row form>
        <Col md="6" className="form-group">
          <ModalFormInput
            name="email"
            placeholder="Email"
            value={formData && formData.email}
            onChange={(e) => {
              setFormError();
              setFormData((formData) => {
                return {
                  ...formData,
                  email: e.target.value,
                };
              });
            }}
            formError={formError}
          />
        </Col>
        <Col md="6" className="form-group">
          <ModalFormInput
            name="phone"
            placeholder="Phone Number"
            value={formData && formData.phone}
            onChange={(e) => {
              setFormError();
              setFormData((formData) => {
                return {
                  ...formData,
                  phone: e.target.value,
                };
              });
            }}
            formError={formError}
          />
        </Col>
      </Row>
      <Row form>
        <Col md="12" className="form-group">
          <ModalFormInput
            name="password"
            placeholder="Temp Password"
            value={formData && formData.password}
            onChange={(e) => {
              setFormError();
              setFormData((formData) => {
                return {
                  ...formData,
                  password: e.target.value,
                };
              });
            }}
            formError={formError}
          />
        </Col>
      </Row>
      {isEdit && (
        <Row form>
          <Col md="12" className="form-group">
            <FormSelect
              value={formData && formData.role}
              onChange={(e) => {
                setFormData((formData) => {
                  return {
                    ...formData,
                    role: e.target.value,
                  };
                });
              }}
            >
              <option value="user">User</option>
              <option value="admin">Admin</option>
            </FormSelect>
          </Col>
        </Row>
      )}
    </Form>
  );
};

export default NewAgentForm;
