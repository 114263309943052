import { authService } from "./auth.service";
import { apiUrl } from "../config.json";
import axios from "axios";
import fileDownload from 'js-file-download';


function authorizeDownloader(token) {
    return axios.create({
        withCredentials: false,
        baseURL: `${apiUrl}`,
        responseType: 'blob',
        headers: {
            common: {
                'Authorization': `Bearer ${token}`
            }
        }
    });
}

export async function downloadFile(userId, uploadId, fileName) {
    const accessToken = authService.userValue.token;
    const http = authorizeDownloader(accessToken);
    const { data } = await http.get(`/uploads/users/${userId}/download/${uploadId}?action=download`);
    fileDownload(data, fileName);
}

export async function previewFile(userId, uploadId) {
    const accessToken = authService.userValue.token;
    const http = authorizeDownloader(accessToken);
    const { data } = await http.get(`/uploads/users/${userId}/download/${uploadId}?action=download`);
    return data;
}