import React from "react";
import { FormInput, FormFeedback } from "shards-react";

const ModalFormInput = ({ name, placeholder, value, onChange, formError }) => {
  return (
    <>
      <FormInput
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        invalid={
          formError && formError.path[formError.path.length - 1] === name
        }
      />
      <FormFeedback>{formError && formError.message}</FormFeedback>
    </>
  );
};

export default ModalFormInput;
