import React from "react";
import { Card, CardBody } from "shards-react";

/* Table */
import UploadColumns from "./adminTable/UploadColumns";
import TableHeader from "./adminTable/TableHeader";
import FileManagerTable from "./adminTable/FileManagerTable";
import { useMutation, useQueryClient } from "react-query";
const pageSizeOptions = [5, 10, 25, 50, 75, 100];

const AdminFileManager = ({
  limit,
  setLimit,
  search,
  setSearch,
  data,
  status,
  setSortBy,
  setPage,
  pages,
  page,
  sortBy,
  handleFileEdit,
  handleDownloadButton,
  alertPage,
  setDeleteHandler,
}) => {
  const queryClient = useQueryClient();
  /**
   * Delete Mutation
   */
  const uploadsDeleter = useMutation("deleteAdminUploadsPagination");
  /**
   * Prefetch Query
   */
  const preFetchData = (id) => {
    queryClient.prefetchQuery(["upload", id], { staleTime: 10 * 1000 });
  };

  function handleDelete(item) {
    setDeleteHandler({
      deleteFn: () => {
        uploadsDeleter.mutate(
          {
            id: item.id,
            key: ["uploads", { page, limit, sortBy, search }],
          },
          {
            onError: () => {
              alertPage.error(`Unable to delete file: ${item.originalname}`);
            },
            onSuccess: () => {
              alertPage.success(`File: ${item.originalname} DELETED`);
            },
          }
        );
      },
      item: `File ${item.originalname}`,
    });
  }
  /**
   *
   *
   */
  return (
    <Card className="file-manager file-manager-list p-0">
      <TableHeader
        limit={limit}
        setLimit={setLimit}
        pageSizeOptions={pageSizeOptions}
        search={search}
        setSearch={setSearch}
      />
      <CardBody className="p-0">
        <FileManagerTable
          columns={UploadColumns({
            handleFileDownload: (row) => {
              handleDownloadButton(row);
            },
            handleFileEdit: (row) => {
              handleFileEdit(row);
            },
            handleFileDelete: (row) => {
              handleDelete(row);
            },
          })}
          data={data}
          pageSize={limit}
          pages={pages}
          loading={status === "loading"}
          setSortBy={setSortBy}
          prefetchItem={preFetchData}
          setLimit={setLimit}
          setPage={setPage}
        />
      </CardBody>
    </Card>
  );
};

export default AdminFileManager;
