import React, { useState, useEffect, memo } from "react";
import classNames from "classnames";
import { Col, Card, CardBody, CardFooter } from "shards-react";
import { useDrag } from "react-dnd";
// Core
import ThumbNailViewer from "../common/DocumentViewer/ThumbNailViewer";
import DocumentFooter from "../common/DocumentViewer/DocumentFooter";

export const Box = memo(function Box({
  userId,
  fileId,
  name,
  type,
  fileName,
  fileType,
  fileSize,
  previewFile,
  selected,
  setSelected,
}) {
  const [file, setFile] = useState();
  const [{ opacity }, drag] = useDrag(
    () => ({
      type,
      item: { name, fileId },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [fileId, name, type]
  );

  const isSelected = selected === fileId;

  const classes = classNames(
    isSelected && "file-manager__item--selected",
    "file-manager__item",
    "file-manager__item--directory",
    "mb-3"
  );

  useEffect(() => {
    previewFile(userId, fileId).then((res) => {
      const filePreview = URL.createObjectURL(res);
      setFile(filePreview);
    });

    return () => {
      URL.revokeObjectURL(file);
    };
  }, [fileId]);

  return (
    <Col lg="3" sm="6" key={fileId}>
      <div ref={drag} role="Box">
        <Card
          small
          className={classes}
          onClick={() => {
            if (isSelected) setSelected("");
            else setSelected(fileId);
          }}
        >
          <CardBody className="file-manager__item-preview px-0 pb-0 pt-4">
            {file && (
              <ThumbNailViewer
                fileType={fileType}
                fileName={fileName}
                url={file}
              />
            )}
          </CardBody>
          <CardFooter className="border-top">
            <DocumentFooter fileName={fileName} fileSize={fileSize} />
          </CardFooter>
        </Card>
      </div>
    </Col>
  );
});

export default Box;
