import { ButtonGroup, Button } from "shards-react";
import capitalize from "../../utils/capitalize";

export default function VendorListColumns(viewRow, editRow, deleteRow) {
  return [
    {
      Header: "id",
      accessor: "id",
      show: false,
    },
    {
      Header: "Name",
      accessor: "name",
      minWidth: 55,
      Cell: (row) => <span>{`${capitalize(row.original.name)}`}</span>,
    },
    {
      Header: "Phone",
      accessor: "phone",
      minWidth: 55,
      Cell: (row) => <span>{`${row.original.phone}`}</span>,
    },
    {
      Header: "Actions",
      accessor: "actions",
      maxWidth: 130,
      minWidth: 180,
      sortable: false,
      Cell: (row) => (
        <ButtonGroup size="sm" className="d-table mx-auto">
          <Button
            theme="white"
            onClick={() => {
              viewRow(row.original);
            }}
          >
            <i className="material-icons">&#xE416;</i>
          </Button>
          <Button
            theme="white"
            onClick={() => {
              editRow(row.original);
            }}
          >
            <i className="material-icons">&#xE254;</i>
          </Button>
          <Button
            theme="white"
            onClick={() => {
              deleteRow(row.original);
            }}
          >
            <i className="material-icons">&#xE872;</i>
          </Button>
        </ButtonGroup>
      ),
    },
  ];
}
