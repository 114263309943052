import moment from "moment";
import { ButtonGroup, Button } from "shards-react";

const tours = [
  {
    Header: "id",
    accessor: "_id",
    show: false,
  },
  {
    Header: "Operator",
    accessor: "operator",
  },
  {
    Header: "Confirmation",
    accessor: "confirmation",
  },
  {
    Header: "From",
    accessor: "fromDate",
    minWidth: 40,
    Cell: (row) => {
      return <span>{moment.utc(row.original.fromDate).format("MM-DD")}</span>;
    },
  },
  {
    Header: "To",
    accessor: "toDate",
    minWidth: 40,
    Cell: (row) => {
      return <span>{moment.utc(row.original.toDate).format("MM-DD")}</span>;
    },
  },
  {
    Header: "$",
    accessor: "package",
    minWidth: 40,
    Cell: (row) => {
      if (row.original.package) {
        return <span className="material-icons">task_alt</span>;
      }
      return <span>${row.original.price}</span>;
    },
  },
  {
    accessor: "actions",
    maxWidth: 130,
    minWidth: 40,
    sortable: false,
    Cell: (row) => (
      <ButtonGroup size="sm" className="d-table mx-auto my-auto">
        <Button
          theme="white"
          onClick={() => {
            console.log("del", row);
          }}
        >
          <i className="material-icons">&#xE872;</i>
        </Button>
      </ButtonGroup>
    ),
  },
];

const hotels = [
  {
    Header: "id",
    accessor: "_id",
    show: false,
  },
  {
    Header: "Hotel",
    accessor: "hotel",
  },
  {
    Header: "#",
    accessor: "room",
    minWidth: 40,
  },
  {
    Header: "From",
    accessor: "fromDate",
    minWidth: 40,
    Cell: (row) => {
      return <span>{moment.utc(row.original.fromDate).format("MM-DD")}</span>;
    },
  },
  {
    Header: "To",
    accessor: "toDate",
    minWidth: 40,
    Cell: (row) => {
      return <span>{moment.utc(row.original.toDate).format("MM-DD")}</span>;
    },
  },
  {
    Header: "$",
    accessor: "package",
    minWidth: 40,
    Cell: (row) => {
      if (row.original.package) {
        return <span className="material-icons">task_alt</span>;
      }
      return <span>${row.original.price}</span>;
    },
  },
  {
    accessor: "actions",
    maxWidth: 130,
    minWidth: 40,
    sortable: false,
    Cell: (row) => (
      <ButtonGroup size="sm" className="d-table mx-auto my-auto">
        <Button
          theme="white"
          onClick={() => {
            console.log("del", row);
          }}
        >
          <i className="material-icons">&#xE872;</i>
        </Button>
      </ButtonGroup>
    ),
  },
];

const cruise = [
  {
    Header: "Line",
    accessor: "cruiseLine",
  },
  {
    Header: "Confirmation",
    accessor: "confirmation",
  },
  {
    Header: "Deck",
    accessor: "deck",
  },
  {
    Header: "From",
    accessor: "fromDate",
    minWidth: 40,
    Cell: (row) => {
      return <span>{moment.utc(row.original.fromDate).format("MM-DD")}</span>;
    },
  },
  {
    Header: "To",
    accessor: "toDate",
    minWidth: 40,
    Cell: (row) => {
      return <span>{moment.utc(row.original.toDate).format("MM-DD")}</span>;
    },
  },
  {
    Header: "$",
    accessor: "package",
    minWidth: 40,
    Cell: (row) => {
      if (row.original.package) {
        return <span className="material-icons">task_alt</span>;
      }
      return <span>${row.original.price}</span>;
    },
  },
  {
    accessor: "actions",
    maxWidth: 130,
    minWidth: 40,
    sortable: false,
    Cell: (row) => (
      <ButtonGroup size="sm" className="d-table mx-auto my-auto">
        <Button
          theme="white"
          onClick={() => {
            console.log("del", row);
          }}
        >
          <i className="material-icons">&#xE872;</i>
        </Button>
      </ButtonGroup>
    ),
  },
];

const rental = [
  {
    Header: "Company",
    accessor: "company",
  },
  {
    Header: "Pick Up",
    accessor: "pickUp",
  },
  {
    Header: "Drop Off",
    accessor: "dropOff",
  },
  {
    Header: "From",
    accessor: "fromDate",
    minWidth: 40,
    Cell: (row) => {
      return <span>{moment.utc(row.original.fromDate).format("MM-DD")}</span>;
    },
  },
  {
    Header: "To",
    accessor: "toDate",
    minWidth: 40,
    Cell: (row) => {
      return <span>{moment.utc(row.original.toDate).format("MM-DD")}</span>;
    },
  },
  {
    Header: "$",
    accessor: "package",
    minWidth: 40,
    Cell: (row) => {
      if (row.original.package) {
        return <span className="material-icons">task_alt</span>;
      }
      return <span>${row.original.price}</span>;
    },
  },
  {
    accessor: "actions",
    maxWidth: 130,
    minWidth: 40,
    sortable: false,
    Cell: (row) => (
      <ButtonGroup size="sm" className="d-table mx-auto my-auto">
        <Button
          theme="white"
          onClick={() => {
            console.log("del", row);
          }}
        >
          <i className="material-icons">&#xE872;</i>
        </Button>
      </ButtonGroup>
    ),
  },
];

const exports = {
  tours,
  hotels,
  cruise,
  rental,
};

export default exports;
