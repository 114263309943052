import { useFormError } from "../formError/useFormError";
import { useQueryClient, useMutation } from "react-query";

export function useMutationUpdate(
  mutationFn,
  setFormData,
  queryKey,
  setFormError
) {
  const queryClient = useQueryClient();

  const setNewError = useFormError(setFormError);

  return useMutation(mutationFn, {
    onMutate: async (variables) => {
      setFormData();
      setFormError();
      await queryClient.cancelQueries(queryKey[0]);

      const previousData = queryClient.getQueryData(queryKey);

      // Optimistic Update
      queryClient.setQueryData(queryKey, (old) => {
        return {
          ...old,
          results: old.results.map((result) => {
            if (result.id === variables.id) {
              return {
                ...result,
                ...variables,
              };
            } else {
              return result;
            }
          }),
        };
      });

      return { previousData };
    },
    onError: (error, variables, { previousData }) => {
      console.log("Unable to save");

      queryClient.setQueryData(queryKey, previousData);

      setNewError(error);
    },
    onSuccess: (data, variables, context) => {
      queryClient.removeQueries([queryKey[0], variables.id], { exact: true });
      queryClient.prefetchQuery(
        [queryKey[0], variables.id],
        async () => {
          const { data } = await getUpload(variables.id);
          return data;
        },
        {
          staleTime: 10 * 1000,
        }
      );
      console.log("Mutation Success");
    },
    onSettled: (data, error, variables) => {
      if (error) {
        setFormData(variables);
      }
      queryClient.invalidateQueries(queryKey);
    },
  });
}

export function useMutationDelete(mutationFn, queryKey) {
  const queryClient = useQueryClient();

  const { page } = queryKey[1];
  const nextPageQueryKey = [
    queryKey[0],
    {
      ...queryKey[1],
      page: page + 1,
    },
  ];

  return useMutation(mutationFn, {
    onMutate: async (variables) => {
      await queryClient.cancelQueries(queryKey[0]);
      await queryClient.cancelQueries(nextPageQueryKey);

      const previousData = queryClient.getQueryData(queryKey);
      const nextPageData = queryClient.getQueryData(nextPageQueryKey);

      // Optimistic Delete - If next page exists
      if (nextPageData) {
        const { results } = nextPageData;

        const nextRecord = results ? results[0] : [];

        // Update Current page
        queryClient.setQueryData(queryKey, (old) => {
          return {
            ...old,
            results: nextRecord
              ? [
                  ...old.results.filter((result) => result.id !== variables),
                  nextRecord,
                ]
              : [...old.results.filter((result) => result.id !== variables)],
            totalResults: old.totalResults - 1,
          };
        });

        // Update Next page
        if (results[0]) results.shift();
        queryClient.setQueryData(nextPageQueryKey, (old) => {
          return {
            ...old,
            results: results,
          };
        });
      } else {
        // Optimistic Delete - No next page
        queryClient.setQueryData(queryKey, (old) => {
          return {
            ...old,
            results: old.results.filter((result) => result.id !== variables),
            totalResults: old.totalResults - 1,
          };
        });
      }

      return { previousData, nextPageData };
    },
    onError: (error, variables, { previousData, nextPageData }) => {
      console.log("Unable to delete");
      queryClient.setQueryData(queryKey, previousData);
      if (nextPageData)
        queryClient.setQueryData(nextPageQueryKey, nextPageData);
    },
    onSuccess: (data) => {
      //console.log("Deleted");
    },
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries(nextPageQueryKey);
      queryClient.invalidateQueries(queryKey);
    },
  });
}

export function useMutationToggleUpdate(mutationFn, queryKey) {
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onMutate: async (variables) => {
      await queryClient.cancelQueries(queryKey[0]);

      const previousData = queryClient.getQueryData(queryKey);

      // Optimistic Update
      queryClient.setQueryData(queryKey, (old) => {
        return {
          ...old,
          results: old.results.map((result) => {
            if (result.id === variables.id) {
              return {
                ...result,
                ...variables.body,
              };
            } else {
              return result;
            }
          }),
        };
      });

      return { previousData };
    },
    onError: (error, variables, { previousData }) => {
      console.log("Unable to save");

      queryClient.setQueryData(queryKey, previousData);
    },

    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries(queryKey);
    },
  });
}

export function useMutationSingleUpdate(
  mutationFn,
  setFormData,
  queryKey,
  setFormError
) {
  const queryClient = useQueryClient();

  const setNewError = useFormError(setFormError);

  return useMutation(mutationFn, {
    onMutate: async (variables) => {
      setFormData();
      setFormError({});
      await queryClient.cancelQueries(queryKey);

      const previousData = queryClient.getQueryData(queryKey);

      // Optimistic Update
      queryClient.setQueryData(queryKey, (old) => {
        return {
          ...old,
          ...variables.body,
        };
      });

      return { previousData };
    },
    onError: (error, variables, { previousData }) => {
      console.log("Unable to save");

      queryClient.setQueryData(queryKey, previousData);

      setNewError(error);
    },

    onSettled: (data, error, variables) => {
      if (error) {
        setFormData(variables);
      }
      queryClient.invalidateQueries(queryKey);
    },
  });
}
