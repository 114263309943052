import React from "react";
import {
  Form,
  FormInput,
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormSelect,
  FormFeedback,
  Row,
  Col,
} from "shards-react";

const EditFileModal = ({
  isOpen,
  setIsOpen,
  updated,
  setUpdated,
  handleSubmit,
  error,
}) => {
  const [formError, setFormError] = React.useState();

  React.useEffect(() => {
    setFormError(error);
  }, [error]);

  return (
    <div>
      <Modal
        size="sm"
        open={isOpen}
        toggle={() => {
          setIsOpen(false);
        }}
      >
        <ModalHeader>Edit Filename</ModalHeader>
        <ModalBody>
          <Form
            //className="quick-post-form"
            onSubmit={(e) => e.preventDefault()}
          >
            <FormGroup>
              <FormInput
                name="originalname"
                placeholder="File"
                value={updated && updated.originalname}
                onChange={(e) => {
                  setUpdated((updated) => {
                    return {
                      ...updated,
                      originalname: e.target.value,
                    };
                  });
                }}
                onClick={() => {
                  if (formError && formError.field === "originalname")
                    setFormError();
                }}
                invalid={formError && formError.field === "originalname"}
              />
              <FormFeedback>{formError && formError.message}</FormFeedback>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button className="btn-accent" onClick={() => handleSubmit()}>
            Save
          </Button>
          <Button className="btn-accent" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditFileModal;
