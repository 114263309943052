import { authService } from "./auth.service";
import { createAuthCaller } from "./http.service";
import queryString from "query-string";
import removeMissing from "../utils/removeMissing";

export function markSeen(id) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    return http.patch(`/notifications/${id}`);
}

export function getNotifications(params) {
    const accessToken = authService.userValue.token;
    const http = createAuthCaller(accessToken);
    const query = queryString.stringify(params);
    return http.get(`/notifications?${query}`)
}

const notificationService = {
    markSeen,
    getNotifications
}

export default notificationService