import {
  getAllClients,
  getClient,
  updateClient,
  deleteClient,
  addFreeText,
  addClientUser,
  removeClientUser,
} from "../../services/client.service";

import { useQuery } from "react-query";
import {
  useMutationUpdate,
  useMutationDelete,
} from "../mutation/useCreateMutations";
import {
  useMutationUpdateFreeText,
  useMutationAddClientUser,
  useMutationRemoveClientUser,
} from "./clientMutations";
import {
  usePrefetchPages,
  usePrefetchPageSize,
  usePrefetchId,
} from "../prefetch/usePreFetchers";
import { updateUpload } from "../../services/upload.service";

/**
 * - useClients
 *
 * @param {object} param0
 * @returns React Query - Many Records
 */
export function useClients({ page, limit, sortBy, search, start, end }) {
  return useQuery(
    ["clients", { page, limit, sortBy, search, start, end }],
    async () => {
      const { data } = await getAllClients({
        page,
        limit,
        sortBy,
        search,
        start,
        end,
      });
      //console.log(data);
      return data;
    },
    { staleTime: 10 * 1000 }
  );
}

/**
 * - useClient
 *
 * @param {string} id
 * @returns React Query - Single Record
 */
export function useClient(id) {
  return useQuery(
    ["clients", id],
    async () => {
      if (id) {
        const { data } = await getClient(id);
        return data;
      }
    },
    { staleTime: 10 * 1000 }
  );
}

/**
 * - useUpdate
 *
 * @param {function} setFormData set form data state
 * @param {array} queryKey query key for page data
 * @param {function} setFormError set form error state
 * @returns {object} useMutation object
 */
export function useUpdate(setFormData, queryKey, setFormError) {
  return useMutationUpdate(
    (update) =>
      updateClient(update.id, {
        fileName: update.fileName,
      }),
    setFormData,
    queryKey,
    setFormError
  );
}

/**
 * - useDelete
 *
 * @param {array} queryKey query key for page data
 * @returns {object} useMutation object
 */
export function useDelete(queryKey) {
  return useMutationDelete(deleteClient, queryKey);
}

/**
 * - usePrefetchClients
 *
 * @param {object} param0
 * @param {object} data
 * @param {array} pageSizeOptions
 * @returns {function} setPreFetchId
 */
export function usePrefetchClients(
  { page, limit, sortBy, search, start, end },
  data,
  pageSizeOptions
) {
  usePrefetchPages(
    "clients",
    { page, limit, sortBy, search, start, end },
    data,
    getAllClients
  );
  usePrefetchPageSize(
    "clients",
    pageSizeOptions,
    { page, limit, sortBy, search, start, end },
    data,
    getAllClients
  );
  const setPreFetchId = usePrefetchId("clients", getClient);
  return setPreFetchId;
}

export function useAddFreeText(setFormData, queryKey) {
  //console.log(setFormData, queryKey, setFormError);
  return useMutationUpdateFreeText(
    (update) => addFreeText(update.id, update.freeText),
    setFormData,
    queryKey
  );
}

export function useAddClientUser(setFormData, queryKey) {
  return useMutationAddClientUser(
    (update) => addClientUser(update.clientId, update.user.id),
    setFormData,
    queryKey
  );
}

export function useRemoveClientUser(queryKey) {
  return useMutationRemoveClientUser(
    (update) => removeClientUser(update.clientId, update.userId),
    queryKey
  );
}

const exports = {
  useClients,
  useClient,
  usePrefetchClients,
  useDelete,
  useUpdate,
  useAddFreeText,
  useRemoveClientUser,
};

export default exports;
