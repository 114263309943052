import clientService from "../services/client.service";
import bookingService from "../services/booking.service";


import { MutationOption, QueryFunction } from "../component_modules/QuerySetup/QuerySetup";

/** Creat uploads defaults
 * 
 * 
 * @param {object} queryClient 
 * @returns queryClient
 */
export const setClientQueryDefaults = (queryClient) => {
    /**
     * Create Optimistic Update default Mutation loader
     * 
     * @param queryClient
     * @param param0 - Options & defaults
     * @param param0.DEBUG 
     * 
     * @returns {MutationOption} 
     */
    const optimistic = new MutationOption(queryClient, { DEBUG: true });

    optimistic.mutationFn = (variables) => clientService.updateClient(variables.id, variables.body);
    queryClient.setMutationDefaults("updateClient", optimistic.objWithArrayProp("results").update);


    optimistic.mutationFn = (variables) => clientService.addFreeText(variables.id, variables.params);
    queryClient.setMutationDefaults("addFreeText", optimistic.item().update);


    optimistic.mutationFn = (variables) => clientService.deleteClient(variables.id);
    queryClient.setMutationDefaults("deleteClient", optimistic.objWithArrayProp("results").delete);


    /** QUERY FUNCTIONS */
    const queryFunctions = {
        "clients": async (queryKey) => {
            const [_key, { page, limit, sortBy, search, start, end }] = queryKey;
            const { data } = await clientService.getAllClients({ page, limit, sortBy, search, start, end })
            return data;
        },
        "client": async (queryKey) => {
            const [_key, _id] = queryKey;
            if (_id) {
                const { data } = await clientService.getClient(_id)
                return data;
            }
        },
        "clientBookings": async (queryKey) => {
            const [_key, { page, limit, sortBy, search, clientId }] = queryKey;
            const { data } = await bookingService.getAllBookings({ page, limit, sortBy, search, clientId })
            return data;
        },

    }


    /** Create Default Function */
    const queryDefault = new QueryFunction(queryFunctions).defaultQueryFn;


    queryClient.setDefaultOptions({
        queries: {
            queryFn: queryDefault,
            staleTime: 10 * 1000,
        },
    })


    return queryClient;
}