import vendorService from "../services/vendor.service";
import payoutService from "../services/payout.service";

import { MutationOption, QueryFunction } from "../component_modules/QuerySetup/QuerySetup";

/** Creat uploads defaults
 * 
 * 
 * @param {object} queryClient 
 * @returns queryClient
 */
export const setVendorQueryDefaults = (queryClient) => {
    /**
     * Create Optimistic Update default Mutation loader
     * 
     * @param queryClient
     * @param param0 - Options & defaults
     * @param param0.DEBUG 
     * 
     * @returns {MutationOption} 
     */
    const optimistic = new MutationOption(queryClient, { DEBUG: true });

    optimistic.mutationFn = (variables) => vendorService.updateVendor(variables.id, variables.body);
    queryClient.setMutationDefaults("updateVendor", optimistic.objWithArrayProp("results").update);


    optimistic.mutationFn = (variables) => vendorService.deleteVendor(variables.id);
    queryClient.setMutationDefaults("deleteVendor", optimistic.objWithArrayProp("results").delete);


    //optimistic.mutationFn = (variables) => vendorService.updatePayout(variables.id, variables.body);
    //optimistic.mutationFn = (variables) => payoutService.updatePayout(variables.id, variables.body);
    //queryClient.setMutationDefaults("updatePayout", optimistic.objWithArrayProp("results").update);


    optimistic.mutationFn = (variables) => payoutService.deletePayout(variables.id);
    queryClient.setMutationDefaults("deletePayout", optimistic.objWithArrayProp("results").delete);


    /** QUERY FUNCTIONS */
    const queryFunctions = {
        "vendors": async (queryKey) => {
            const [_key, { page, limit, sortBy, search }] = queryKey;
            const { data } = await vendorService.getAllVendors({ page, limit, sortBy, search })
            return data;
        },
        "vendor": async (queryKey) => {
            const [_key, _id] = queryKey;
            if (_id) {
                const { data } = await vendorService.getVendor(_id)
                return data;
            }
        },
        "payout": async (queryKey) => {
            const [_key, _id] = queryKey;
            if (_id) {
                const { data } = await vendorService.getBookingVendors(_id)
                return data
            }
        },
        "payouts": async (queryKey) => {
            const [_key, { page, limit, sortBy, search, status, start, end }] = queryKey;
            //const { data } = await vendorService.getPayouts({ page, limit, sortBy, search, status, start, end })
            const { data } = await payoutService.getPayouts({ page, limit, sortBy, search, status, start, end })
            return data;
        },
        "invoices": async (queryKey) => {
            const [_key, { bookingId, vendor }] = queryKey;
            const { data } = await payoutService.getInvoicePayouts({ bookingId, vendor })
            return data;
        }

    }


    /** Create Default Function */
    const queryDefault = new QueryFunction(queryFunctions).defaultQueryFn;


    queryClient.setDefaultOptions({
        queries: {
            queryFn: queryDefault,
            staleTime: 10 * 1000,
        },
    })


    return queryClient;
}