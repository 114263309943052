import { ButtonGroup, Button } from "shards-react";
import capitalize from "../../utils/capitalize";
import formatPhoneNumber from "../../utils/formatPhoneNumber";
import moment from "moment";

export default function ClientColumns(viewRow, editRow, deleteRow) {
  return [
    {
      Header: "id",
      accessor: "id",
      show: false,
    },
    {
      Header: "Joined",
      accessor: "createdAt",
      minWidth: 35,
      Cell: (row) => (
        <span>{moment(row.original.createdAt).format("MM-DD-YYYY")}</span>
      ),
    },
    {
      Header: "Updated",
      accessor: "updatedAt",
      minWidth: 35,
      Cell: (row) => <span>{moment(row.original.createdAt).fromNow()}</span>,
    },
    {
      Header: "Client",
      accessor: "primary.lastName",
      minWidth: 75,
      Cell: (row) => (
        <span>
          {`${capitalize(row.original.primary.lastName)}, ${capitalize(
            row.original.primary.firstName
          )}`}
        </span>
      ),
    },
    {
      Header: "Phone",
      accessor: "phone",
      minWidth: 45,
      className: "text-center",
      Cell: (row) => <span>{formatPhoneNumber(row.original.phone)}</span>,
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: (row) => <span>{row.original.email}</span>,
    },

    {
      Header: "Actions",
      accessor: "actions",
      maxWidth: 130,
      minWidth: 180,
      sortable: false,
      Cell: (row) => (
        <ButtonGroup size="sm" className="d-table mx-auto">
          <Button
            theme="white"
            onClick={() => {
              viewRow(row.original);
            }}
          >
            <i className="material-icons">&#xE416;</i>
          </Button>
          <Button
            theme="white"
            onClick={() => {
              editRow(row.original);
            }}
          >
            <i className="material-icons">&#xE254;</i>
          </Button>
          <Button
            theme="white"
            onClick={() => {
              deleteRow(row.original);
            }}
          >
            <i className="material-icons">&#xE872;</i>
          </Button>
        </ButtonGroup>
      ),
    },
  ];
}
