import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "shards-react";
import PageAlert, { usePageAlert } from "../common/Alert/PageAlert";
import AreYouSure, { useAreYouSure } from "../common/AreYouSure/AreYouSure";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { setVendorQueryDefaults } from "../query/vendor.query";
import { useSearchBar } from "../custom/SiteSearchBar/SiteSearchBar";
import PlainHeader from "../common/PlainHeader";
import vendorService from "../services/vendor.service";
import DataTableCard from "../common/DataTableCard/DataTableCard";
import VendorListColumns from "../components/Vendors/VendorListColumns";
import NewVendorForm from "../components/Vendors/NewVendorForm";
import vendorValidation from "../validation/vendor.validation";

const Vendors = () => {
  /* Query Defaults */
  const queryClient = useQueryClient();
  setVendorQueryDefaults(queryClient);
  /* Table State */
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState("name:asc");
  const { search, setSearch } = useSearchBar("");
  const [vendorData, setVendorData] = useState({});

  const { alertPage, alertProps } = usePageAlert();
  const { areYouSureProps, setDeleteHandler } = useAreYouSure();
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState();
  const [formError, setFormError] = useState();

  /* Query */
  const { data, status } = useQuery([
    "vendors",
    { page, limit, sortBy, search },
  ]);
  /* Move back a page when no results */
  // - This
  useEffect(() => {
    if (data) {
      if (!data.results[0] && page > 1) {
        setPage((page) => page - 1);
      }
    }
  }, [data]);
  /* Page limited to total pages */
  // - Or This
  useEffect(() => {
    if (data) {
      if (page > data.totalPages) {
        setPage(data.totalPages);
      }
    }
  }, [data]);

  /* Clear Form on Modal close */
  useEffect(() => {
    if (!isOpen) {
      setFormData();
      setFormError();
    }
  }, [isOpen]);

  /* Delete Function */
  const vendorDeleter = useMutation("deleteVendor");
  function handleDelete(item) {
    setDeleteHandler({
      deleteFn: async () => {
        vendorDeleter.mutate(
          {
            id: item.id,
            key: ["vendors", { page, limit, sortBy, search }],
          },
          {
            onError: alertPage.error(`Unable to delete vendor: ${item.name}`),
            onSuccess: alertPage.success(`Vendor: ${item.name} DELETED`),
          }
        );
      },
      item: `${item.name}`,
    });
  }
  /* Update Function */
  const vendorUpdator = useMutation("updateVendor");
  function handleUpdate(item) {
    vendorUpdator.mutate(
      {
        id: item.id,
        body: { name: item.name, phone: item.phone },
        key: ["vendors", { page, limit, sortBy, search }],
      },
      {
        onError: alertPage.error(`Unable to update vendor: ${item.name}`),
        onSuccess: alertPage.success(`Vendor: ${item.name} Updated`),
      }
    );
  }
  /* Submit Function */
  async function handleSubmit() {
    try {
      if (!formData.hasOwnProperty("phone") || formData.phone === "") {
        formData.phone = "0000000000";
      }

      const value = await vendorValidation.newVendor.validateAsync(formData);

      if (value.hasOwnProperty("id")) {
        /* Edit */
        handleUpdate(value);
      } else {
        /* New */
        await vendorService.createVendor(value);
      }
      queryClient.invalidateQueries([
        "vendors",
        { page, limit, sortBy, search },
      ]);
      setIsOpen();
    } catch (ex) {
      // Validation error
      if (ex.isJoi) {
        return setFormError(ex.details[0]);
      }
      // Api response error
      if (ex.response) {
        return alertPage.error(ex.response.data.message);
      }
      // Critical error
      console.error(ex);
      return alertPage.error("Connection error - please try again later");
    }
  }
  /* Edit Function */
  function handleEdit(item) {
    const { id, name, phone } = item;
    setFormData({ id, name, phone });
  }
  /* Open modal when formdata */
  useEffect(() => {
    if (formData) {
      setIsOpen(true);
    }
  }, [formData]);

  return (
    <>
      <PageAlert alertProps={alertProps} />
      <Container fluid className="main-content-container px-4">
        <PlainHeader
          title={"Vendors"}
          subtitle={"Agent"}
          pageInfo="Add new Vendors"
        />
        <Row>
          <Col md="4">
            <DataTableCard
              columns={VendorListColumns(
                () => {},
                (item) => {
                  /* Vendor Edit */
                  handleEdit(item);
                },
                (item) => {
                  /* Vendor Delete */
                  handleDelete(item);
                }
              )}
              search={search}
              data={data}
              status={status}
              search={search}
              setSearch={setSearch}
              status={status}
              limit={limit}
              setLimit={setLimit}
              setPage={setPage}
              setSortBy={setSortBy}
              setPreFetchId={() => {}}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              handleSubmit={handleSubmit}
            >
              <NewVendorForm
                formData={formData}
                setFormData={setFormData}
                formError={formError}
                setFormError={setFormError}
              />
            </DataTableCard>
          </Col>
          <Col md="5"></Col>
        </Row>
      </Container>
      <AreYouSure areYouSureProps={areYouSureProps} />
    </>
  );
};

export default Vendors;
