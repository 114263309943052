import React from "react";
import PropTypes from "prop-types";
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
  FormSelect,
  ButtonGroup,
  Button,
} from "shards-react";
import moment from "moment";

const ApiKeysTable = ({
  title,
  apiKeysTableData,
  handleEdit,
  handleDelete,
  handleShowKey,
  handleCopy,
  displayKey,
}) => {
  return (
    <Card small className="lo-stats h-100">
      <CardHeader className="border-bottom">
        <h6 className="m-0">{title}</h6>
        <div className="block-handle" />
      </CardHeader>

      <CardBody className="p-0">
        <Container fluid className="px-0">
          <table className="table mb-0">
            <thead className="py-2 bg-light text-semibold border-bottom">
              <tr>
                <th>Details</th>
                <th className="text-center">Status</th>
                <th className="text-center">Access</th>
                <th className="text-center">Last Request</th>
                <th className="text-center">User</th>
                <th className="text-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              {apiKeysTableData.map((item, idx) => (
                <tr key={idx}>
                  <td className="lo-stats__order-details">
                    <span>{item.name}</span>
                    <span>{item.createdAt}</span>
                  </td>
                  <td className="lo-stats__status">
                    <div className="d-table mx-auto">
                      <Badge pill theme={getBadgeType(item.status)}>
                        {item.status}
                      </Badge>
                    </div>
                  </td>
                  <td className="lo-stats__items text-center">{item.access}</td>
                  <td
                    className={`lo-stats__total text-center ${item.lastRequestClass}`}
                  >
                    {item.lastRequest}
                  </td>
                  <td className="lo-stats__items text-center">{item.user}</td>
                  <td className="lo-stats__actions">
                    <ButtonGroup className="d-table ml-auto">
                      <Button
                        theme="white"
                        onClick={() => {
                          handleShowKey(item);
                        }}
                      >
                        <i className="material-icons">vpn_key</i>
                      </Button>
                      <Button
                        theme="white"
                        onClick={() => {
                          handleEdit(item);
                        }}
                      >
                        <i className="material-icons">&#xE254;</i>
                      </Button>
                      <Button
                        theme="white"
                        onClick={() => {
                          handleDelete(item);
                        }}
                      >
                        <i className="material-icons">&#xE872;</i>
                      </Button>
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Container>
      </CardBody>

      <CardFooter className="border-top">
        <Row>
          {/* Time Span */}
          <Col>
            <FormSelect
              size="sm"
              value="last-week"
              style={{ maxWidth: "130px" }}
              onChange={() => {}}
            >
              <option value="last-week">Last Week</option>
              <option value="today">Today</option>
              <option value="last-month">Last Month</option>
              <option value="last-year">Last Year</option>
            </FormSelect>
          </Col>

          {/* View Full Report */}
          <Col className="text-right text-nowrap">
            {/* eslint-disable-next-line */}
            {displayKey && (
              <>
                <Button
                  theme="white"
                  className="mr-2"
                  size="sm"
                  onClick={() =>
                    //navigator.clipboard.writeText(displayKey.token);
                    handleCopy()
                  }
                >
                  {`Copy ${displayKey.name} API key`}
                </Button>
                <span>{displayKey && displayKey.token}</span>
              </>
            )}
          </Col>
        </Row>
      </CardFooter>
    </Card>
  );
};

/**
 * Returns the badge type for a specific
 */
function getBadgeType(itemStatus) {
  const statusMap = {
    active: "success",
    Pending: "warning",
    suspended: "danger",
  };

  return statusMap[itemStatus];
}

function getColorByDate(colorDate) {
  const momentColorDate = moment(colorDate);
  const momentNow = moment();

  if (momentColorDate.isSame(momentNow, "day")) {
    return "text-success";
  } else if (momentColorDate.isSame(momentNow, "week")) {
    return "text-warning";
  } else {
    return "text-danger";
  }
}

ApiKeysTable.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,

  /**
   * The latest orders data.
   */
  apiKeysTableData: PropTypes.array,
};

ApiKeysTable.defaultProps = {
  title: "Latest Orders",
  apiKeysTableData: [
    {
      id: "#19280",
      name: "Phone Server",
      createdAt: "21 February 2018 20:32",
      status: "active",
      access: "7",
      lastRequest: moment("2021-06-14").fromNow(),
      lastRequestClass: getColorByDate("2021-06-14"),
    },
    {
      id: "#19279",
      name: "Phone Server",
      createdAt: "21 February 2018 20:32",
      status: "suspended",
      access: "7",
      lastRequest: moment("2021-06-10").fromNow(),
      lastRequestClass: getColorByDate("2021-06-10"),
    },
    {
      id: "#19278",
      name: "Phone Server",
      createdAt: "21 February 2018 20:32",
      status: "active",
      access: "18",
      lastRequest: moment("2021-06-10").fromNow(),
      lastRequestClass: getColorByDate("2021-06-10"),
    },
    {
      id: "#19277",
      name: "Phone Server",
      createdAt: "21 February 2018 20:32",
      status: "Pending",
      access: "7",
      lastRequest: moment("2021-06-10").fromNow(),
      lastRequestClass: getColorByDate("2021-06-10"),
    },
  ],
};

export default ApiKeysTable;
