import React from "react";
import { Container, Row, Card, CardHeader, CardBody } from "shards-react";
import GlobalSearchBar from "../CommonPageComponents/GlobalSearchBar";
import TableDataSelect from "./TableDataSelect";

const TableHeader = ({ table, setTable, tableOptions, search, setSearch }) => {
  return (
    <CardHeader className="text-center p-0">
      <Container fluid className="file-manager__filters border-bottom">
        <Row>
          <TableDataSelect
            table={table}
            setTable={setTable}
            tableOptions={tableOptions}
          />
          <GlobalSearchBar search={search} setSearch={setSearch} />
        </Row>
      </Container>
      {/* <FileDropzone /> */}
    </CardHeader>
  );
};

export default TableHeader;
