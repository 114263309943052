import clientService from "../services/client.service";
import userService from "../services/user.service";
import { MutationOption, QueryFunction } from "../component_modules/QuerySetup/QuerySetup";

/** Creat AdminManager defaults
 * 
 * 
 * @param {object} queryClient 
 * @returns queryClient
 */
export const setAdminManagerQueryDefaults = (queryClient) => {
    /**
     * Create Optimistic Update default Mutation loader
     * 
     * @param queryClient
     * @param param0 - Options & defaults
     * @param param0.DEBUG 
     * 
     * @returns {MutationOption} 
     */
    const optimistic = new MutationOption(queryClient, { DEBUG: false });

    optimistic.mutationFn = (variables) => clientService.updateClient(variables.id, variables.body);
    queryClient.setMutationDefaults("updateClient", optimistic.objWithArrayProp("results").update);


    optimistic.mutationFn = (variables) => clientService.addFreeText(variables.id, variables.params);
    queryClient.setMutationDefaults("addFreeText", optimistic.item().update);


    optimistic.mutationFn = (variables) => clientService.deleteClient(variables.id);
    queryClient.setMutationDefaults("deleteClient", optimistic.objWithArrayProp("results").delete);

    optimistic.mutationFn = (variables) => userService.updateUser(variables.id, variables.body);
    queryClient.setMutationDefaults("updateUser", optimistic.objWithArrayProp("results").update);


    optimistic.mutationFn = (variables) => userService.deleteUser(variables.id);
    queryClient.setMutationDefaults("deleteUser", optimistic.objWithArrayProp("results").delete);

    optimistic.mutationFn = (variables) => userService.createUser(variables.body);
    queryClient.setMutationDefaults("createUser", optimistic.objWithArrayProp("results").add);


    /** QUERY FUNCTIONS */
    const queryFunctions = {
        "clients": async (queryKey) => {
            const [_key, { page, limit, sortBy, search, start, end }] = queryKey;
            const { data } = await clientService.getAllClients({ page, limit, sortBy, search, start, end })
            return data;
        },
        "client": async (queryKey) => {
            const [_key, _id] = queryKey;
            if (_id) {
                const { data } = await clientService.getClient(_id)
                return data;
            }
        },
        "users": async (queryKey) => {
            const [_key, { page, limit, sortBy, search, start, end }] = queryKey;
            const { data } = await userService.getAllUsers({
                page,
                limit,
                sortBy,
                search,
                start,
                end,
            });
            return data;
        },
        "user": async (queryKey) => {
            const [_key, _id] = queryKey;
            const { data } = await userService.getUser(_id);
            return data;
        },
    }


    /** Create Default Function */
    const queryDefault = new QueryFunction(queryFunctions).defaultQueryFn;


    queryClient.setDefaultOptions({
        queries: {
            queryFn: queryDefault,
            staleTime: 10 * 1000,
        },
    })


    return queryClient;
}