import React from "react";
import { useHistory, Link, useParams } from "react-router-dom";
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
  FormSelect,
  ButtonGroup,
  Button,
} from "shards-react";
import moment from "moment";

const PayoutsTable = ({ title, data, handleViewItems, handleSaveItems }) => {
  const { client, bookingId } = useParams();

  return (
    <Card small className="payout-stats h-100">
      <CardHeader className="border-bottom">
        <h6 className="m-0">{title}</h6>
        <div className="block-handle" />
      </CardHeader>

      <CardBody className="p-0">
        <Container fluid className="px-0">
          <table className="table mb-0">
            <thead className="py-2 bg-light text-semibold border-bottom">
              <tr>
                <th>Details</th>
                <th className="text-center">Items</th>
                <th className="text-center">Packaged</th>
                <th className="text-center">Total</th>
                <th className="text-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item, idx) => (
                  <tr key={idx}>
                    <td className="lo-stats__order-details">
                      <span>{item.vendor.name}</span>
                      <span>
                        {moment.utc(item.bookingDate).format("DD of MMM YYYY")}
                      </span>
                    </td>

                    <td className="lo-stats__items text-center">
                      {item.count}
                    </td>
                    <td className="lo-stats__items text-center">
                      {item.packages}
                    </td>
                    <td className="lo-stats__total text-center text-success">
                      ${item.price}
                    </td>

                    <td className="lo-stats__actions">
                      <ButtonGroup className="d-table ml-auto">
                        <Button
                          size="sm"
                          theme="white"
                          onClick={() => handleViewItems(item)}
                        >
                          View Items
                        </Button>
                        <Button
                          size="sm"
                          theme="white"
                          onClick={() => handleSaveItems(item)}
                        >
                          Invoice
                        </Button>
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Container>
      </CardBody>

      <CardFooter className="border-top">
        <Row>
          <Col></Col>

          <Col className="text-right view-report">
            <Link to={`/clients/${client}/bookings/${bookingId}`}>
              Go back to View &rarr;
            </Link>
          </Col>
        </Row>
      </CardFooter>
    </Card>
  );
};

function getBadgeType(itemStatus) {
  const statusMap = {
    Complete: "success",
    Pending: "warning",
    Canceled: "danger",
  };

  return statusMap[itemStatus];
}

export default PayoutsTable;
