import uploadService from "../services/upload.service";
import { MutationOption, QueryFunction } from "../component_modules/QuerySetup/QuerySetup";


/** Create uploads defaults
 * Creat uploads defaults
 * 
 * @param {object} queryClient 
 * @returns queryClient
 */
export const setUploadQueryDefaults = (queryClient) => {

    /** Create Optimistic Update default Mutation loader
     * Create Optimistic Update default Mutation loader
     * 
     * @param queryClient
     * @param param0 - Options & defaults
     * @param param0.DEBUG 
     * 
     * @returns {MutationOption} 
     */
    const optimistic = new MutationOption(queryClient, { DEBUG: true });


    /** DELETE - Mutation "deleteAdminUploadsPagination"
     * DELETE - Mutation "deleteAdminUploadsPagination"
     * 
     * @param {variables} - object passed to - 'mutate(variables)'
     * @param {variables.id} ObjectId of upload to delete
     *  ex:
     *      let { mutate } = useMutation("deleteAdminUploadsPagination")
     *      mutate(variables)
     */
    optimistic.mutationFn = (variables) => uploadService.deleteUpload(variables.id);

    queryClient.setMutationDefaults("deleteAdminUploadsPagination", optimistic.objWithArrayProp("results").delete);


    /** UPDATE - Mutation "updateUploadsPagination"
     * UPDATE - Mutation "updateUploadsPagination"
     * 
     * @param {variables.id} ObjectId of upload
     * @param {variables.body} object - updated document
     */
    optimistic.mutationFn = (mutateParams) => uploadService.updateUpload(mutateParams.id, mutateParams.body);

    queryClient.setMutationDefaults("updateUploadsPagination", optimistic.objWithArrayProp("results").update);


    /** UPDATE - Mutation "updateUploadsArray"
     * 
     * 
     * @param {variables.userId} ObjectId of upload
     * @param {variables.uploadId} ObjectId of upload
     * @param {variables.params} object - updated document
     */
    optimistic.mutationFn = (variables) => uploadService.updateDirectoryUpload(variables.userId, variables.uploadId, variables.params)

    queryClient.setMutationDefaults("updateUploadsArray", optimistic.array().update);


    /** DELETE - Mutation "deleteUploadsArray"
     * 
     * 
     * @param {variables.id} ObjectId of upload
     */
    optimistic.mutationFn = (variables) => uploadService.deleteUpload(variables.id);

    queryClient.setMutationDefaults("deleteUploadsArray", optimistic.array().delete);


    /** DELETE - Mutation "moveUploadsArray"
     * 
     * 
     * @param {variables.userId} ObjectId of upload
     * @param {variables.uploadId} ObjectId of upload
     * @param {variables.params} object - updated document
     */
    optimistic.mutationFn = (variables) => uploadService.updateDirectoryUpload(variables.userId, variables.uploadId, variables.params)

    queryClient.setMutationDefaults("moveUploadsArray", optimistic.array().delete);



    /** QUERY FUNCTIONS */
    const queryFunctions = {
        "files": async (queryKey) => {
            const [_key, _id, { page, limit, sortBy, search }] = queryKey;
            // if (_id) {
            const { data } = await uploadService.getAllDirectoryUploads(_id, { page, limit, sortBy, search });
            return data;
            // } else return [];
        },
        "file": async (queryKey) => {
            const [_key, _id] = queryKey;
            const { data } = await uploadService.getUpload(_id);
            return data;
        },
        "uploadCards": async (queryKey) => {
            const [_key, { userId, dir, search }] = queryKey;
            if (dir) {
                const { data } = await uploadService.getDirectoryUploads(userId, { dir, search });
                return data;
            } else return [];
        },
        "uploads": async (queryKey) => {
            const [_key, { page, limit, sortBy, search }] = queryKey;
            const { data } = await uploadService.getAllUploads({ page, limit, sortBy, search });
            return data;
        },
        "upload": async (queryKey) => {
            const [_key, _id] = queryKey;
            const { data } = await uploadService.getUpload(_id);
            return data;
        }
    }


    /** Create Default Function */
    const queryDefault = new QueryFunction(queryFunctions).defaultQueryFn;


    queryClient.setDefaultOptions({
        queries: {
            queryFn: queryDefault,
            staleTime: 10 * 1000,
        },
    })

    return queryClient;
}











