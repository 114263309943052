import React, { useState } from "react";
import { Row, Col } from "shards-react";
import PageTitle from "../components/common/PageTitle";

const PlainHeader = ({ title, subtitle, pageInfo }) => {
  return (
    <Row noGutters className="page-header py-4">
      {/* Page Header :: Title */}
      <PageTitle
        title={title}
        subtitle={subtitle}
        className="text-sm-left mb-3"
      />
      {/* Page Header :: Actions */}
      <Col xs="12" sm="4" className="col d-flex align-items-center" />

      {/* Page Header :: Datepicker */}
      <Col sm="4" className="d-flex"></Col>
      <Col className="text-right">
        <span className="stats-small__label ">{pageInfo}</span>
      </Col>
    </Row>
  );
};

export default PlainHeader;
