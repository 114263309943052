import React from "react";
import DataTable from "../../common/DataTable/DataTable";
import BookingListColumns from "./BookingListColumns";
import { useHistory } from "react-router-dom";

const BookingListDataTable = ({
  data,
  status,
  limit,
  setPage,
  setLimit,
  setSortBy,
  setPreFetchId,
  handleDelete,
}) => {
  let history = useHistory();

  return (
    <>
      <DataTable
        columns={BookingListColumns(
          (row) => {
            const client = row.clientId;
            const booking = row.id;
            history.push(`/clients/${client}/bookings/${booking}`);
          },
          () => {},
          (row) => {
            handleDelete(row);
          }
        )}
        data={(data && data.results) || []}
        pages={data && data.totalPages}
        loading={status === "loading"}
        pageSize={limit}
        setPage={setPage}
        setLimit={setLimit}
        setSortBy={setSortBy}
        prefetchItem={setPreFetchId}
      />
    </>
  );
};

export default BookingListDataTable;
