import React, { useState } from "react";
import { Row, Col, ButtonGroup, Button } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { NavLink } from "react-router-dom";
import capitalize from "../utils/capitalize";

const pageDescription = (page) => {
  if (page === "edit") return "Edit Client Information";
  if (page === "users") return "Client User Accounts";

  return "Client Profile";
};

const ProfileHeader = ({ title, subtitle, id, page }) => {
  return (
    <Row noGutters className="page-header py-4 rowFlex">
      {/* Page Header :: Title */}
      <PageTitle
        title={title && capitalize(title)}
        subtitle={subtitle}
        className="text-sm-left mb-3"
      />
      {/* Page Header :: Actions */}
      <Col xs="12" sm="4" className="col d-flex align-items-center"></Col>

      {/* Page Header :: Datepicker */}
      <Col sm="4" className="d-flex align-items-center">
        <ButtonGroup size="sm" className="d-inline-flex mb-3 mb-sm-0 mx-auto">
          <Button theme="white" tag={NavLink} exact to={"/clients/" + id}>
            Profile
          </Button>
          <Button theme="white" tag={NavLink} to={"/clients/" + id + "/edit"}>
            Edit
          </Button>
          <Button
            theme="white"
            tag={NavLink}
            to={"/clients/" + id + "/bookings"}
          >
            Bookings
          </Button>
        </ButtonGroup>
      </Col>
      <Col className="text-center">
        <span className="stats-small__label ">{pageDescription(page)}</span>
      </Col>
    </Row>
  );
};

export default ProfileHeader;
