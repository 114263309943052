import React from "react";
import { BehaviorSubject, from } from "rxjs";
import {
  mergeMap,
  filter,
  debounceTime,
  distinctUntilChanged,
} from "rxjs/operators";
import { FormInput, ListGroup, ListGroupItem } from "shards-react";
import { getAirportsAutoComplete } from "../../services/airport.service";

let searchSubject = new BehaviorSubject("");

let searchResultObservables = searchSubject.pipe(
  filter((val) => val.length > 0),
  debounceTime(750),
  distinctUntilChanged(),
  mergeMap((val) => from(getAirportsAutoComplete(val)))
);

const useObservable = (observable, setter) => {
  React.useEffect(() => {
    let subscription = observable.subscribe((result) => {
      setter(result.data);
    });
    return () => subscription.unsubscribe();
  }, [observable, setter]);
};

const AirportAutoCompleteInput = ({ value, onChange, placeholder }) => {
  const [isEdit, setIsEdit] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [results, setResults] = React.useState([]);
  const [showOptions, setShowOptions] = React.useState(false);
  const [activeOption, setActiveOption] = React.useState(0);
  const inputSelectRef = React.useRef();
  useObservable(searchResultObservables, setResults);

  React.useEffect(() => {
    if (isEdit) {
      inputSelectRef.current.focus();
    }
  }, [isEdit]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setSearch(newValue);
    if (newValue) {
      setShowOptions(true);
      searchSubject.next(newValue ? newValue : " ");
    } else {
      setShowOptions(false);
    }
  };

  const handleOnClick = (e) => {
    const changeEvent = {
      target: {
        value: e.currentTarget.id,
      },
    };
    onChange(changeEvent);
    setSearch("");
    setShowOptions(false);
    setIsEdit(false);
  };

  function handleOnKeyDown(e) {
    if (e.keyCode === 13) {
      const changeEvent = {
        target: {
          value: results[activeOption].iata,
        },
      };
      setActiveOption(0);
      onChange(changeEvent);
      setSearch("");
      setShowOptions(false);
      setIsEdit(false);
    } else if (e.keyCode === 38) {
      if (activeOption === 0) {
        return;
      }
      setActiveOption((activeOption) => activeOption - 1);
    } else if (e.keyCode === 40) {
      if (activeOption === results.length - 1) {
        return;
      }
      setActiveOption((activeOption) => activeOption + 1);
    } else if (e.keyCode === 9) {
      onChange({
        target: {
          value: "",
        },
      });
      setSearch("");
      setShowOptions(false);
      setIsEdit(false);
    }
  }

  return (
    <div className="">
      {!isEdit && (
        <FormInput
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          onClick={() => setIsEdit(true)}
        />
      )}
      {isEdit && (
        <FormInput
          innerRef={inputSelectRef}
          onChange={handleChange}
          value={search}
          placeholder={placeholder}
          onKeyDown={handleOnKeyDown}
        />
      )}
      <ListGroup small>
        {showOptions &&
          [...results].map((airport, index) => (
            <ListGroupItem
              id={airport.iata}
              key={airport.iata}
              onClick={handleOnClick}
              active={index === activeOption}
            >{`${airport.name}, ${airport.city} (${airport.iata})`}</ListGroupItem>
          ))}
      </ListGroup>
    </div>
  );
};

export default AirportAutoCompleteInput;
