import React from "react";
import TableHeader from "./components/TableHeader";
import DataTable from "./components/DataTable";
import { Card, CardBody } from "shards-react";
import { Modal, ModalBody, Button, ModalFooter } from "shards-react";

const DataTableCard = ({
  search,
  setSearch,
  columns,
  data,
  status,
  limit,
  setLimit,
  setPage,
  setSortBy,
  setPreFetchId,
  handleSubmit,
  isOpen,
  setIsOpen,
  children,
}) => {
  return (
    <>
      <Card className="p-0 mb-4">
        <TableHeader
          setIsOpen={setIsOpen}
          search={search}
          setSearch={setSearch}
        />
        <CardBody className="p-0">
          <DataTable
            columns={columns}
            data={(data && data.results) || []}
            pages={data && data.totalPages}
            loading={status === "loading"}
            pageSize={limit}
            setPage={setPage}
            setLimit={setLimit}
            setSortBy={setSortBy}
            prefetchItem={setPreFetchId}
          />
        </CardBody>
      </Card>
      <Modal open={isOpen} toggle={() => setIsOpen(!isOpen)}>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <Button className="btn-accent" onClick={() => handleSubmit()}>
            Save
          </Button>
          <Button className="btn-accent" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DataTableCard;
