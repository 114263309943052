import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Button,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormGroup,
  FormSelect,
} from "shards-react";
import StatusSelect from "../../components/Clients/common/StatusSelect";
import StateList from "../../custom/StateList";
import FormCardHeader from "./FormCardHeader";

const FormCard = ({ title, handleSubmit, noFooter, children }) => {
  return (
    <Card className="p-0 mb-4 h-100">
      <FormCardHeader title={title} />
      <CardBody className="p-0">
        <ListGroup flush>
          <ListGroupItem className="p-3">
            <Row>
              <Col>
                <div>{children}</div>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </CardBody>

      <CardFooter>
        {!noFooter && (
          <Button
            theme="accent"
            type="submit"
            disabled={false}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        )}
      </CardFooter>
    </Card>
  );
};

export default FormCard;
