import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  FormInput,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  FormSelect,
  FormFeedback,
} from "shards-react";
import moment from "moment";

const NewBookingModal = ({
  isOpen,
  setIsOpen,
  booking,
  setBooking,
  agents,
  handleSubmit,
  formError,
}) => {
  return (
    <Modal open={isOpen} toggle={() => setIsOpen(!isOpen)}>
      <ModalBody>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Row form>
            <Col md="6">
              <label htmlFor="feTravelDate">Travel Date</label>
              <FormInput
                id="feTravelDate"
                type="date"
                placeholder="Travel"
                name="travelDate"
                value={moment.utc(booking.travelDate).format("YYYY-MM-DD")}
                onChange={(e) => {
                  setBooking({
                    ...booking,
                    travelDate: e.target.value,
                  });
                }}
                invalid={formError && formError.path === "travelDate"}
              />
              <FormFeedback>{formError && formError.message}</FormFeedback>
            </Col>
            <Col md="6" className="form-group">
              <label htmlFor="feBookingName">Name</label>
              <FormInput
                id="feBookingName"
                placeholder="Booking Name"
                value={booking.name}
                name="name"
                onChange={(e) => {
                  setBooking({
                    ...booking,
                    name: e.target.value,
                  });
                }}
                invalid={formError && formError.path === "name"}
              />
              <FormFeedback>{formError && formError.message}</FormFeedback>
            </Col>
          </Row>

          <FormGroup>
            <label htmlFor="feAgent">Agent</label>
            <FormSelect
              id="feAgent"
              value={booking._agent}
              onChange={(e) => {
                setBooking({
                  ...booking,
                  _agent: e.target.value,
                  agentId: e.target.value,
                });
              }}
            >
              {agents.map((agent) => {
                return (
                  <option key={agent._id} value={agent._id}>
                    {`${agent.lastName}, ${agent.firstName}`}
                  </option>
                );
              })}
            </FormSelect>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button className="btn-accent" onClick={() => handleSubmit()}>
          Save
        </Button>
        <Button className="btn-accent" onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default NewBookingModal;
