import React from "react";

export function useAlert() {
  const [isVissable, setIsVissable] = React.useState(false);
  const [alertData, setAlertData] = React.useState();

  function pageAlert({ theme, message }) {
    setIsVissable(false);
    setAlertData({
      message: message,
      theme: theme,
    });
  }

  React.useEffect(() => {
    setAlertData();
  }, []);

  React.useEffect(() => {
    if (alertData) setIsVissable(true);
  }, [alertData]);

  React.useEffect(() => {
    if (isVissable) {
      let timer1 = setTimeout(() => setIsVissable(false), 5 * 1000);

      return () => {
        clearTimeout(timer1);
      };
    }
  }, [isVissable]);

  const alertPage = {
    success: function (message) {
      pageAlert({ theme: "success", message });
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    },
    error: function (message) {
      pageAlert({ theme: "danger", message });
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    },
  };

  return {
    pageAlert,
    alertPage,
    alertProps: { isVissable, setIsVissable, alertData },
  };
}
