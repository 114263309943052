import React from "react";
import { io } from "socket.io-client";

/**
 *
 * @param {socket} socket
 * @param {object} token
 * @returns {socket}
 */
export const createSocket = (socket, token) => {
  if (socket) return socket;

  if (token) {
    return io({
      auth: {
        token,
      },
    });
  }
};

export const SocketContext = React.createContext();
