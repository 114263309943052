import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
} from "shards-react";
import formatPhoneNumber from "../../../utils/formatPhoneNumber";
import capitalize from "../../../utils/capitalize";
import capitalizeArray from "../../../utils/capitalizeArray";

const ClientInfo = ({ client }) => {
  return (
    <Card className="p-0 mb-4 h-100">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Client Info</h6>
        <div className="block-handle" />
      </CardHeader>
      <CardBody className="py-0">
        <ListGroup flush>
          <ListGroupItem className="d-flex row px-0">
            <Col className="col-4" lg="4" md="4" sm="4">
              <h6 className="go-stats__label mb-1">Name(s)</h6>
              <div className="go-stats__meta">
                <Row>
                  <Col>
                    {client && (
                      <span className="mr-2">
                        <strong>1. </strong>
                        {`${capitalize(client.primary.firstName)} ${capitalize(
                          client.primary.lastName
                        )}`}
                      </span>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {client && client.secondary.firstName2 !== "n/a" && (
                      <span className="mr-2">
                        <strong>2.</strong>
                        {` ${capitalize(
                          client.secondary.firstName2
                        )} ${capitalize(client.secondary.lastName2)}`}
                      </span>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg="8" md="8" sm="8" className="d-flex col-8">
              <div className="go-stats__value text-right ml-auto">
                <h6 className="go-stats__label mb-1">Phone</h6>
                <span className="go-stats__meta">
                  {client && formatPhoneNumber(client.phone)}
                </span>
              </div>
              <div className="go-stats__value text-right ml-auto">
                <h6 className="go-stats__label mb-1">Cell</h6>
                <span className="go-stats__meta">
                  {client && formatPhoneNumber(client.cell)}
                </span>
              </div>
              <div className="go-stats__chart d-flex ml-auto"></div>
            </Col>
          </ListGroupItem>
          <ListGroupItem className="d-flex row px-0">
            <Col className="col-12">
              <h6 className="go-stats__label mb-1">Email</h6>
              <div className="go-stats__meta">
                <Row>
                  <Col>
                    <span className="mr-2">{` ${client && client.email}`}</span>
                  </Col>
                </Row>
              </div>
            </Col>
          </ListGroupItem>
          <ListGroupItem className="d-flex row px-0">
            <Col className="col-12">
              <h6 className="go-stats__label mb-1">Address</h6>
              <div className="go-stats__meta">
                <Row>
                  <Col>
                    <span className="mr-2">{` ${
                      client && capitalizeArray(client.address.address)
                    }`}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="mr-2">{` ${
                      client && capitalizeArray(client.address.address2)
                    }`}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="mr-2">{`${
                      client && capitalizeArray(client.address.city)
                    }, ${client && client.address.state} ${
                      client && client.address.zip
                    }`}</span>
                  </Col>
                </Row>
              </div>
            </Col>
          </ListGroupItem>
        </ListGroup>
      </CardBody>
    </Card>
  );
};

export default ClientInfo;
