import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { Card } from "shards-react";
import DropzonePreview from "./DropzonePreview";
import MetaActions from "./MetaActions";

const DropZone = ({
  files,
  setFiles,
  sendUploads,
  selectedFile,
  setSelectedFile,
  deleteSelectedFile,
  editFile,
  search,
  setSearch,
}) => {
  const queryClient = useQueryClient();

  // Select All to Upload
  const selectAll = () => {
    setFiles(
      files.map((file, id) => {
        return Object.assign(file, {
          selected: true,
        });
      })
    );
  };

  // Deselect All
  const deselectAll = () => {
    setFiles(
      files.map((file, id) => {
        return Object.assign(file, {
          selected: false,
        });
      })
    );
  };

  // Clear All
  const clearAll = () => {
    setFiles([]);
  };

  // Delete File

  // Edit File
  const editSelectedFile = async () => {
    editFile(selectedFile);
  };

  const isUploadFiles = files.length > 0;
  const isSelectedFile = !!selectedFile;
  const isSelectedUploadFiles = files.find((file) => file.selected);
  const isSearchable = !selectedFile;

  return (
    <Card small className="mb-3">
      {/* Dropzone */}
      <DropzonePreview setFiles={setFiles} />
      {/* Meta Actions */}
      <MetaActions
        files={files}
        sendUploads={sendUploads}
        selectAll={selectAll}
        deselectAll={deselectAll}
        clearAll={clearAll}
        deleteSelectedFile={deleteSelectedFile}
        editSelectedFile={editSelectedFile}
        isUploadFiles={isUploadFiles}
        isSelectedFile={isSelectedFile}
        isSelectedUploadFiles={isSelectedUploadFiles}
        isSearchable={isSearchable}
        search={search}
        setSearch={setSearch}
      />
    </Card>
  );
};

export default DropZone;
