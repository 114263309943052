import React from "react";

import { Col, FormSelect } from "shards-react";

const PageSizeSelect = ({ limit, setLimit, pageSizeOptions }) => {
  return (
    <Col className="file-manager__filters__rows d-flex">
      <span>Show</span>
      <FormSelect
        size="sm"
        onChange={(e) => setLimit(e.target.value)}
        value={limit}
      >
        {pageSizeOptions.map((size, idx) => (
          <option key={idx} value={size}>
            {size} rows
          </option>
        ))}
      </FormSelect>
    </Col>
  );
};

export default PageSizeSelect;
