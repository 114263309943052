import React from "react";
import {
  Row,
  Col,
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
  ButtonGroup,
  Button,
} from "shards-react";

const MetaActions = ({
  sendUploads,
  selectAll,
  clearAll,
  deselectAll,
  deleteSelectedFile,
  editSelectedFile,
  isUploadFiles,
  isSelectedFile,
  isSelectedUploadFiles,
  isSearchable,
  search,
  setSearch,
}) => (
  <Row noGutters className="p-2">
    <Col lg="3" className="mb-2 mb-lg-0">
      <Form action="POST">
        {isSearchable && (
          <InputGroup seamless size="sm">
            <InputGroupAddon type="prepend">
              <InputGroupText>
                <i className="material-icons">search</i>
              </InputGroupText>
            </InputGroupAddon>
            <FormInput
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </InputGroup>
        )}
      </Form>
    </Col>
    <Col>
      <div className="d-flex ml-lg-auto my-auto">
        <ButtonGroup size="sm" className="mr-2 ml-lg-auto">
          {isUploadFiles && (
            <React.Fragment>
              <Button theme="white" onClick={() => selectAll()}>
                <i className="material-icons">&#xE877;</i>
              </Button>
              <Button theme="white" onClick={() => deselectAll()}>
                <i className="material-icons">&#xE9D3;</i>
              </Button>
              <Button theme="white" onClick={() => clearAll()}>
                <i className="material-icons">&#xE14C;</i>
              </Button>
            </React.Fragment>
          )}

          {isSelectedFile && (
            <React.Fragment>
              <Button theme="white" onClick={() => editSelectedFile()}>
                <i className="material-icons">&#xE254;</i>
              </Button>
              <Button theme="white" onClick={() => deleteSelectedFile()}>
                <i className="material-icons">&#xE872;</i>
              </Button>
            </React.Fragment>
          )}
        </ButtonGroup>
        {isSelectedUploadFiles && (
          <Button
            size="sm"
            theme="accent"
            className="d-inline-block ml-auto ml-lg-0"
            onClick={sendUploads}
          >
            <i className="material-icons">&#xE145;</i> Upload Selected Documents
          </Button>
        )}
      </div>
    </Col>
  </Row>
);

export default MetaActions;
