import React, { useEffect, useState } from "react";
import { Container } from "shards-react";
import PageAlert, { usePageAlert } from "../common/Alert/PageAlert";
import AreYouSure, { useAreYouSure } from "../common/AreYouSure/AreYouSure";
// Query Hooks
import { downloadFile } from "../services/downloader.service";
// Hooks
import { useSearchBar } from "../custom/SiteSearchBar/SiteSearchBar";
// Page componnenets
import PageHeader, { useDateChangeHandler } from "../common/PageHeader";
import EditFileModal from "../components/UploadManager/EditFileModal";
// Table
import AdminFileManager from "../component_modules/fileManager/AdminFileManager";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { setUploadQueryDefaults } from "../query/upload.query";
/**
 *
 * @returns {React View Component}
 */
const UploadManager = () => {
  const queryClient = useQueryClient();
  setUploadQueryDefaults(queryClient);
  const { alertPage, alertProps } = usePageAlert();
  const { areYouSureProps, setDeleteHandler } = useAreYouSure();

  const mutation = useMutation("updateUploadsPagination");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [sortBy, setSortBy] = useState("fileName:asc");
  const { search, setSearch } = useSearchBar();
  const [start, handleStartChange, end, handleEndChange] =
    useDateChangeHandler();

  // Query
  const [tableData, setTableData] = useState([]);
  const [pages, setPages] = useState(1);

  const { data } = useQuery(["uploads", { page, limit, sortBy, search }]);

  useEffect(() => {
    if (data) {
      setTableData(data.results);
      setPages(data.totalPages);
    }
  }, [data]);

  useEffect(() => {
    if (data && page + 1 < data.totalPages)
      queryClient.prefetchQuery(
        ["uploads", { page: page + 1, limit, sortBy, search }],
        { staleTime: 10 * 1000 }
      );
  }, [page, data, queryClient]);

  useEffect(() => {
    if (data && page > 0)
      queryClient.prefetchQuery(
        ["uploads", { page: page - 1, limit, sortBy, search }],
        { staleTime: 10 * 1000 }
      );
  }, [page, data, queryClient]);

  /**
   * Edit Functions
   */
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [updated, setUpdated] = useState();
  useEffect(() => {
    if (updated) setEditIsOpen(true);
  }, [updated]);
  const [formError, setFormError] = useState();

  /* On Close Modal clear formData & selectedId */
  useEffect(() => {
    if (!editIsOpen) {
      setFormError();
      setUpdated();
    }
  }, [editIsOpen]);

  /* Move back a page when no results in page data */
  useEffect(() => {
    if (data) {
      if (!data.results[0] && page > data.totalPages && page !== 1) {
        setPage((page) => page - 1);
      }
    }
  }, [data]);

  /**
   * Button handlers
   */
  const handleDownloadButton = (row) => {
    downloadFile(row.user.id, row.id, row.originalname);
  };
  const handleSubmit = () => {
    const { originalname, folder } = updated;
    mutation.mutate({
      id: updated.id,
      body: { originalname, folder },
      key: ["uploads", { page, limit, sortBy, search }],
    });

    setEditIsOpen(false);
  };

  return (
    <>
      <PageAlert alertProps={alertProps} />
      <Container fluid className="main-content-container px-4 pb-4">
        <PageHeader
          title="Uploads"
          subtitle="File Manager"
          dateStart={start}
          dateEnd={end}
          handleStartChange={handleStartChange}
          handleEndChange={handleEndChange}
        />
        <AdminFileManager
          limit={limit}
          setLimit={setLimit}
          search={search}
          setSearch={setSearch}
          data={tableData}
          pages={pages}
          status={status}
          setSortBy={setSortBy}
          setPage={setPage}
          page={page}
          sortBy={sortBy}
          handleFileEdit={setUpdated}
          handleDownloadButton={handleDownloadButton}
          alertPage={alertPage}
          setDeleteHandler={setDeleteHandler}
        />
        <EditFileModal
          isOpen={editIsOpen}
          setIsOpen={setEditIsOpen}
          updated={updated}
          setUpdated={setUpdated}
          handleSubmit={handleSubmit}
          error={formError}
        />
      </Container>
      <AreYouSure areYouSureProps={areYouSureProps} size="sm" />
    </>
  );
};

export default UploadManager;
