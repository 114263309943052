import { ButtonGroup, Button } from "shards-react";
import moment from "moment";
import capitalizeArray from "../../utils/capitalizeArray";

export default function userColumns(editRow, deleteRow) {
  return [
    {
      Header: "id",
      accessor: "_id",
      maxWidth: 55,
      className: "text-center",
      show: false,
    },
    {
      Header: "Joined",
      accessor: "createdAt",
      Cell: (row) => (
        <span>{moment(row.original.createdAt).format("MM-DD-YYYY")}</span>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: (row) => <span>{row.original.email}</span>,
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: (row) => (
        <span>
          {capitalizeArray(row.original.lastName)},{" "}
          {capitalizeArray(row.original.firstName)}
        </span>
      ),
    },
    {
      Header: "Role",
      accessor: "role",
      minWidth: 60,
      className: "text-center",
      Cell: (row) => <span>{capitalizeArray(row.original.role)}</span>,
    },
    {
      Header: "Actions",
      accessor: "actions",
      maxWidth: 130,
      minWidth: 180,
      sortable: false,
      Cell: (row) => (
        <ButtonGroup size="sm" className="d-table mx-auto">
          <Button
            theme="white"
            onClick={() => {
              editRow(row.original);
            }}
          >
            <i className="material-icons">&#xE254;</i>
          </Button>
          <Button
            theme="white"
            onClick={() => {
              deleteRow(row.original);
            }}
          >
            <i className="material-icons">&#xE872;</i>
          </Button>
        </ButtonGroup>
      ),
    },
  ];
}
