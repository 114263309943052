import userService from "../services/user.service";
import { v4 as uuidv4 } from 'uuid';
import { MutationOption, QueryFunction } from "../component_modules/QuerySetup/QuerySetup";



export const setUserQueryDefaults = (queryClient) => {

    const optimistic = new MutationOption(queryClient, { DEBUG: true });


    optimistic.mutationFn = (variables) => userService.updateUser(variables.id, variables.user);
    queryClient.setMutationDefaults("updateUser", optimistic.objWithArrayProp("results").update);


    optimistic.mutationFn = (variables) => userService.deleteUser(variables.id);
    queryClient.setMutationDefaults("deleteUser", optimistic.objWithArrayProp("results").delete);

    optimistic.mutationFn = (variables) => userService.createUser(variables.body);
    queryClient.setMutationDefaults("createUser", optimistic.objWithArrayProp("results").add);


    /** QUERY FUNCTIONS */
    const queryFunctions = {
        "users": async (queryKey) => {
            const [_key, { page, limit, sortBy, search, start, end }] = queryKey;
            const { data } = await userService.getAllUsers({
                page,
                limit,
                sortBy,
                search,
                start,
                end,
            });
            return data;
        },
        "user": async (queryKey) => {
            const [_key, _id] = queryKey;
            const { data } = await userService.getUser(_id);
            return data;
        },

    }


    /** Create Default Function */
    const queryDefault = new QueryFunction(queryFunctions).defaultQueryFn;


    queryClient.setDefaultOptions({
        queries: {
            queryFn: queryDefault,
            staleTime: 10 * 1000,
        },
    })


    return queryClient;
}






const onMutate = (Z) => {
    return async (variables) => {
        await queryClient.cancelQueries(variables.key[0]);
        const previousData = queryClient.getQueryData(variables.key);
        queryClient.setQueryData(variables.key, (old) => {





            return paginationOptimisticUpdate(variables, old);




        });
        return { previousData };
    }
}
async (variables) => {
    await queryClient.cancelQueries(variables.key[0]);
    const previousData = queryClient.getQueryData(variables.key);
    //Optimistic Update
    queryClient.setQueryData(variables.key, (old) => {

        return paginationOptimisticUpdate = (variables, old) => {
            return {
                ...old,
                results: [
                    ...old.results.map(result => {
                        return result.id !== variables.id ? result : {
                            ...result,
                            ...variables.body,
                        }
                    })
                ]
            }
        }


    });

    return { previousData };
}

export const paginationOptimisticDelete = async (variables) => {
    await queryClient.cancelQueries(variables.key[0]);
    const previousData = queryClient.getQueryData(variables.key);
    //Optimistic Update
    queryClient.setQueryData(variables.key, (old) => {
        return {
            ...old,
            results: [
                ...old.results.filter(result => {
                    return result.id === variables.id
                })
            ]
        }
    });
    return { previousData };

}

export const paginationOptimisticAdd = async (variables) => {
    await queryClient.cancelQueries(variables.key[0]);
    const previousData = queryClient.getQueryData(variables.key);
    //Optimistic Update

    queryClient.setQueryData(variables.key, old => ({
        ...old,
        results: [...old.results, {
            id: uuidv4(),
            ...variables.body,
        }],
    }))


    return { previousData };

}



