import React from "react";
import { Row, Col, Form } from "shards-react";
import ModalFormInput from "../../common/DataTableCard/components/ModalFormInput";

const NewClientForm = ({ formData, setFormData, formError, setFormError }) => {
  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      <Row form>
        <Col className="form-group">
          <ModalFormInput
            name="firstName"
            placeholder="First"
            value={formData && formData.primary && formData.primary.firstName}
            onChange={(e) => {
              setFormError();
              setFormData((formData) => {
                if (formData && formData.hasOwnProperty("primary")) {
                  return {
                    ...formData,
                    primary: {
                      ...formData.primary,
                      firstName: e.target.value,
                    },
                  };
                }
                return {
                  ...formData,
                  primary: {
                    firstName: e.target.value,
                  },
                };
              });
            }}
            formError={formError}
          />
        </Col>
        <Col className="form-group">
          <ModalFormInput
            name="lastName"
            placeholder="Last"
            value={formData && formData.primary && formData.primary.lastName}
            onChange={(e) => {
              setFormError();
              setFormData((formData) => {
                if (formData && formData.hasOwnProperty("primary")) {
                  return {
                    ...formData,
                    primary: {
                      ...formData.primary,
                      lastName: e.target.value,
                    },
                  };
                }
                return {
                  ...formData,
                  primary: {
                    lastName: e.target.value,
                  },
                };
              });
            }}
            formError={formError}
          />
        </Col>
      </Row>
      <Row form>
        <Col className="form-group">
          <ModalFormInput
            name="phone"
            placeholder="Phone"
            value={formData && formData.phone}
            onChange={(e) => {
              setFormError();
              setFormData((formData) => {
                return {
                  ...formData,
                  phone: e.target.value,
                };
              });
            }}
            formError={formError}
          />
        </Col>
      </Row>
      <Row form>
        <Col className="form-group">
          <ModalFormInput
            name="email"
            placeholder="Email"
            value={formData && formData.email}
            onChange={(e) => {
              setFormError();
              setFormData((formData) => {
                return {
                  ...formData,
                  email: e.target.value,
                };
              });
            }}
            formError={formError}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default NewClientForm;
