import React, { useState, useEffect } from "react";
import { Row, Col, Button, Modal, ModalBody, ModalHeader } from "shards-react";

const AreYouSure = ({ areYouSureProps, size }) => {
  return (
    <Modal
      size={size ? size : "sm"}
      open={areYouSureProps.isAreYouSureOpen}
      toggle={areYouSureProps.toggleAreYouSure}
    >
      <ModalHeader>
        <Col className="text-center text-break">{`Delete ${areYouSureProps.itemToDelete}?`}</Col>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <Button theme="white" onClick={areYouSureProps.toggleAreYouSure}>
              Cancel
            </Button>
          </Col>

          <Col className="text-right view-report">
            <Button theme="danger" onClick={areYouSureProps.deleteItem}>
              Delete
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default AreYouSure;

/**
 * Custom hook for AreYouSure component
 * @returns {object} {areYouSureProps, setDeleteHandler}
 */
export function useAreYouSure() {
  const [isAreYouSureOpen, setIsAreYouSureOpen] = useState(false);
  const [deleteHandlerOptions, setDeleteHandlerOptions] = useState();

  /** toggleAreYouSure
   * Toggle state for Modal
   */
  const toggleAreYouSure = () => {
    setIsAreYouSureOpen((isAreYouSureOpen) => !isAreYouSureOpen);
  };

  /** deleteItem
   * OnClick event function to delete item
   */
  const deleteItem = () => {
    deleteHandlerOptions.deleteFn();
    setIsAreYouSureOpen(false);
  };

  /** setDeleteHandler
   * Set delete handler options
   * @param {object} param0
   * @param {function} param0.deleteFn
   * @param {string} param0.item
   */
  const setDeleteHandler = ({ deleteFn, item }) => {
    setDeleteHandlerOptions({ deleteFn, item });
  };

  useEffect(() => {
    if (deleteHandlerOptions) {
      setIsAreYouSureOpen(true);
    }
  }, [deleteHandlerOptions]);

  /**
   * Return modal props object `areYouSureProps`
   * & delete handler options setter 'setDeleteHandler'
   */
  return {
    areYouSureProps: {
      isAreYouSureOpen,
      toggleAreYouSure,
      deleteItem,
      itemToDelete: deleteHandlerOptions && deleteHandlerOptions.item,
    },
    setDeleteHandler,
  };
}
