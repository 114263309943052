import React from "react";
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Row,
  Col,
  FormSelect,
  Button,
  Form,
  FormGroup,
  FormInput,
  FormCheckbox,
} from "shards-react";
import { useQuery } from "react-query";

const ApiKeysForm = ({
  title,
  isEdit,
  updated,
  setUpdated,
  accessCheckBoxes,
  setAccessCheckBoxes,
  handleSubmit,
  handleClear,
}) => {
  const { data: accessOptions } = useQuery(["accessOptions"]);

  return (
    <Card small className="lo-stats h-100">
      <CardHeader className="border-bottom">
        <h6 className="m-0">
          {title} - {isEdit ? "Edit" : "New"}
        </h6>
        <div className="block-handle" />
      </CardHeader>

      <CardBody className="d-flex flex-column">
        <Form>
          <FormGroup>
            <FormInput
              placeholder="name"
              value={(updated && updated.name) || ""}
              onChange={(e) => setUpdated({ ...updated, name: e.target.value })}
            ></FormInput>
          </FormGroup>
          <FormGroup>
            <FormSelect
              value={(updated && updated.status) || "active"}
              onChange={(e) =>
                setUpdated({ ...updated, status: e.target.value })
              }
            >
              <option value="active">Active</option>
              <option value="suspended">Suspended</option>
            </FormSelect>
          </FormGroup>

          <fieldset>
            {accessOptions &&
              accessOptions.map((accessOption, i) => (
                <FormCheckbox
                  key={i}
                  checked={accessCheckBoxes[accessOption]}
                  onChange={() =>
                    setAccessCheckBoxes((accessCheckBoxes) => {
                      return {
                        ...accessCheckBoxes,
                        [accessOption]: !accessCheckBoxes[accessOption],
                      };
                    })
                  }
                >
                  {accessOption}
                </FormCheckbox>
              ))}
          </fieldset>
        </Form>
      </CardBody>
      <CardFooter className="border-top">
        <Row>
          <Col>
            <Button theme="white" onClick={handleClear}>
              Clear
            </Button>
          </Col>

          <Col className="text-right view-report">
            <Button theme="accent" onClick={handleSubmit}>
              Save
            </Button>
          </Col>
        </Row>
      </CardFooter>
    </Card>
  );
};

export default ApiKeysForm;
