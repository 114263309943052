import React from "react";
import { DropdownItem } from "shards-react";
import { useHistory } from "react-router-dom";
import { markSeen } from "../../services/notification.service";

const statusIcon = {
  system: "notifications",
  mail: "email",
  account: "person",
  security: "verified_user",
  analytics: "show_chart",
  upload: "file_upload",
};

const NotificationItem = ({ message, type, status, id }) => {
  const history = useHistory();
  return (
    <DropdownItem
      onClick={() => {
        markSeen(id).then((res) => {
          const { path } = res.data;
          history.push(path);
        });
      }}
    >
      <div className="notification__icon-wrapper">
        <div className="notification__icon">
          <i className="material-icons">{statusIcon[type]}</i>
        </div>
      </div>
      <div className="notification__content">
        <span className="notification__category">{type}</span>
        <p>{message}</p>
      </div>
    </DropdownItem>
  );
};

export default NotificationItem;
