import React, { useState } from "react";
import { Row, Col, Form, Button } from "shards-react";
import FormCard from "../../common/FormCard/FormCard";
import { useFormData } from "../../common/FormCard/useFormData";
import AddressForm from "./Edit/AddressForm";
import ClientContactForm from "./Edit/ClientContactForm";
import { updateClient } from "../../services/client.service";
import { useFormError } from "../../query.hooks/formError/useFormError";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";

const EditClient = ({ client, alertPage }) => {
  const queryClient = useQueryClient();
  let history = useHistory();
  const { formRef, getFormValues, fillFormValues, clearFormValues } =
    useFormData();

  const [phone, setPhone] = useState();
  const [cell, setCell] = useState();

  const [errors, setErrors] = useState({});
  const setFormErrors = useFormError(setErrors);

  React.useEffect(() => {
    if (client) {
      fillFormValues(client);
      setPhone(client.phone);
      setCell(client.cell);
    }
  }, [client]);

  React.useEffect(() => {
    if (errors.message) {
      alertPage.error(errors.message);
    }
  }, [errors]);

  async function updateClientData(clientData) {
    try {
      return await updateClient(client.id, clientData);
    } catch (ex) {
      setFormErrors(ex);
    }
  }

  async function handleSubmit() {
    const formValues = getFormValues();
    const dataObject = {};

    for (const key in client) {
      if (typeof client[key] === "object" && !Array.isArray(client[key])) {
        if (!dataObject.hasOwnProperty(key)) {
          dataObject[key] = {};
        }

        for (const subKey in client[key]) {
          dataObject[key][subKey] = formValues[subKey];
        }
      } else {
        if (formValues[key]) dataObject[key] = formValues[key];
      }
    }
    dataObject.phone = phone;
    dataObject.cell = cell;

    const updatedClient = await updateClientData(dataObject);

    if (updatedClient) {
      setErrors({});
      queryClient.refetchQueries(["clients", client.id]);

      history.push(`/clients/${client.id}`, {
        pageAlert: { theme: "success", message: "Client Updated" },
      });
    }
  }

  return (
    <>
      <Form onSubmit={(e) => e.preventDefault()} innerRef={formRef}>
        <Row className="rowFlex mb-4">
          <Col md="6">
            <FormCard title={"Client"} noFooter={true}>
              <ClientContactForm
                errors={errors}
                phone={phone}
                setPhone={setPhone}
                cell={cell}
                setCell={setCell}
              />
            </FormCard>
          </Col>
          <Col md="5">
            <FormCard title={"Address"} noFooter={true}>
              <AddressForm errors={errors} />
            </FormCard>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Button
              theme="accent"
              type="submit"
              disabled={false}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default EditClient;
