import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody } from "shards-react";
import TableHeader from "./common/TableHeader";
import { useSearchBar } from "../../custom/SiteSearchBar/SiteSearchBar";
import { useQuery, useMutation, useQueryClient } from "react-query";
import BookingListDataTable from "../BookingList/BookingListDataTable";
import AreYouSure, { useAreYouSure } from "../../common/AreYouSure/AreYouSure";
import NewBookingModal from "./Bookings/NewBookingModal";
import bookingService from "../../services/booking.service";
import authService from "../../services/auth.service";
import newRecordValidation from "../../validation/newRecord.validation";

const BookingsClient = ({ client, alertPage }) => {
  const queryClient = useQueryClient();
  const { id } = authService.userValue;
  const [isOpen, setIsOpen] = useState(false);
  const [booking, setBooking] = useState({});
  const [agents, setAgents] = useState([]);
  const [formError, setFormError] = useState();
  // State
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [sortBy, setSortBy] = useState("primary.lastName:asc");
  const { search, setSearch } = useSearchBar("");
  const { areYouSureProps, setDeleteHandler } = useAreYouSure();

  useEffect(() => {
    bookingService.getOptions({ option: "agents" }).then((res) => {
      setAgents(res.data);
    });
  }, []);

  async function handleSubmit() {
    try {
      if (!booking.hasOwnProperty("agent")) {
        booking.agent = id;
      }

      Object.assign(booking, {
        client: client.id,
      });

      const value = await newRecordValidation.booking.validateAsync(booking);

      await bookingService.createBooking(value);
      queryClient.invalidateQueries([
        "clientBookings",
        { page, limit, sortBy, search, clientId: client.id },
      ]);
      setIsOpen(false);
    } catch (ex) {
      if (ex.isJoi) {
        setFormError({
          path: ex.details[0].path[0],
          message: ex.details[0].message,
        });
      } else {
        console.log(ex);
      }
    }
  }

  useEffect(() => {
    if (!isOpen) {
      setBooking({});
      setFormError();
    }
  }, [isOpen]);
  /**
   * Bookings Query
   */
  const { status, data } = useQuery([
    "clientBookings",
    { page, limit, sortBy, search, clientId: (client && client.id) || "" },
  ]);

  const bookingDeleter = useMutation("deleteBooking");

  // Mutation goes here
  function handleDelete(item) {
    setDeleteHandler({
      deleteFn: async () => {
        bookingDeleter.mutate(
          {
            id: item.id,
            key: [
              "clientBookings",
              { page, limit, sortBy, search, clientId: client.id },
            ],
          },
          {
            onError: alertPage.error(`Unable to delete booking: ${item.name}`),
            onSuccess: alertPage.success(`Booking: ${item.name} DELETED`),
          }
        );
      },
      item: `Item`,
    });
  }

  return (
    <>
      <Row>
        <Col md="12">
          <Card className="p-0 mb-4">
            <TableHeader
              search={search}
              setSearch={setSearch}
              setIsOpen={setIsOpen}
            />
            <CardBody className="p-0">
              <BookingListDataTable
                status={status}
                data={data}
                limit={limit}
                setPage={setPage}
                setLimit={setLimit}
                setSortBy={setSortBy}
                setPreFetchId={() => {}}
                handleDelete={handleDelete}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <NewBookingModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        booking={booking}
        setBooking={setBooking}
        agents={agents}
        handleSubmit={handleSubmit}
        formError={formError}
      />
      <AreYouSure areYouSureProps={areYouSureProps} />
    </>
  );
};

export default BookingsClient;
