import moment from "moment";
import React from "react";
import { useHistory } from "react-router";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  ButtonGroup,
  Button,
} from "shards-react";
import capitalizeArray from "../../utils/capitalizeArray";

const ReminderList = ({ title, data }) => {
  const history = useHistory();
  return (
    <Card small className="payout-stats h-100">
      <CardHeader className="border-bottom">
        <h6 className="m-0">{title}</h6>
        <div className="block-handle" />
      </CardHeader>
      <CardBody className="p-0">
        <Container fluid className="px-0">
          <table className="table mb-0">
            <thead className="py-2 bg-light text-semibold border-bottom">
              <tr>
                <th>Client</th>
                <th className="text-center"></th>
                <th className="text-center"></th>
                <th className="text-center"></th>
                <th className="text-right"></th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((reminder) => (
                  <tr>
                    <td className="lo-stats__order-details">
                      <span>{`${capitalizeArray(
                        reminder._client.primary.lastName
                      )}`}</span>
                      <span>
                        {moment.utc(reminder.travelDate).format("MM-DD-YYYY")}
                      </span>
                    </td>
                    <td className="lo-stats__items text-center"></td>
                    <td className="lo-stats__items text-center">
                      {reminder.name}
                    </td>
                    <td
                      className={`lo-stats__total text-center text-success`}
                    ></td>
                    <td className="lo-stats__actions">
                      <ButtonGroup className="d-table ml-auto">
                        <Button
                          size="sm"
                          theme="white"
                          onClick={() => {
                            history.push(
                              `/clients/${reminder.clientId}/bookings/${
                                reminder.id || reminder._id
                              }`
                            );
                          }}
                        >
                          Booking
                        </Button>
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Container>
      </CardBody>
    </Card>
  );
};

export default ReminderList;
