import React from "react";
import {
  Col,
  FormInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "shards-react";

const GlobalSearchBar = ({ search, setSearch }) => {
  return (
    <Col className="file-manager__filters__search d-flex">
      <InputGroup seamless size="sm" className="ml-auto">
        <InputGroupAddon type="prepend">
          <InputGroupText>
            <i className="material-icons">search</i>
          </InputGroupText>
        </InputGroupAddon>
        <FormInput value={search} onChange={(e) => setSearch(e.target.value)} />
      </InputGroup>
    </Col>
  );
};

export default GlobalSearchBar;
