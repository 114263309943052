import { useQueryClient, useMutation } from "react-query";
import { updateUpload } from "../../services/upload.service";

export function useMutationUpdateFreeText(mutationFn, setFormData, queryKey) {
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onMutate: async (variables) => {
      setFormData("");

      await queryClient.cancelQueries(queryKey[0]);

      const previousData = queryClient.getQueryData(queryKey);

      // Optimistic Update
      queryClient.setQueryData(queryKey, (old) => {
        return {
          ...old,
          freeText: [
            ...old.freeText,
            {
              createdAt: Date.now(),
              freeText: variables.freeText.freeText,
              user: variables.freeText.user,
            },
          ],
        };
      });

      return { previousData };
    },
    onError: (error, variables, { previousData }) => {
      console.log("Unable to save");

      queryClient.setQueryData(queryKey, previousData);

      if (setFormError) setNewError(error);
    },
    onSettled: (data, error, variables) => {
      if (error) {
        setFormData(variables);
      }
      queryClient.invalidateQueries(queryKey);
    },
  });
}

export function useMutationAddClientUser(mutationFn, setFormData, queryKey) {
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onMutate: async (variables) => {
      setFormData();
      await queryClient.cancelQueries(queryKey[0]);

      const previousData = queryClient.getQueryData(queryKey);

      // Optimistic Update
      queryClient.setQueryData(queryKey, (old) => {
        return {
          ...old,
          users: [...old.users, variables.user],
        };
      });

      return { previousData };
    },
    onError: (error, variables, { previousData }) => {
      console.log("Unable to save");

      queryClient.setQueryData(queryKey, previousData);
    },
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries(queryKey);
    },
  });
}

export function useMutationRemoveClientUser(mutationFn, queryKey) {
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onMutate: async (variables) => {
      await queryClient.cancelQueries(queryKey[0]);

      const previousData = queryClient.getQueryData(queryKey);

      // Optimistic Update
      queryClient.setQueryData(queryKey, (old) => {
        return {
          ...old,
          users: [...old.users].filter((user) => {
            return user.id !== variables.id;
          }),
        };
      });

      return { previousData };
    },
    onError: (error, variables, { previousData }) => {
      console.log("Unable to save");

      queryClient.setQueryData(queryKey, previousData);
    },
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries(queryKey);
    },
  });
}
