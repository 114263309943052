import React from "react";
import { Container, Row, Col } from "shards-react";
import PageAlert, { usePageAlert } from "../common/Alert/PageAlert";
import AreYouSure, { useAreYouSure } from "../common/AreYouSure/AreYouSure";
import { useQueryClient, useQuery } from "react-query";
import { setBookingsQueryDefaults } from "../query/booking.query";
import { useSearchBar } from "../custom/SiteSearchBar/SiteSearchBar";
import PlainHeader from "../common/PlainHeader";
import ReminderList from "../components/Reminders/ReminderList";

const Reminders = () => {
  /* Query Defaults */
  const queryClient = useQueryClient();
  setBookingsQueryDefaults(queryClient);
  /* Table State */

  const { search, setSearch } = useSearchBar("");

  const { alertPage, alertProps } = usePageAlert();
  const { areYouSureProps, setDeleteHandler } = useAreYouSure();

  /* Query */
  const { data, status } = useQuery(["allReminders"]);

  console.log(data);
  return (
    <>
      <PageAlert alertProps={alertProps} />
      <Container fluid className="main-content-container px-4">
        <PlainHeader
          title={"Reminders"}
          subtitle={"Agent"}
          pageInfo="Booking reminders"
        />
        <Row className="mb-4">
          <Col md="4">
            {status === "success" && (
              <ReminderList
                title={"Two weeks till last payment"}
                data={data.twoWeeksLastPayment.results}
              />
            )}
          </Col>
          <Col md="4">
            {status === "success" && (
              <ReminderList
                title={"Three days till last payment"}
                data={data.threeDaysLastPayment.results}
              />
            )}
          </Col>
          <Col md="4">
            {status === "success" && (
              <ReminderList
                title={"Payments due tomorrow"}
                data={data.nextDayPayment.results}
              />
            )}
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md="4">
            {status === "success" && (
              <ReminderList
                title={"Two weeks till travel date"}
                data={data.twoWeeksTravel.results}
              />
            )}
          </Col>
          <Col md="4">
            {status === "success" && (
              <ReminderList
                title={"Three days till travel date"}
                data={data.threeDaysTravel.results}
              />
            )}
          </Col>
        </Row>
      </Container>
      <AreYouSure areYouSureProps={areYouSureProps} />
    </>
  );
};

export default Reminders;
