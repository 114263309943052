import React from "react";
import { Link } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
  ButtonGroup,
  Button,
} from "shards-react";
import bookingService from "../../../services/booking.service";

const Reminders = ({ bookingId }) => {
  const queryClient = useQueryClient();
  const { data, status } = useQuery(["reminders", bookingId]);

  async function handleToggle(reminder) {
    try {
      await bookingService.toggleReminder(bookingId, reminder);
      queryClient.invalidateQueries(["reminders", bookingId]);
    } catch (ex) {
      console.log(ex);
    }
  }
  return (
    <Row className="mb-4">
      <Col md="6">
        <Card small className="payout-stats h-100">
          <CardHeader className="border-bottom">
            <h6 className="m-0">Reminders</h6>
            <div className="block-handle" />
          </CardHeader>
          <CardBody className="p-0">
            <Container fluid className="px-0">
              {status === "success" && (
                <table className="table mb-0">
                  <thead className="py-2 bg-light text-semibold border-bottom">
                    <tr>
                      <th>Name</th>
                      <th className="text-center"></th>
                      <th className="text-center"></th>
                      <th className="text-center">Status</th>
                      <th className="text-right"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="lo-stats__order-details">
                        <span>Travel in 2 weeks</span>
                        <span>
                          Notification on{" "}
                          {moment
                            .utc(data.travelDate)
                            .subtract(2, "week")
                            .format("MM-DD-YYYY")}
                        </span>
                      </td>
                      <td className="lo-stats__items text-center"></td>
                      <td className="lo-stats__items text-center"></td>
                      <td
                        className={`lo-stats__total text-center text-${
                          data.reminders.twoWeeksTravel ? "success" : "danger"
                        }`}
                      >
                        {data.reminders.twoWeeksTravel ? "ON" : "Off"}
                      </td>
                      <td className="lo-stats__actions">
                        <ButtonGroup className="d-table ml-auto">
                          <Button
                            size="sm"
                            theme="white"
                            onClick={() => {
                              handleToggle("twoWeeksTravel");
                            }}
                          >
                            {data.reminders.twoWeeksTravel
                              ? "Dismiss"
                              : "Turn On"}
                          </Button>
                        </ButtonGroup>
                      </td>
                    </tr>
                    <tr>
                      <td className="lo-stats__order-details">
                        <span>Travel in 3 days</span>
                        {data && data.reminders && (
                          <span>
                            Notification on{" "}
                            {moment
                              .utc(data.travelDate)
                              .subtract(3, "day")
                              .format("MM-DD-YYYY")}
                          </span>
                        )}
                      </td>
                      <td className="lo-stats__items text-center"></td>
                      <td className="lo-stats__items text-center"></td>
                      <td
                        className={`lo-stats__total text-center text-${
                          data.reminders.threeDaysTravel ? "success" : "danger"
                        }`}
                      >
                        {data && data.reminders.threeDaysTravel ? "ON" : "Off"}
                      </td>
                      <td className="lo-stats__actions">
                        <ButtonGroup className="d-table ml-auto">
                          <Button
                            size="sm"
                            theme="white"
                            onClick={() => {
                              handleToggle("threeDaysTravel");
                            }}
                          >
                            {data.reminders.threeDaysTravel
                              ? "Dismiss"
                              : "Turn On"}
                          </Button>
                        </ButtonGroup>
                      </td>
                    </tr>
                    <tr>
                      <td className="lo-stats__order-details">
                        <span>Last Payment in 2 weeks</span>
                        <span>
                          Notification on{" "}
                          {moment
                            .utc(data.lastPayment)
                            .subtract(2, "week")
                            .format("MM-DD-YYYY")}
                        </span>
                      </td>
                      <td className="lo-stats__items text-center"></td>
                      <td className="lo-stats__items text-center"></td>
                      <td
                        className={`lo-stats__total text-center text-${
                          data.reminders.twoWeeksLastPayment
                            ? "success"
                            : "danger"
                        }`}
                      >
                        {data && data.reminders.twoWeeksLastPayment
                          ? "ON"
                          : "Off"}
                      </td>
                      <td className="lo-stats__actions">
                        <ButtonGroup className="d-table ml-auto">
                          <Button
                            size="sm"
                            theme="white"
                            onClick={() => {
                              handleToggle("twoWeeksLastPayment");
                            }}
                          >
                            {data.reminders.twoWeeksLastPayment
                              ? "Dismiss"
                              : "Turn On"}
                          </Button>
                        </ButtonGroup>
                      </td>
                    </tr>
                    <tr>
                      <td className="lo-stats__order-details">
                        <span>Last Payment in 3 days</span>
                        <span>
                          Notification on{" "}
                          {moment
                            .utc(data.lastPayment)
                            .subtract(3, "day")
                            .format("MM-DD-YYYY")}
                        </span>
                      </td>
                      <td className="lo-stats__items text-center"></td>
                      <td className="lo-stats__items text-center"></td>
                      <td
                        className={`lo-stats__total text-center text-${
                          data.reminders.threeDaysLastPayment
                            ? "success"
                            : "danger"
                        }`}
                      >
                        {data.reminders.threeDaysLastPayment ? "ON" : "Off"}
                      </td>
                      <td className="lo-stats__actions">
                        <ButtonGroup className="d-table ml-auto">
                          <Button
                            size="sm"
                            theme="white"
                            onClick={() => {
                              handleToggle("threeDaysLastPayment");
                            }}
                          >
                            {data.reminders.threeDaysLastPayment
                              ? "Dismiss"
                              : "Turn On"}
                          </Button>
                        </ButtonGroup>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </Container>
          </CardBody>
          <CardFooter className="border-top">
            <Row>
              <Col></Col>
              <Col className="text-right view-report">
                <Link to={`/reminders`}>Go to All Reminders &rarr;</Link>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Col>
    </Row>
  );
};

export default Reminders;
