import React from "react";
import getSidebarNavItems from "../../data/sidebar-nav-items";

const useSidebarItems = (role) => {
  const currentRole = role ? role : "user";

  const [sidebarNavItems, setSidebarNavItems] = React.useState();
  React.useEffect(() => {
    setSidebarNavItems(getSidebarNavItems(currentRole));
  }, []);

  function toggleSidebarDropdown(item) {
    const newStore = sidebarNavItems;
    let navGroupIdx = null;
    let navItemIdx = null;
    newStore.forEach((navItem, _idx) => {
      const __idx = navItem.items.indexOf(item);
      if (__idx !== -1) {
        navGroupIdx = _idx;
        navItemIdx = __idx;
      }
    });

    newStore[navGroupIdx].items[navItemIdx].open =
      !newStore[navGroupIdx].items[navItemIdx].open;

    const newMenuItems = newStore.map((i) => {
      i.items = i.items.map((_i, idx) => {
        if (idx !== navItemIdx) {
          _i.open = false;
        }
        return _i;
      });
      return i;
    });

    setSidebarNavItems(newMenuItems);
  }

  return [sidebarNavItems, toggleSidebarDropdown];
};

export default useSidebarItems;
