import React from "react";
import { useQueryClient } from "react-query";
import bookingService from "../../../services/booking.service";

const useBooking = (bookingId, bookingType, validation) => {
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = React.useState(false);
  const [selected, setSelected] = React.useState();
  const [formError, setFormError] = React.useState();
  const [isPackage, setIsPackage] = React.useState(false);

  React.useEffect(() => {
    if (selected) {
      setFormError();
      setIsOpen(true);
    }
  }, [selected]);

  React.useEffect(() => {
    if (!isOpen) {
      setSelected();
    }
  }, [isOpen]);

  async function handleSubmit() {
    try {
      Object.assign(selected, {
        package: isPackage ? true : false,
        price: isPackage ? 0 : selected.price,
        vendor: selected.vendor ? selected.vendor.id : "MISSING",
      });

      console.log(selected);

      const value = await validation.validateAsync(selected);

      delete value._id;
      delete value.type;

      if (selected._id) {
        console.log("EDIT", bookingId, bookingType, selected._id, value);
        await bookingService.updateBookingItem(
          bookingId,
          bookingType,
          selected._id,
          value
        );
      } else {
        console.log("NEW", bookingId, bookingType, value);
        await bookingService.createBookingItem(bookingId, bookingType, value);
      }
      queryClient.invalidateQueries(["booking", bookingId]);
      setIsOpen(false);
    } catch (ex) {
      if (ex.isJoi) {
        console.log(ex);
        setFormError({
          path: ex.details[0].path[0],
          message: ex.details[0].message,
        });
      } else {
        console.log(ex);
      }
    }
  }

  return {
    handleSubmit,
    isOpen,
    setIsOpen,
    selected,
    setSelected,
    formError,
    setFormError,
    isPackage,
    setIsPackage,
  };
};

export default useBooking;
