export const objectId = (value, helpers) => {
  if (!value.match(/^[0-9a-fA-F]{24}$/)) {
    return helpers.message('"{{#label}}" must be a valid mongo id');
  }
  return value;
};

export const phone = (value, helpers) => {
  if (value.length !== 10) {
    return helpers.message('"phone" must be 10 digits');
  }

  if (value.match(/[a-zA-Z]/)) {
    return helpers.message('"phone" must only be numbers');
  }
  return value;
};

const exports = {
  phone,
  objectId,
};

export default exports;
