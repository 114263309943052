import React, { useEffect, useContext } from "react";
import {
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
} from "shards-react";
import { useLocation } from "react-router-dom";
import { SearchBarContext } from "./SearchBarContext";

const SiteSearchBar = () => {
  const { search, setSearch } = useSearchBar();

  return (
    <Form className="main-navbar__search w-100 d-none d-md-flex d-lg-flex">
      <InputGroup seamless className="ml-3">
        <InputGroupAddon type="prepend">
          <InputGroupText>
            <i className="material-icons">search</i>
          </InputGroupText>
        </InputGroupAddon>
        <FormInput
          className="navbar-search"
          placeholder="Search for something..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </InputGroup>
    </Form>
  );
};
export default SiteSearchBar;

export function useSearchBar() {
  const { search, setSearch } = useContext(SearchBarContext);
  const { pathname } = useLocation();
  useEffect(() => {
    setSearch("");
  }, [pathname]);

  return { search, setSearch };
}
