import { ButtonGroup, Button } from "shards-react";
import moment from "moment";
import capitalize from "../../utils/capitalize";
import formatPhoneNumber from "../../utils/formatPhoneNumber";

export default function userColumns(toggleRow, editRow, deleteRow) {
  return [
    {
      Header: "id",
      accessor: "id",

      show: false,
    },
    {
      Header: "Joined",
      accessor: "createdAt",
      minWidth: 35,
      Cell: (row) => (
        <span>{moment(row.original.createdAt).format("MM-DD-YYYY")}</span>
      ),
    },
    {
      Header: "Name",
      accessor: "lastName",
      minWidth: 75,
      Cell: (row) => (
        <span>
          {capitalize(row.original.lastName)},{" "}
          {capitalize(row.original.firstName)}
        </span>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: (row) => <span>{row.original.email}</span>,
    },

    {
      Header: "Role",
      accessor: "role",
      minWidth: 25,
      className: "text-center",
      Cell: (row) => (
        <span className={row.original.role === "admin" ? "text-salmon" : ""}>
          {capitalize(row.original.role)}
        </span>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      minWidth: 25,
      className: "text-center",
      Cell: (row) => (
        <span
          className={row.original.status === "suspended" ? "text-danger" : ""}
        >
          {capitalize(row.original.status)}
        </span>
      ),
    },
    {
      Header: "Online Status",
      accessor: "status",
      minWidth: 25,
      className: "text-center",
      Cell: (row) => (
        <span
          className={row.original.onlineStatus === "online" ? "text-java" : ""}
        >
          {capitalize(row.original.onlineStatus)}
        </span>
      ),
    },
    {
      Header: "Actions",
      accessor: "actions",
      maxWidth: 130,
      minWidth: 180,
      sortable: false,
      Cell: (row) => (
        <ButtonGroup size="sm" className="d-table mx-auto">
          <Button
            theme="white"
            onClick={() => {
              toggleRow(row.original);
            }}
          >
            <i className="material-icons">&#xE7FD;</i>
          </Button>
          <Button
            theme="white"
            onClick={() => {
              editRow(row.original);
            }}
          >
            <i className="material-icons">&#xE254;</i>
          </Button>
          <Button
            theme="white"
            onClick={() => {
              deleteRow(row.original);
            }}
          >
            <i className="material-icons">&#xE872;</i>
          </Button>
        </ButtonGroup>
      ),
    },
  ];
}
