import React from "react";
import { Container, Row, Col, CardHeader } from "shards-react";

const FormCardHeader = ({ title }) => {
  return (
    <CardHeader className="p-0">
      <Container fluid className="file-manager__filters border-bottom">
        <Row>
          <Col>
            <h6 className="className=m-0">{title}</h6>
          </Col>

          {/* Filters :: Search */}
          <Col className="file-manager__filters__search d-flex" md="6"></Col>
        </Row>
      </Container>
    </CardHeader>
  );
};

export default FormCardHeader;
