import React from "react";
import { Container, Row, Col } from "shards-react";
import PageHeader, { useDateChangeHandler } from "../common/PageHeader";

import BookingHeader from "../common/BookingHeader";
import { useLocation } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { useClient } from "../query.hooks/clients/clients";
import EditClient from "../components/Clients/EditClient";
import ProfileClient from "../components/Clients/ProfileClient";
import UsersClient from "../components/Clients/UsersClient";
import PageAlert from "../common/Alert/PageAlert";
import { useAlert } from "../common/Alert/useAlert";
import AreYouSure, { useAreYouSure } from "../common/AreYouSure/AreYouSure";
import { setBookingsQueryDefaults } from "../query/booking.query";
import BookingsClient from "../components/Clients/BookingsClient";
import { useParams } from "react-router-dom";
import FlightsCard from "../components/Booking/FlightsCard";
import ViewBooking from "../components/Booking/ViewBooking";
import bookingService from "../services/booking.service";
import Payouts from "../components/Booking/Payouts/Payouts";
import Payment from "../components/Booking/Payments/Payment";
import CheckList from "../components/Booking/checklist/CheckList";
import Reminders from "../components/Booking/reminders/Reminders";

const Booking = ({ match }) => {
  const queryClient = useQueryClient();
  setBookingsQueryDefaults(queryClient);
  let { bookingId } = useParams();
  /**
   * Bookings Query
   */
  const { status, data } = useQuery(["booking", bookingId]);

  const { alertPage, alertProps } = useAlert();

  const { areYouSureProps, setDeleteHandler } = useAreYouSure();

  function handleDelete(item, type) {
    setDeleteHandler({
      deleteFn: async () => {
        await bookingService.deleteBookingItem(bookingId, type, item._id);
        queryClient.invalidateQueries(["booking", bookingId]);
      },
      item: `Item`,
    });
  }

  return (
    <>
      <PageAlert alertProps={alertProps} />
      <Container fluid className="main-content-container px-4">
        {status === "success" && (
          <BookingHeader
            title={data && data.client.primary.lastName}
            subtitle={"Private Client"}
            id={data && data.id}
            clientId={data && data.clientId}
            page={match.params.page}
          />
        )}

        <div>
          {match.params.page === "payouts" && (
            <Payouts bookingId={bookingId} alertPage={alertPage} />
          )}
        </div>
        <div>
          {match.params.page === "payment" && <Payment bookingId={bookingId} />}
        </div>
        <div>
          {match.params.page === "reminders" && (
            <Reminders bookingId={bookingId} />
          )}
        </div>
        <div>
          {match.params.page === "checklist" && (
            <CheckList bookingId={bookingId} />
          )}
        </div>
        <div>
          {!match.params.page && (
            <ViewBooking
              data={data}
              handleDelete={handleDelete}
              alertPage={alertPage}
            />
          )}
        </div>
      </Container>
      <AreYouSure areYouSureProps={areYouSureProps} />
    </>
  );
};

export default Booking;
