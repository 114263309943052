import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Button,
  Form,
  FormInput,
  CardHeader,
} from "shards-react";
import authService from "../../services/auth.service";
import bookingService from "../../services/booking.service";
import FreeTextDisplay from "./FreeText/FreeTextDisplay";
import { useQueryClient } from "react-query";

const FreeText = ({ id, freeText }) => {
  const queryClient = useQueryClient();
  const [newFreeText, setNewFreeText] = useState();
  const user = authService.userValue;

  async function handleSubmit() {
    try {
      await bookingService.addFreeText(id, {
        user: `${user.lastName}, ${user.firstName}`,
        freeText: newFreeText,
      });
      setNewFreeText("");
      queryClient.invalidateQueries(["booking", id]);
    } catch (ex) {
      console.log(ex);
    }
  }

  return (
    <Card className="p-0 mb-4 h-100">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Booking Free Text</h6>
        <div className="block-handle" />
      </CardHeader>
      <CardBody className="p-0">
        <FreeTextDisplay freeText={freeText} />
      </CardBody>
      <CardFooter className="border-top">
        <Row className="mb-2">
          <FormInput
            placeholder="Add free text"
            className="mt-4"
            onChange={(e) => setNewFreeText(e.target.value)}
            value={newFreeText}
          />
        </Row>
        <Row>
          {newFreeText && (
            <Button theme="accent" type="submit" onClick={() => handleSubmit()}>
              Save Changes
            </Button>
          )}
        </Row>
      </CardFooter>
    </Card>
  );
};

export default FreeText;
