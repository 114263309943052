import React from "react";
import { ButtonGroup, Button } from "shards-react";
import moment from "moment";
import capitalize from "../utils/capitalize";
import filesize from "filesize";

const UploadColumns = ({
  handleFileDownload,
  handleFileEdit,
  handleFileDelete,
}) => {
  return [
    {
      Header: "",
      accessor: "icon",
      maxWidth: 60,
      sortable: false,
      Cell: (row) => (
        <div className="file-manager__item-icon" tabIndex="0">
          <div>
            <i className="material-icons">&#xE24D;</i>
          </div>
        </div>
      ),
    },
    {
      Header: "Name",
      accessor: "fileName",
      Cell: (row) => (
        <div>
          <h5 className="file-manager__item-title">
            {row.original.originalname}
          </h5>
          <span className="file-manager__item-meta">
            Uploaded {moment(row.original.createdAt).fromNow()}
          </span>
        </div>
      ),
    },
    {
      Header: "Size",
      accessor: "fileSize",
      maxWidth: 100,
      className: "text-center d-flex align-items-center",
      Cell: (row) => (
        <div>
          <span className="file-manager__item-meta">
            {filesize(row.original.fileSize)}
          </span>
        </div>
      ),
    },
    {
      Header: "User",
      accessor: "user",
      maxWidth: 180,
      className: "text-center d-flex align-items-center",
      Cell: (row) => {
        //console.log(row.original);
        const { user } = row.original;
        return (
          <div>
            <span className="file-manager__item-meta">
              {user &&
                `${capitalize(user.lastName)}, ${capitalize(user.firstName)}`}
            </span>
          </div>
        );
      },
    },
    {
      Header: "Actions",
      accessor: "actions",
      maxWidth: 200,
      sortable: false,
      Cell: (row) => (
        <ButtonGroup size="sm" className="d-table my-auto">
          <Button
            theme="white"
            className="mr-2"
            onClick={() => handleFileDownload(row.original)}
          >
            <i className="material-icons">&#xE2C4;</i>
          </Button>
          <Button
            theme="white"
            className="mr-2"
            onClick={() => handleFileEdit(row.original)}
          >
            <i className="material-icons">&#xE254;</i>
          </Button>
          <Button theme="danger" onClick={() => handleFileDelete(row.original)}>
            <i className="material-icons">&#xE872;</i>
          </Button>
        </ButtonGroup>
      ),
    },
  ];
};

export default UploadColumns;
