import React from "react";
import { useDropzone } from "react-dropzone";
import classNames from "classnames";

const TableDropzone = ({ handleUpload }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*, application/pdf",
    onDrop: (acceptedFiles) => {
      // Upload Files
      handleUpload(acceptedFiles);
    },
  });

  return (
    <div className="file-manager__dropzone">
      <div
        {...getRootProps()}
        className={classNames("dropzone", {
          "dropzone--isActive": isDragActive,
        })}
      >
        <input {...getInputProps()} />
        <p>
          {isDragActive
            ? "Drop files"
            : "Drag files here to upload, or click to select files"}
        </p>

        <em>(Only image and PDF files will be accepted)</em>
      </div>
    </div>
  );
};

export default TableDropzone;
