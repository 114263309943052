import React from "react";
import TravelersCard from "./TravelersCard";
import FlightsCard from "./FlightsCard";
import { Row, Col } from "shards-react";
import HotelsCard from "./HotelsCard";
import ToursCard from "./ToursCard";
import CruiseCard from "./CruiseCard";
import RentalCarCard from "./RentalCarCard";
import FreeText from "./FreeText";
import BookingForm from "./BookingForm";

const ViewBooking = ({ data, handleDelete }) => {
  return (
    <>
      <Row className="mb-4">
        <Col md={7}>{data && <BookingForm data={data} />}</Col>
        <Col md={5}>
          <FreeText
            id={data && data.id}
            freeText={(data && data.freeText) || []}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={5}>
          <TravelersCard
            bookingId={data && data.id}
            travelers={(data && data.travelers) || []}
            handleDelete={handleDelete}
          />
        </Col>
        <Col md={7}>
          <RentalCarCard
            label="Rental Cars"
            bookingId={data && data.id}
            data={(data && data.rentalCars) || []}
            handleDelete={handleDelete}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={6}>
          <HotelsCard
            label="Hotels"
            bookingId={data && data.id}
            data={(data && data.hotels) || []}
            handleDelete={handleDelete}
          />
        </Col>
        <Col md={6}>
          <ToursCard
            label="Tours"
            bookingId={data && data.id}
            data={(data && data.tours) || []}
            handleDelete={handleDelete}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <FlightsCard
            bookingId={data && data.id}
            flights={(data && data.flights) || []}
            handleDelete={handleDelete}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <CruiseCard
            label="Cruise"
            bookingId={data && data.id}
            data={(data && data.cruise) || []}
            handleDelete={handleDelete}
          />
        </Col>
      </Row>
    </>
  );
};

export default ViewBooking;
