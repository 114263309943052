//import { useQueryClient } from "react-query";

export function useActions({ userId, downloadFile, setUpdated }) {
  const handleFileDownload = (row) => {
    downloadFile(userId, row.id, row.originalname);
  };
  const handleFileEdit = (row) => {
    setUpdated(row);
  };

  return { handleFileDownload, handleFileEdit };
}
