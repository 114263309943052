import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import App from './App';
import { authService } from "./services/auth.service";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/main.scss";
//import "@react-pdf-viewer/core/lib/styles/index.css";
//import "@react-pdf-viewer/thumbnail/lib/styles/index.css";

authService.refreshToken().finally(startApp);

async function startApp() {
  ReactDOM.render(
    <React.StrictMode>
      <Router basename={process.env.REACT_APP_BASENAME || ""}>
        <App />
      </Router>
    </React.StrictMode>,
    document.getElementById('root')
  );
}



