import React, { useEffect, useState } from "react";
import DataTable from "./DataTable";
import { useMutation, useQuery, useQueryClient } from "react-query";
import UserColumns from "./UserColumns";
import EditUserModal from "./EditUserModal";
import capitalizeArray from "../../utils/capitalizeArray";

const UserDataTable = ({
  table,
  limit,
  search,
  start,
  end,
  setLimit,
  alertPage,
  setDeleteHandler,
}) => {
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const [updated, setUpdated] = useState();

  useEffect(() => {
    if (updated) setIsOpen(true);
  }, [updated]);

  useEffect(() => {
    if (!isOpen) setUpdated();
  }, [isOpen]);

  // State
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState("lastName:asc");

  // Query hooks
  const { status, data } = useQuery([
    "users",
    { page, limit, sortBy, search, start, end },
  ]);

  const setPreFetchId = (id) => {
    queryClient.prefetchQuery(["user", id]);
  };

  /**
   * Delete Function
   */
  const userDeleter = useMutation("deleteUser");
  function handleDeleteUser(item) {
    setDeleteHandler({
      deleteFn: () => {
        userDeleter.mutate(
          {
            id: item.id,
            key: ["users", { page, limit, sortBy, search, start, end }],
          },
          {
            onError: () => {
              alertPage.error(
                `Unable to delete user: ${capitalizeArray(
                  item.lastName
                )}, ${capitalizeArray(item.firstName)} - ${item.email}`
              );
            },
            onSuccess: () => {
              queryClient.invalidateQueries("users");
              alertPage.success(
                `User: ${capitalizeArray(item.lastName)}, ${capitalizeArray(
                  item.firstName
                )} - ${item.email} DELETED`
              );
            },
          }
        );
      },
      item: `User ${item.email}`,
    });
  }

  /**
   * Update Function
   */
  const userUpdater = useMutation("updateUser");
  function handleStatusToggle(row) {
    const status = row.status === "active" ? "suspended" : "active";

    userUpdater.mutate({
      id: row.id,
      body: {
        status: row.status === "active" ? "suspended" : "active",
      },
      key: ["users", { page, limit, sortBy, search, start, end }],
    });
  }

  /* Move back a page when no results in page data */
  useEffect(() => {
    if (data) {
      if (!data.results[0] && page > data.totalPages && page !== 1) {
        setPage((page) => page - 1);
      }
    }
  }, [data]);

  return (
    <React.Fragment>
      <div className="">
        {table === "Users" && (
          <DataTable
            columns={UserColumns(
              (row) => {
                handleStatusToggle(row);
              },
              (row) => {
                setUpdated(row);
              },
              (item) => {
                handleDeleteUser(item);
              }
            )}
            data={data && data.results}
            pages={data && data.totalPages}
            loading={status === "loading"}
            pageSize={limit}
            setPage={setPage}
            setLimit={setLimit}
            setSortBy={setSortBy}
            prefetchItem={setPreFetchId}
          />
        )}
      </div>
      <EditUserModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        updated={updated}
        setUpdated={setUpdated}
        handleSubmit={() => {
          const { email, status, role } = updated;

          userUpdater.mutate({
            id: updated.id,
            body: { email, status, role },
            key: ["users", { page, limit, sortBy, search, start, end }],
          });
          setIsOpen(false);
        }}
        error={{}}
      />
    </React.Fragment>
  );
};

export default UserDataTable;
