import React, { useState } from "react";
import { Container, Row, Col, ButtonGroup, Button, Fade } from "shards-react";
import PageAlert, { usePageAlert } from "../common/Alert/PageAlert";
import AreYouSure, { useAreYouSure } from "../common/AreYouSure/AreYouSure";
import PageTitle from "../components/common/PageTitle";
import FileManagerCards from "../component_modules/fileManager/FileManagerCards";
import FileManagerList from "../component_modules/fileManager/FileManagerList";
import {
  getDirectoryUploads,
  updateDirectoryUpload,
  updateUpload,
  uploadFiles,
  deleteUpload,
  getAllDirectoryUploads,
  getUpload,
} from "../services/upload.service";
import { previewFile, downloadFile } from "../services/downloader.service";
import { authService } from "../services/auth.service";
import { setUploadQueryDefaults } from "../query/upload.query";
import { useQueryClient } from "react-query";

const UserUploads = () => {
  /* Setup Uploads Query/Mutation defaults */
  const queryClient = useQueryClient();
  setUploadQueryDefaults(queryClient);
  const { alertPage, alertProps } = usePageAlert();
  const { areYouSureProps, setDeleteHandler } = useAreYouSure();
  /* Get User ID */
  const { id: userId } = authService.userValue;
  /* List/Card - file manager view toggler */
  const [view, setView] = useState("list");

  return (
    <>
      <PageAlert alertProps={alertProps} />
      <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            title="File Manager"
            subtitle="Dashboards"
            className="text-sm-left mb-3"
          />
          {/* Page Header :: List/Cards Actions */}
          <Col className="col d-flex">
            <ButtonGroup
              size="sm"
              className="d-inline-flex ml-auto my-auto ml-auto mr-auto mr-sm-0"
            >
              <Button
                theme="white"
                active={view === "list"}
                onClick={() => setView("list")}
              >
                <i className="material-icons">&#xE8EF;</i>
              </Button>
              <Button
                theme="white"
                active={view === "cards"}
                onClick={() => setView("cards")}
              >
                <i className="material-icons">&#xE8F0;</i>
              </Button>
            </ButtonGroup>
          </Col>
        </Row>

        {/* Cards View  */}
        {view === "cards" && (
          <Fade>
            <FileManagerCards
              userId={userId}
              folders={[
                "My Documents",
                "Pictures",
                "Billing",
                "Notes",
                "Family",
              ]}
              // Services
              getFile={getUpload}
              getFiles={getDirectoryUploads}
              uploadFiles={uploadFiles}
              moveFile={updateUpload}
              previewFile={previewFile}
              deleteFile={deleteUpload}
              updateFile={updateDirectoryUpload}
              alertPage={alertPage}
              setDeleteHandler={setDeleteHandler}
            />
          </Fade>
        )}
        {/* List View  */}
        {view === "list" && (
          <Fade>
            <FileManagerList
              userId={userId}
              folders={[
                "My Documents",
                "Pictures",
                "Billing",
                "Notes",
                "Family",
              ]}
              // Services
              getFiles={getAllDirectoryUploads}
              uploadFiles={uploadFiles}
              downloadFile={downloadFile}
              deleteFile={deleteUpload}
              updateFile={updateDirectoryUpload}
              alertPage={alertPage}
              setDeleteHandler={setDeleteHandler}
            />
          </Fade>
        )}
      </Container>
      <AreYouSure areYouSureProps={areYouSureProps} size="sm" />
    </>
  );
};

export default UserUploads;
