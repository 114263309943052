import notificationService from "../services/notification.service";
import { MutationOption, QueryFunction } from "../component_modules/QuerySetup/QuerySetup";

/** Creat uploads defaults
 * 
 * 
 * @param {object} queryClient 
 * @returns queryClient
 */
export const setNotificationQueryDefaults = (queryClient) => {
    /**
   * Create Optimistic Update default Mutation loader
   * 
   * @param queryClient
   * @param param0 - Options & defaults
   * @param param0.DEBUG 
   * 
   * @returns {MutationOption} 
   */
    const optimistic = new MutationOption(queryClient, { DEBUG: true });

    /** UPDATE - Mutation "markSeen"
    * UPDATE - Mutation "markSeen"
    * 
    * @param {variables.id} ObjectId of notification
    * @param {variables.body} object - updated document
    */
    optimistic.mutationFn = (variables) => notificationService.markSeen(variables.id); //uploadService.updateUpload(mutateParams.id, mutateParams.body);

    queryClient.setMutationDefaults("markSeen", optimistic.objWithArrayProp("results").update);





    /** QUERY FUNCTIONS */
    const queryFunctions = {
        "notifications": async (queryKey) => {
            const [_key, { page, limit, sortBy, type, search, status }] = queryKey;
            const { data } = await notificationService.getNotifications({ page, limit, sortBy, search, type, status })
            return data;
        },
    }


    /** Create Default Function */
    const queryDefault = new QueryFunction(queryFunctions).defaultQueryFn;


    queryClient.setDefaultOptions({
        queries: {
            queryFn: queryDefault,
            staleTime: 10 * 1000,
        },
    })


    return queryClient;
}