export class QueryFunction {
  constructor(queryFunctions) {
    this.queryFunctions = queryFunctions;

    this.defaultQueryFn = async ({ queryKey }) => {
      const [_check] = queryKey;

      const queryFunction = queryFunctions[_check];

      return queryFunction(queryKey);
    };
  }
}
