import React from "react";

export function useFormData() {
  const formRef = React.useRef();

  function getFormValues() {
    return [...formRef.current.elements].reduce((acc, element) => {
      if (element.name && element.name === "phone") {
        return {
          ...acc,
        };
      }
      if (element.name && element.name === "cell") {
        return {
          ...acc,
        };
      }
      if (!element.name) {
        return {
          ...acc,
        };
      }

      return {
        ...acc,
        [element.name]: element.value,
      };
    }, {});
  }

  function clearFormValues() {
    return [...formRef.current.elements].map((element) => {
      element.value = "";
      return element;
    });
  }

  function fillFormValues(data) {
    const dataObject = {};

    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const element = data[key];

        if (typeof element === "object" && !Array.isArray(element)) {
          for (const subKey in element) {
            if (Object.hasOwnProperty.call(element, subKey)) {
              const subElement = element[subKey];
              dataObject[subKey] = subElement;
            }
          }
        } else {
          dataObject[key] = element;
        }
      }
    }

    const inputs = [...formRef.current.elements].filter((element) => {
      return (
        element.name !== "password" ||
        element.name !== "phone" ||
        element.name !== "cell"
      );
    });

    return [...inputs].map((element) => {
      if (element.name === "password") {
        return element;
      } else if (element.name === "phone") {
        return element;
      } else if (element.name === "cell") {
        return element;
      } else if (!element.name) {
        return element;
      } else {
        element.value = dataObject[element.name];
        return element;
      }
    });
  }

  return { formRef, getFormValues, clearFormValues, fillFormValues };
}
