import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Card, CardHeader, CardBody } from "shards-react";
import TableFilters from "./listTable/TableFilters";
import TableColumns from "./listTable/TableColumns";
import FileManagerTable from "./listTable/FileManagerTable";
import { useActions } from "./listTable/actions";
import EditFileModal from "./common/EditFileModal/EditFileModal";
import TableDropzone from "./listTable/TableDropzone";

const FileManagerList = ({
  userId,
  uploadFiles,
  downloadFile,
  folders,
  alertPage,
  setDeleteHandler,
}) => {
  const queryClient = useQueryClient();
  const [tableData, setTableData] = useState([]);
  // table params
  const [sortBy, setSortBy] = useState("fileName:desc");
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();

  // Query
  const { data, status } = useQuery([
    "files",
    userId,
    { page, limit, sortBy, search },
  ]);

  // Set Table Data
  useEffect(() => {
    if (data) setTableData(data.results);
  }, [data]);

  // Modal
  const [isOpen, setIsOpen] = useState(false);
  const [updated, setUpdated] = useState();

  // Handlers
  const { handleFileDownload, handleFileEdit } = useActions({
    userId,
    downloadFile,
    setUpdated,
  });

  useEffect(() => {
    if (updated) setIsOpen(true);
  }, [updated]);

  useEffect(() => {
    if (!isOpen) setUpdated();
  }, [isOpen]);

  const fileMutation = useMutation("updateUploadsPagination");
  // PreFetch
  const preFetchData = (id) => {
    queryClient.prefetchQuery(["file", id], { staleTime: 10 * 1000 });
  };

  // Upload dropzone files
  const sendUploads = async (files) => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append(`files`, files[i]);
    }
    await uploadFiles(userId, formData, "My Documents");
    queryClient.invalidateQueries(["files"]);
  };

  const deleteAdminUploadsPagination = useMutation(
    "deleteAdminUploadsPagination"
  );
  function handleDelete(item) {
    setDeleteHandler({
      deleteFn: () => {
        deleteAdminUploadsPagination.mutate(
          {
            key: ["files", userId, { page, limit, sortBy, search }],
            id: item.id,
          },
          {
            onError: async () => {
              queryClient.invalidateQueries("files");
              alertPage.error(`Unable to delete file: ${item.originalname}`);
            },
            onSuccess: () => {
              alertPage.success(`File: ${item.originalname} DELETED`);
            },
          }
        );
      },
      item: `File: ${item.originalname}`,
    });
  }

  return (
    <React.Fragment>
      <Card className="file-manager file-manager-list p-0">
        <CardHeader className="text-center p-0">
          {/* Filters */}
          <TableFilters
            pageSize={limit}
            setPageSize={setLimit}
            search={search}
            setSearch={setSearch}
          />
          <TableDropzone
            handleUpload={async (acceptedFiles) => {
              await sendUploads(acceptedFiles);
            }}
          />
        </CardHeader>
        <CardBody className="p-0">
          <FileManagerTable
            columns={TableColumns({
              handleFileDownload,
              handleFileEdit,
              handleFileDelete: handleDelete,
            })}
            data={tableData}
            pageSize={limit}
            pages={data && data.totalPages}
            loading={status === "loading"}
            setSortBy={setSortBy}
            prefetchItem={preFetchData}
            setLimit={setLimit}
            setPage={setPage}
          />
        </CardBody>
      </Card>
      <EditFileModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        updated={updated}
        setUpdated={setUpdated}
        handleSubmit={async () => {
          const { folder, originalname } = updated;
          fileMutation.mutate(
            {
              userId,
              id: updated.id,
              body: { folder, originalname },
              key: ["files", userId, { page, limit, sortBy, search }],
            },
            {
              onSuccess: () => {
                setIsOpen(false);
                alertPage.success(`File: ${originalname} UPDATED`);
              },
              onError: async () => {
                queryClient.invalidateQueries("files");
                alertPage.error(`Unable to update file: ${originalname}`);
              },
            }
          );
        }}
        error={{}}
        folders={folders}
      />
    </React.Fragment>
  );
};

export default FileManagerList;
