import React from "react";
import classNames from "classnames";
import { Row, Col, Card, CardBody, CardFooter } from "shards-react";
import ThumbNailViewer from "../common/DocumentViewer/ThumbNailViewer";
import DocumentFooter from "../common/DocumentViewer/DocumentFooter";

const DocumentsList = ({ files, setFiles }) => {
  function handleDocumentClick(file) {
    console.log("handle select - ", file);
    setFiles(
      files.map((doc) =>
        Object.assign(doc, {
          selected: file.name === doc.name ? !doc.selected : doc.selected,
        })
      )
    );
  }

  return (
    <Row>
      {files.map((file, idx) => {
        const classes = classNames(
          "mb-3",
          "file-manager__item",
          file.selected && "file-manager__item--selected"
        );

        return (
          <Col lg="3" sm="6" key={idx}>
            <Card
              small
              className={classes}
              onClick={() => handleDocumentClick(file)}
            >
              <CardBody className="file-manager__item-preview px-0 pb-0 pt-4">
                <ThumbNailViewer
                  fileName={file.name}
                  fileType={file.type}
                  url={file.preview}
                />
              </CardBody>
              <CardFooter className="border-top">
                <DocumentFooter fileName={file.name} fileSize={file.size} />
              </CardFooter>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
};

export default DocumentsList;
