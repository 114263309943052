import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Button,
  Form,
  FormInput,
  CardHeader,
} from "shards-react";
import FreeTextDisplay from "./FreeText/FreeTextDisplay";
import { useAddFreeText } from "../../../query.hooks/clients/clients";
import authService from "../../../services/auth.service";

const FreeText = ({ id, freeText }) => {
  const [newFreeText, setNewFreeText] = useState();
  const user = authService.userValue;

  const mutation = useAddFreeText(setNewFreeText, ["clients", id]);

  async function handleSubmit() {
    mutation.mutate({
      id,
      freeText: {
        user: `${user.lastName}, ${user.firstName}`,
        freeText: newFreeText,
      },
    });
  }

  return (
    <Card className="p-0 mb-4 h-100">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Free Text</h6>
        <div className="block-handle" />
      </CardHeader>
      <CardBody className="p-0">
        <FreeTextDisplay freeText={freeText} />
      </CardBody>
      <CardFooter className="border-top">
        <Row className="mb-2">
          <FormInput
            placeholder="Add free text"
            className="mt-4"
            onChange={(e) => setNewFreeText(e.target.value)}
            value={newFreeText}
          />
        </Row>
        <Row>
          {newFreeText && (
            <Button theme="accent" type="submit" onClick={() => handleSubmit()}>
              Save Changes
            </Button>
          )}
        </Row>
      </CardFooter>
    </Card>
  );
};

export default FreeText;
