import React, { useState, useEffect } from "react";
import { useQueryClient } from "react-query";
import {
  Row,
  Col,
  FormInput,
  Modal,
  ModalBody,
  FormGroup,
  Form,
  ButtonGroup,
  Button,
  ModalFooter,
  FormFeedback,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormCheckbox,
} from "shards-react";
import moment from "moment";
import bookingValidation from "./validation/booking.validation";
import BookingItemCard from "./common/BookingItemCard";
import bookingService from "../../services/booking.service";
import flightImgIcon from "./Payouts/flights.png";
import AirportAutoCompleteInput from "../../custom/AirportAutoCompleteInput/AirportAutoCompleteInput";
import VendorAutoCompleteInput from "../../custom/VendorAutoCompleteInput/VendorAutoCompleteInput";

const FlightsCard = ({ bookingId, flights, handleDelete }) => {
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState();
  const [formError, setFormError] = useState();
  const [isPackage, setIsPackage] = useState(false);

  useEffect(() => {
    if (selected) {
      setFormError();
      setIsOpen(true);
    }
  }, [selected]);

  useEffect(() => {
    if (!isOpen) {
      setSelected();
    }
  }, [isOpen]);

  async function handleSubmit() {
    try {
      Object.assign(selected, {
        package: isPackage,
        price: isPackage ? 0 : selected.price,
        vendor: selected.vendor ? selected.vendor.id : "MISSING",
      });

      const value = await bookingValidation.flight.validateAsync(selected);

      delete value._id;
      delete value.type;

      /* Edit */
      if (selected._id) {
        await bookingService.updateBookingItem(
          bookingId,
          "flights",
          selected._id,
          value
        );
      } else {
        /* New */
        await bookingService.createBookingItem(bookingId, "flights", value);
      }
      queryClient.invalidateQueries(["booking", bookingId]);
      setIsOpen(false);
    } catch (ex) {
      if (ex.isJoi) {
        console.log(ex.details);
        setFormError({
          path: ex.details[0].path[0],
          message: ex.details[0].message,
        });
      } else {
        console.log(ex);
      }
    }
  }

  return (
    <>
      <BookingItemCard
        label="Flights"
        imgIcon={flightImgIcon}
        data={flights}
        columns={columns}
        setSelected={setSelected}
        handleDelete={handleDelete}
        type="flights"
      />
      <Modal open={isOpen} toggle={() => setIsOpen(!isOpen)}>
        <ModalBody>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Row form>
              <Col className="form-group">
                <AirportAutoCompleteInput
                  placeholder="From"
                  value={selected && selected.from}
                  onChange={(e) => {
                    setSelected((updated) => {
                      return {
                        ...updated,
                        from: e.target.value,
                      };
                    });
                  }}
                />
              </Col>
              <Col className="form-group">
                <AirportAutoCompleteInput
                  placeholder="To"
                  value={selected && selected.to}
                  onChange={(e) => {
                    setSelected((updated) => {
                      return {
                        ...updated,
                        to: e.target.value,
                      };
                    });
                  }}
                />
              </Col>
            </Row>

            <FormGroup>
              <FormInput
                name="date"
                placeholder="Date"
                type="date"
                value={selected && moment(selected.date).format("YYYY-MM-DD")}
                onChange={(e) => {
                  setSelected((updated) => {
                    return {
                      ...updated,
                      date: e.target.value,
                    };
                  });
                }}
                invalid={formError && formError.path === "date"}
              />
              <FormFeedback>{formError && formError.message}</FormFeedback>
            </FormGroup>

            <FormGroup>
              <FormInput
                name="via"
                placeholder="Via"
                value={selected && selected.via}
                onChange={(e) => {
                  setSelected((updated) => {
                    return {
                      ...updated,
                      via: e.target.value,
                    };
                  });
                }}
                invalid={formError && formError.path === "via"}
              />
              <FormFeedback>{formError && formError.message}</FormFeedback>
            </FormGroup>

            <FormGroup>
              <FormInput
                name="flightNumber"
                placeholder="Flight Number"
                value={selected && selected.flightNumber}
                onChange={(e) => {
                  setSelected((updated) => {
                    return {
                      ...updated,
                      flightNumber: e.target.value,
                    };
                  });
                }}
                invalid={formError && formError.path === "flightNumber"}
              />
              <FormFeedback>{formError && formError.message}</FormFeedback>
            </FormGroup>
            <Row form>
              <Col className="form-group">
                <FormInput
                  name="depart"
                  placeholder="Depart"
                  type="time"
                  value={selected && selected.depart}
                  onChange={(e) => {
                    setSelected((updated) => {
                      return {
                        ...updated,
                        depart: e.target.value,
                      };
                    });
                  }}
                  invalid={formError && formError.path === "depart"}
                />
                <FormFeedback>{formError && formError.message}</FormFeedback>
              </Col>
              <Col className="form-group">
                <FormInput
                  name="arrive"
                  placeholder="Arrive"
                  type="time"
                  value={selected && selected.arrive}
                  onChange={(e) => {
                    setSelected((updated) => {
                      return {
                        ...updated,
                        arrive: e.target.value,
                      };
                    });
                  }}
                  invalid={formError && formError.path === "arrive"}
                />
                <FormFeedback>{formError && formError.message}</FormFeedback>
              </Col>
            </Row>

            <FormGroup>
              <FormInput
                name="seat"
                placeholder="Seat"
                value={selected && selected.seat}
                onChange={(e) => {
                  setSelected((updated) => {
                    return {
                      ...updated,
                      seat: e.target.value,
                    };
                  });
                }}
                invalid={formError && formError.path === "seat"}
              />
              <FormFeedback>{formError && formError.message}</FormFeedback>
            </FormGroup>
            <Row>
              <Col>
                <FormCheckbox
                  name="package"
                  checked={isPackage}
                  onChange={() => setIsPackage(!isPackage)}
                >
                  Package
                </FormCheckbox>
              </Col>
              <Col>
                {!isPackage && (
                  <InputGroup className="mb-3">
                    <InputGroupAddon type="prepend">
                      <InputGroupText>$</InputGroupText>
                    </InputGroupAddon>
                    <FormInput
                      name="price"
                      value={selected && selected.price}
                      onChange={(e) => {
                        setSelected((updated) => {
                          return {
                            ...updated,
                            price: e.target.value,
                          };
                        });
                      }}
                      invalid={formError && formError.path === "price"}
                    />
                  </InputGroup>
                )}
              </Col>
            </Row>
            <Row from>
              <Col className="form-group">
                <VendorAutoCompleteInput
                  value={selected && selected.vendor}
                  placeholder="Vendor"
                  onChange={(e) => {
                    setSelected((updated) => {
                      return {
                        ...updated,
                        vendor: e.target.value,
                      };
                    });
                  }}
                />
              </Col>
            </Row>
            {/*
            <AutoCompleteInput
              label="Vendor"
              initValue={
                (selected && selected.vendor && selected.vendor.name) || ""
              }
              fetchData={vendorService.getVendorsByNameAutoComplete}
              setSelectedId={(vendorId) =>
                setSelected((updated) => {
                  return {
                    ...updated,
                    vendor: vendorId,
                  };
                })
              }
            />
            */}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button className="btn-accent" onClick={() => handleSubmit()}>
            Save
          </Button>
          <Button className="btn-accent" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const columns = [
  {
    Header: "id",
    accessor: "_id",
    show: false,
  },
  {
    Header: "From",
    accessor: "from",
  },
  {
    Header: "To",
    accessor: "to",
  },
  {
    Header: "Date",
    accessor: "date",
    Cell: (row) => {
      return <span>{moment.utc(row.original.date).format("MM-DD-YYYY")}</span>;
    },
  },
  {
    Header: "Via",
    accessor: "via",
  },
  {
    Header: "Flight #",
    accessor: "flightNumber",
  },
  {
    Header: "Depart",
    accessor: "depart",
  },
  {
    Header: "Arrive",
    accessor: "arrive",
  },
  {
    Header: "Seat",
    accessor: "seat",
  },
  {
    Header: "$",
    accessor: "package",
    minWidth: 40,
    Cell: (row) => {
      if (row.original.package) {
        return <span className="material-icons">task_alt</span>;
      }
      return <span>${row.original.price}</span>;
    },
  },
  {
    accessor: "actions",
    maxWidth: 130,
    minWidth: 40,
    sortable: false,
    Cell: (row) => (
      <ButtonGroup size="sm" className="d-table mx-auto my-auto">
        <Button
          theme="white"
          onClick={() => {
            console.log("del", row);
          }}
        >
          <i className="material-icons">&#xE872;</i>
        </Button>
      </ButtonGroup>
    ),
  },
];

export default FlightsCard;
