import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views
//import LogOut from "./custom/LogOut";
//import Survey from "./views/Survey";
//import Blank from "./layouts/Blank";
//
//import Admin from "./views/Admin";
import AdminManager from "./views/AdminManager";
import ApiKeyManager from "./views/ApiKeyManager";
//import FileManager from "./views/FileManager";
import UploadManager from "./views/UploadManager";
import Client from "./views/Client";
import UserProfile from "./views/UserProfile";
import UserUploads from "./views/UserUploads";
import ClientList from "./views/ClientList";
import BookingList from "./views/BookingList";
import Booking from "./views/Booking";
import Vendors from "./views/Vendors";
import Payouts from "./views/Payouts";
import Agents from "./views/Agents";
import Reminders from "./views/Reminders";


const routes = [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    roles: ["admin", "user"],
    component: () => <Redirect to="/user/profile" />,
  },
  {
    path: "/admin",
    exact: true,
    layout: DefaultLayout,
    component: AdminManager,
    roles: ["admin"],
  },
  // {
  //path: "/admin/users",
  //exact: true,
  //layout: DefaultLayout,
  //component: UserManager,
  //roles: ["admin"],
  // },
  {
    path: "/admin/uploads",
    exact: true,
    layout: DefaultLayout,
    component: UploadManager,
    roles: ["admin"],
  },
  {
    path: "/admin/api",
    exact: true,
    layout: DefaultLayout,
    component: ApiKeyManager,
    roles: ["admin"],
  },
  {
    path: "/clients",
    exact: true,
    layout: DefaultLayout,
    component: ClientList,
    roles: ["admin"],
  },
  {
    path: "/clients/:client",
    exact: true,
    layout: DefaultLayout,
    component: Client,
    roles: ["admin"],
  },
  {
    path: "/clients/:client/:page",
    exact: true,
    layout: DefaultLayout,
    component: Client,
    roles: ["admin"],
  },
  {
    path: "/clients/:client/bookings/:bookingId",
    exact: true,
    layout: DefaultLayout,
    component: Booking,
    roles: ["admin"],
  },
  {
    path: "/clients/:client/bookings/:bookingId/:page",
    exact: true,
    layout: DefaultLayout,
    component: Booking,
    roles: ["admin"],
  },
  {
    path: "/bookings",
    exact: true,
    layout: DefaultLayout,
    component: BookingList,
    roles: ["admin"],
  },
  {
    path: "/vendors",
    exact: true,
    layout: DefaultLayout,
    component: Vendors,
    roles: ["admin"],
  },
  {
    path: "/reminders",
    exact: true,
    layout: DefaultLayout,
    component: Reminders,
    roles: ["admin"],
  },
  {
    path: "/payouts",
    exact: true,
    layout: DefaultLayout,
    component: Payouts,
    roles: ["admin"],
  },
  {
    path: "/agents",
    exact: true,
    layout: DefaultLayout,
    component: Agents,
    roles: ["admin"],
  },
  {
    path: "/user/profile",
    exact: true,
    layout: DefaultLayout,
    component: UserProfile,
    roles: ["admin", "user"],
  },
  {
    path: "/user/uploads",
    exact: true,
    layout: DefaultLayout,
    component: UserUploads,
    roles: ["admin", "user"],
  },

];

export default routes;
