import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "shards-react";
import moment from "moment";
import PageAlert, { usePageAlert } from "../common/Alert/PageAlert";
import AreYouSure, { useAreYouSure } from "../common/AreYouSure/AreYouSure";
import PageTitle from "../components/common/PageTitle";
import ApiKeysTable from "../component_modules/ApiKeyManager/ApiKeysTable";
import ApiKeysForm from "../component_modules/ApiKeyManager/ApiKeysForm";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { setApiKeyManagerQueryDefaults } from "../query/apiKeyManager.query";
import { getApiKeyOptions, getApiKeyToken } from "../services/apiKey.service";

const ApiKeyManager = () => {
  const queryClient = useQueryClient();
  setApiKeyManagerQueryDefaults(queryClient);
  const { alertPage, alertProps } = usePageAlert();
  const { areYouSureProps, setDeleteHandler } = useAreYouSure();
  const [apiKeysTableData, setApiKeysTableData] = useState([]);
  const [updated, setUpdated] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [displayKey, setDisplayKey] = useState();
  const { data } = useQuery(["apiKeys"]);
  const [accessCheckBoxes, setAccessCheckBoxes] = useState({});

  function clearAccessOptions() {
    setAccessCheckBoxes(
      [...Object.keys(accessCheckBoxes)].reduce((acc, item) => {
        return {
          ...acc,
          [item]: false,
        };
      }, {})
    );
  }

  useEffect(() => {
    getApiKeyOptions().then((res) => {
      setAccessCheckBoxes(
        res.data.reduce((acc, item) => {
          return {
            ...acc,
            [item]: false,
          };
        }, {})
      );
    });
  }, []);

  useEffect(() => {
    if (data) {
      setApiKeysTableData(
        data.map((apiKey) => {
          return {
            id: apiKey.id,
            name: apiKey.name,
            createdAt: moment(apiKey.createdAt).format("Do MMMM YYYY h:mm a"),
            status: apiKey.status,
            access: apiKey.access.length,
            lastRequest: moment(apiKey.lastRequest).fromNow(),
            lastRequestClass: getColorByDate(apiKey.lastRequest),
            user: `${apiKey.user.lastName}, ${apiKey.user.firstName}`,
            accessOptions: apiKey.access,
          };
        })
      );
    }
  }, [data]);

  useEffect(() => {
    if (!isEdit) {
      setUpdated();
      clearAccessOptions();
    }
  }, [isEdit]);

  useEffect(() => {
    if (updated && isEdit) {
      setAccessCheckBoxes(
        updated.accessOptions.reduce((acc, item) => {
          return {
            ...acc,
            [item]: true,
          };
        }, {})
      );
    }
  }, [updated, isEdit]);

  useEffect(() => {
    if (displayKey) {
      let timer = setTimeout(() => setDisplayKey(), 5 * 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [displayKey]);

  const createApiKey = useMutation("createApiKey");
  const deleteApiKey = useMutation("deleteApiKey");
  const updateApiKey = useMutation("updateApiKey");

  function handleEdit(item) {
    setUpdated(item);
    setIsEdit(true);
  }

  function handleDelete(item) {
    setDeleteHandler({
      deleteFn: () => {
        deleteApiKey.mutate(
          {
            key: ["apiKeys"],
            id: item.id,
          },
          {
            onError: alertPage.error(`Unable to delete Api Key: ${item.name}`),
            onSuccess: alertPage.success(`Api Key: ${item.name} DELETED`),
          }
        );
        handleClear();
      },
      item: `API Key ${item.name}`,
    });
  }

  function handleShowKey(item) {
    getApiKeyToken(item.id).then((res) => {
      setDisplayKey({ name: item.name, token: res.data.token });
    });
  }

  function handleSubmit() {
    const { name, status } = updated;

    let access = [];

    for (const option in accessCheckBoxes) {
      if (Object.hasOwnProperty.call(accessCheckBoxes, option)) {
        const element = accessCheckBoxes[option];
        if (element) access.push(option);
      }
    }

    const updatedApiKey = {
      name,
      status: status ? status : "active",
      access,
    };

    if (isEdit) {
      updateApiKey.mutate({
        key: ["apiKeys"],
        id: updated.id,
        body: updatedApiKey,
      });
      handleClear();
    } else {
      createApiKey.mutate({
        key: ["apiKeys"],
        body: updatedApiKey,
      });
      handleClear();
    }
  }

  function handleClear() {
    setIsEdit(false);
    setUpdated();
    clearAccessOptions();
  }

  function handleCopy() {
    navigator.clipboard.writeText(displayKey.token);
    alertPage.success(`Api Key: ${displayKey.name}, copied to clipboard`);
  }

  return (
    <>
      <PageAlert alertProps={alertProps} />
      <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            title="API Key Manager"
            subtitle="Dashboards"
            className="text-sm-left mb-3"
          />
          {/* Page Header :: List/Cards Actions */}
          <Col className="col d-flex"></Col>
        </Row>
        <Row>
          <Col md="8">
            <ApiKeysTable
              title="API Accounts"
              apiKeysTableData={apiKeysTableData}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              handleShowKey={handleShowKey}
              handleCopy={handleCopy}
              displayKey={displayKey}
            />
          </Col>
          <Col md="4">
            <ApiKeysForm
              title="API Key"
              isEdit={isEdit}
              updated={updated}
              setUpdated={setUpdated}
              accessCheckBoxes={accessCheckBoxes}
              setAccessCheckBoxes={setAccessCheckBoxes}
              handleSubmit={handleSubmit}
              handleClear={handleClear}
            />
          </Col>
        </Row>
      </Container>
      <AreYouSure areYouSureProps={areYouSureProps} />
    </>
  );
};

function getColorByDate(colorDate) {
  const momentColorDate = moment(colorDate);
  const momentNow = moment();

  if (momentColorDate.isSame(momentNow, "day")) {
    return "text-success";
  } else if (momentColorDate.isSame(momentNow, "week")) {
    return "text-warning";
  } else {
    return "text-danger";
  }
}

export default ApiKeyManager;
