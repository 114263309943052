import Joi from "joi";

const hotel = Joi.object().keys({
  _id: Joi.string(),
  hotel: Joi.string().required(),
  room: Joi.string().required(),
  fromDate: Joi.string().required(),
  toDate: Joi.string().required(),
  package: Joi.boolean(),
  price: Joi.number(),
  vendor: Joi.string().required(),
  type: Joi.string(),
});

const flight = Joi.object().keys({
  _id: Joi.string(),
  from: Joi.string().required(),
  to: Joi.string().required(),
  date: Joi.string().required(),
  via: Joi.string().required(),
  flightNumber: Joi.string().required(),
  depart: Joi.string().required(),
  arrive: Joi.string().required(),
  seat: Joi.string().required(),
  seatsPurchased: Joi.string(),
  package: Joi.boolean(),
  price: Joi.number(),
  vendor: Joi.string().required(),
  type: Joi.string(),
});

const tour = Joi.object().keys({
  _id: Joi.string(),
  operator: Joi.string().required(),
  confirmation: Joi.string().required(),
  fromDate: Joi.string().required(),
  toDate: Joi.string().required(),
  package: Joi.boolean(),
  price: Joi.number(),
  vendor: Joi.string().required(),
  type: Joi.string(),
});

const cruise = Joi.object().keys({
  _id: Joi.string(),
  cruiseLine: Joi.string().required(),
  confirmation: Joi.string().required(),
  deck: Joi.string().required(),
  fromDate: Joi.string().required(),
  toDate: Joi.string().required(),
  package: Joi.boolean(),
  price: Joi.number(),
  type: Joi.string(),
  vendor: Joi.string(),
  type: Joi.string(),
});

const rental = Joi.object().keys({
  _id: Joi.string(),
  company: Joi.string().required(),
  pickUp: Joi.string().required(),
  dropOff: Joi.string().required(),
  fromDate: Joi.string().required(),
  toDate: Joi.string().required(),
  package: Joi.boolean(),
  price: Joi.number(),
  vendor: Joi.string().required(),
  type: Joi.string(),
});

const traveler = Joi.object().keys({
  _id: Joi.string(),
  first: Joi.string().required(),
  last: Joi.string().required(),
  dob: Joi.string().required(),
});

const exports = {
  hotel,
  flight,
  tour,
  cruise,
  rental,
  traveler,
};

export default exports;
