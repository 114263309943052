import React from "react";
import moment from "moment";
import activityTypeIcon from "../../../../data/activityTypeIcon";

const UserActivityItem = ({ activity }) => {
  return (
    <div className="user-activity__item pr-3 py-3">
      <div className="user-activity__item__icon">
        <i className="material-icons">{activityTypeIcon[activity.type]}</i>
      </div>
      <div className="user-activity__item__content">
        <span className="text-light">
          {moment(activity.createdAt).fromNow()}
        </span>
        <p>{activity.title}</p>
      </div>
    </div>
  );
};

export default UserActivityItem;
