import React, { useState, useEffect } from "react";
import { useQueryClient } from "react-query";
import DropZone from "./DropZone";
import SectionTitle from "../common/SectionTitle";
import DocumentsList from "./DocumentsList";

const Uploader = ({
  userId,
  selectedDirectory,
  selectedFile,
  setSelectedFile,
  uploadFiles,
  deleteSelectedFile,
  editFile,
  search,
  setSearch,
}) => {
  const queryClient = useQueryClient();
  const [files, setFiles] = useState([]);

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  // Upload selected files
  const sendUploads = async () => {
    const unSelected = [];

    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      if (files[i].selected) {
        formData.append(`files`, files[i]);
      } else {
        unSelected.push(
          Object.assign(files[i], {
            selected: false,
          })
        );
      }
    }
    const { data } = await uploadFiles(userId, formData, selectedDirectory);

    setFiles(unSelected);
    queryClient.invalidateQueries(["uploadCards"]);
  };

  return (
    <React.Fragment>
      <DropZone
        files={files}
        setFiles={setFiles}
        sendUploads={sendUploads}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        deleteSelectedFile={deleteSelectedFile}
        editFile={editFile}
        search={search}
        setSearch={setSearch}
      />
      {/* Documents */}
      {files.length > 0 && <SectionTitle title="Uploader" />}
      <DocumentsList files={files} setFiles={setFiles} />
    </React.Fragment>
  );
};

export default Uploader;
