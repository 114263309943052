import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody } from "shards-react";
import PageAlert, { usePageAlert } from "../common/Alert/PageAlert";
import AreYouSure, { useAreYouSure } from "../common/AreYouSure/AreYouSure";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { setBookingsQueryDefaults } from "../query/booking.query";

// Page componnenets
import PageHeader, { useDateChangeHandler } from "../common/PageHeader";
import TableHeader from "../common/DataTable/TableHeader";
// Hooks
import { useSearchBar } from "../custom/SiteSearchBar/SiteSearchBar";
import BookingListDataTable from "../components/BookingList/BookingListDataTable";

const BookingList = () => {
  const queryClient = useQueryClient();
  setBookingsQueryDefaults(queryClient);
  const { alertPage, alertProps } = usePageAlert();
  const { areYouSureProps, setDeleteHandler } = useAreYouSure();
  // State
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [sortBy, setSortBy] = useState("primary.lastName:asc");
  const { search, setSearch } = useSearchBar("");
  const [start, handleStartChange, end, handleEndChange] =
    useDateChangeHandler();

  /**
   * Bookings Query
   */
  const { status, data } = useQuery([
    "bookings",
    { page, limit, sortBy, search, start, end },
  ]);

  const bookingDeleter = useMutation("deleteBooking");

  // Mutation goes here
  function handleDelete(item) {
    setDeleteHandler({
      deleteFn: async () => {
        bookingDeleter.mutate(
          {
            id: item.id,
            key: ["bookings", { page, limit, sortBy, search, start, end }],
          },
          {
            onError: alertPage.error(`Unable to delete booking: ${item.name}`),
            onSuccess: alertPage.success(`Client: ${item.name} DELETED`),
          }
        );
      },
      item: `Item`,
    });
  }

  return (
    <>
      <PageAlert alertProps={alertProps} />
      <Container fluid className="main-content-container px-4">
        <PageHeader
          title="Booking List"
          subtitle="Agent"
          dateStart={start}
          dateEnd={end}
          handleStartChange={handleStartChange}
          handleEndChange={handleEndChange}
        />
        <Row>
          <Col md="12">
            <Card className="p-0 mb-4">
              <TableHeader search={search} setSearch={setSearch} />
              <CardBody className="p-0">
                <BookingListDataTable
                  status={status}
                  data={data}
                  limit={limit}
                  setPage={setPage}
                  setLimit={setLimit}
                  setSortBy={setSortBy}
                  setPreFetchId={() => {}}
                  handleDelete={handleDelete}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <AreYouSure areYouSureProps={areYouSureProps} />
    </>
  );
};

export default BookingList;
