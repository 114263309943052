class BasicMutationOption {
  #_queryClient = {};
  constructor(queryClient, BasicMutationDefaults) {
    this.#_queryClient = queryClient;

    const { mutationFn, onMutate, onError, onSuccess, onSettled, DEBUG } =
      BasicMutationDefaults;

    const default_mutationFn = (variables) => {
      DEBUG && console.log("No Mutation Func provided", variables);
    };
    const default_onMutate = (variables) => {
      DEBUG && console.log("Mutate", variables);
    };
    const default_onSuccess = (data, variables, { previousData }) => {
      DEBUG && console.log("Success", data);
    };
    const default_onError = (error, variables, { previousData }) => {
      DEBUG && console.error("Error", error);

      queryClient.setQueryData(variables.key, previousData);
    };
    const default_onSettled = (data, error, variables) => {
      if (data) {
        DEBUG && console.info("Mutation Settled - After Success");
      }

      if (error) {
        DEBUG && console.info("Mutation Settled - After Error");
      }

      queryClient.invalidateQueries(variables.key[0]);
    };

    this._mutationFn = mutationFn || default_mutationFn;

    this._onMutate = onMutate || default_onMutate;

    this._onError = onError || default_onError;

    this._onSuccess = onSuccess || default_onSuccess;

    this._onSettled = onSettled || default_onSettled;
  }

  set mutationFn(mutationFn) {
    this._mutationFn = mutationFn;
  }
  set onMutate(onMutate) {
    this._onMutate = onMutate;
  }
  set onError(onError) {
    this._onError = onError;
  }
  set onSuccess(onSuccess) {
    this._onSuccess = onSuccess;
  }
  set onSettled(onSettled) {
    this._onSettled = onSettled;
  }

  createOptimisticUpdate(updateFn) {
    return async (variables) => {
      const queryClient = this.#_queryClient;
      await queryClient.cancelQueries(variables.key[0]);
      const previousData = queryClient.getQueryData(variables.key);

      queryClient.setQueryData(variables.key, (old) =>
        updateFn(old, variables)
      );
      return { previousData };
    };
  }

  options() {
    return {
      mutationFn: this._mutationFn,
      onMutate: this._onMutate,
      onSuccess: this._onSuccess,
      onError: this._onError,
      onSettled: this._onSettled,
    };
  }
}

class PaginationData {
  constructor(dataArrayProp) {
    this._updateFn = (old, variables) => {
      return {
        ...old,
        [dataArrayProp]: [
          ...old[dataArrayProp].map((result) => {
            if (result.id === variables.id) {
              return {
                ...result,
                ...variables.body,
              };
            }
            return {
              ...result,
            };
          }),
        ],
      };
    };
    this._deleteFn = (old, variables) => {
      return {
        ...old,
        [dataArrayProp]: [
          ...old[dataArrayProp].filter((result) => result.id !== variables.id),
        ],
      };
    };
    this._addFn = (old, variables) => {
      return {
        ...old,
        [dataArrayProp]: [...variables.body, ...old[dataArrayProp]],
      };
    };
  }
}

class ArrayData {
  constructor() {
    this._updateFn = (old, variables) => {
      return [
        ...old.map((oldFile) => {
          if (oldFile.id === variables.id) {
            return {
              ...oldFile,
              ...variables.body,
            };
          }
          return {
            ...oldFile,
          };
        }),
      ];
    };
    this._deleteFn = (old, variables) => {
      return [...old.filter((oldItem) => oldItem.id !== variables.id)];
    };
    this._addFn = (old, variables) => {
      const template = old[0];

      Object.assign(template, {
        id: 1,
        ...variables.body,
      });
      return [template, ...old];
    };
  }
}

class ItemData {
  constructor() {
    this._updateFn = (old, variables) => {
      return {
        ...old,
        ...variables.body,
      };
    };
    this._deleteFn = (old, variables) => {
      return {};
    };
    this._addFn = (old, variables) => {
      return {
        ...variables.body,
      };
    };
  }
}

export class MutationOption extends BasicMutationOption {
  constructor(queryClient, BasicMutationDefaults = {}) {
    super(queryClient, BasicMutationDefaults);
  }

  objWithArrayProp(dataArrayProp) {
    return this.#_merge(new PaginationData(dataArrayProp));
  }

  pagination(dataArrayProp) {
    return this.#_merge(new PaginationData(dataArrayProp));
  }

  array() {
    return this.#_merge(new ArrayData());
  }

  item() {
    return this.#_merge(new ItemData());
  }

  #_merge(obj) {
    for (const attrname in obj) {
      this[attrname] = obj[attrname];
    }
    return this;
  }

  get add() {
    this.onMutate = this.createOptimisticUpdate(this._addFn);
    return this.options();
  }
  get update() {
    this.onMutate = this.createOptimisticUpdate(this._updateFn);
    return this.options();
  }
  get delete() {
    this.onMutate = this.createOptimisticUpdate(this._deleteFn);
    return this.options();
  }
}
